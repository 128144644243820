import { Component, Input } from '@angular/core';

@Component({
  selector: 'common-heading',
  template: `
    <h1
      class="font-extrabold"
      [ngClass]="{
        'text-center': align === 'center',
        'text-left': align === 'left',
        'text-lg xl:text-xl': size === 'small',
        'text-xl xl:text-2xl': size === 'medium',
        'text-2xl xl:text-3xl': size === 'large'
      }"
    >
      {{ label }}
    </h1>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class HeadingComponent {
  @Input() label = '';
  @Input() align: 'center' | 'left' = 'left';
  @Input() size: 'small' | 'medium' | 'large' = 'large';
}
