import {Injectable} from "@angular/core";
import {Apollo, gql} from "apollo-angular";
import {Observable} from "rxjs";
import {ExposeGenerator} from "./expose-generator.model";
import {first, map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class ExposeGeneratorApiFacade {
  constructor(private readonly apollo: Apollo) { }

  createExposeGenerator$(
    objectId: string,
    data: any
  ): Observable<ExposeGenerator> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation CreateExposeGenerator($objectId: String!, $exposeGenerator: ExposeGeneratorInput) {
            exposeGenerator(objectId: $objectId, exposeGenerator: $exposeGenerator) {
              id
              objectId
              sampleSize
              locationDescription {
                result
              }
              propertyDescription {
                detailsInput
                tonalForm
                result
              }
            }
          }
        `,
        variables: {
            objectId,
            exposeGenerator: data
        },
      })
    .pipe(
      first(),
      map((result: any) => ({ ...result.data.exposeGenerator }))
    )
  }

  loadExposees$(objectId: string): Observable<ExposeGenerator[]> {
    return this.apollo.query({
      query: gql`
        query GetExposees($objectId: String!) {
          exposees(objectId: $objectId) {
            id
            objectId
            sampleSize
            creationDate
            locationDescription {
              result
            }
            propertyDescription {
              detailsInput
              tonalForm
              result
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId
      },
    }).pipe(first(), map((result: any) => result.data.exposees))
  }


}
