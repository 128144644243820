import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {GraphQLModule} from "services/graphql.module";
import {EffectsModule} from "@ngrx/effects";
import { RentalContractEffects } from './store/effects';
import {StoreModule} from "@ngrx/store";
import * as fromReducer from './store/reducer';

@NgModule({
  imports: [
    CommonModule,
    GraphQLModule,
    EffectsModule.forFeature([RentalContractEffects]),
    StoreModule.forFeature('rental-contract', fromReducer.reducer)
  ]
})
export class RentalContractDomainModule {}
