import { Injectable } from '@angular/core';
import { TeamFeatureState, teamSelectors } from './store';
import { TeamApiFacade } from './team-api.facade';
import { Store } from '@ngrx/store';
import { TeamActions } from './store/action-types';
import { Observable } from 'rxjs';
import { TeamModel } from './team.model';

@Injectable({ providedIn: 'root' })
export class TeamDataFacade {
  constructor(
    private readonly _store$: Store<TeamFeatureState>,
    private readonly _teamApiFacade: TeamApiFacade
  ) { }

  teams$ = this._store$.select(teamSelectors.teams);
  hasTeamMembers$ = this._store$.select(teamSelectors.hasTeamMembers);
  creatorTeams$ = this._store$.select(teamSelectors.creatorTeams);
  participatingTeams$ = this._store$.select(teamSelectors.participatingTeams);
  invitations$ = this._store$.select(teamSelectors.invitations);

  public loadTeams(): void {
    this._store$.dispatch(TeamActions.LoadTeams());
  }

  public loadTeamsInvitations(): void {
    this._store$.dispatch(TeamActions.LoadTeamsInvitations());
  }

  public loadTeams$(): Observable<TeamModel[]> {
    return this._teamApiFacade.getTeams$();
  }

  public addTeamMembers(teamId: string, email: string): void {
    this._store$.dispatch(TeamActions.AddTeamMember({ teamId, email }));
  }

  public acceptInvitation(teamId: string, email: string): void {
    this._store$.dispatch(TeamActions.AcceptInvitation({ teamId, email }));
  }

  public declineInvitation(teamId: string, email: string): void {
    this._store$.dispatch(TeamActions.DeclineInvitation({ teamId, email }));
  }

  public removeTeamMember(teamId: string, userId: string): void {
    this._store$.dispatch(TeamActions.RemoveTeamMember({ teamId, userId }));
  }

  public updateUserId(userId: string): void {
    this._store$.dispatch(TeamActions.UpdateUserId({ userId }));
  }

  public inviteTeamMembers(teamId: string, email: string): void {
    this._store$.dispatch(TeamActions.InviteTeamMember({ teamId, email }));
  }

  public resendInvitation(teamId: string, email: string): void {
    this._store$.dispatch(TeamActions.ResendInvitation({ teamId, email }));
  }

  public deleteInvitation(teamId: string, email: string): void {
    this._store$.dispatch(TeamActions.DeleteInvitation({ teamId, email }));
  }

}
