import * as localforage from 'localforage';
import { from, Observable } from 'rxjs';

export const LOCAL_DOCUMENT_ID_PREFIX = 'local_';

export abstract class LocalDatabase {
  protected _storage!: LocalForage;

  protected abstract storeName: string;
  protected abstract generateLocalId(data: any): string;

  protected initStorage() {
    this._storage = localforage.createInstance({
      name: 'PROPUP-Database',
      storeName: this.storeName,
    });
  }

  save$(data: any): Observable<any> {
    const id = data.id || this.generateLocalId(data);
    return from(this._storage.setItem(id, data).then(() => ({ ...data, id })));
  }

  get$(id: string): Observable<any> {
    return from(this._storage.getItem(id));
  }

  getAll$(): Observable<any[]> {
    const data: any[] = [];
    return from(
      this._storage.iterate((value) => {
        data.push(value);
      }).then(() => data)
    );
  }

  delete$(id: string): Observable<void> {
    return from(this._storage.removeItem(id));
  }

  clear$(): Observable<void> {
    return from(this._storage.clear());
  }
}