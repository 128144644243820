import { Injectable } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy } from '@angular/common';
import { Subscription } from "rxjs";
import { RightSidenavService } from "services/right-sidenav.service";

declare let gtag: Function;

@Injectable({ providedIn: 'root' })
export class LocationService {
  private _previousUrl: string | null = null;
  private _currentUrl: string | null = null;

  private readonly _subscription = new Subscription();

  get previousLocation() {
    return this._previousUrl;
  }

  get currentLocation() {
    return this._currentUrl;
  }

  constructor(
    private readonly _router: Router,
    private readonly _location: Location,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _locationStrategy: LocationStrategy,
    private readonly _rightSidenavService: RightSidenavService,
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  init(): void {
    this._currentUrl = this._router.url;

    this._subscription.add(
      this._router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this._rightSidenavService.clearContent();


          this._previousUrl = this._currentUrl;
          this._currentUrl = event.url;

          if (this._previousUrl === '/post-login') {
            history.pushState(null, '', location.href);

            this._locationStrategy.onPopState(() => {
              history.go(1);
            });
          }

          gtag('config', 'G-SQH1MLLH6K', {
            page_path: event.urlAfterRedirects,
          });
        }
      })
    );
  }
}
