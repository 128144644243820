import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, catchError, tap } from 'rxjs/operators';

import { SnackbarService } from 'services/snackbar.service';
import { PreListingApiFacade } from '../pre-listing-api.facade';
import * as DataActions from './actions';

@Injectable()
export class PreListingEffects {
  public createPreListing$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreatePreListing),
      switchMap(({ objectId, agentProfile, agentCompany }) =>
        this._preListingApiFacade.createPreListing$(
          objectId,
          agentProfile,
          agentCompany
        ).pipe(
          switchMap(() => [DataActions.CreatePreListingSucceded()]),
          catchError(() => [DataActions.CreatePreListingFailed()])
        )
      )
    )
  );

  public createPreListingFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreatePreListingFailed),
      tap(() =>
        this._snackbarService.showSnackbar(
          'Pre-Listing konnte nicht erzeugt werden',
          'mat-warn',
          true
        )
      )
    ),
    { dispatch: false }
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _snackbarService: SnackbarService,
    private readonly _preListingApiFacade: PreListingApiFacade
  ) { }
}
