import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromReducer from './store/reducer';
import { GraphQLModule } from 'services/graphql.module';
import { ViewingPassApiFacade } from './viewing-pass-api.facade';
import { ViewingPassEffects } from './store/effects';

@NgModule({
  imports: [
    CommonModule,
    GraphQLModule,
    EffectsModule.forFeature([ViewingPassEffects]),
    StoreModule.forFeature('viewing-pass', fromReducer.reducer)
  ],
  providers: [ViewingPassApiFacade],
})
export class ViewingPassDomainModule {}
