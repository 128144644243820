import { createSelector, ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromReducer from './reducer';

export interface CustomerPortalFeatureState {
  customerPortal: fromReducer.State;
}

export const reducers: ActionReducerMap<CustomerPortalFeatureState> = {
  customerPortal: fromReducer.reducer,
};

export const getCustomerPortalState = createFeatureSelector<fromReducer.State>('customer-portal');

export const customerPortalSelectors = {
  customerPortals: createSelector(getCustomerPortalState, fromReducer.getCustomerPortals),
  customerPortalsLoading: createSelector(getCustomerPortalState, fromReducer.getCustomerPortalsLoading),
  customerPortalFeedback: createSelector(getCustomerPortalState, fromReducer.getCustomerPortalFeedback),
  customerPortalFeedbackLoading: createSelector(getCustomerPortalState, fromReducer.getCustomerPortalFeedbackLoading),
  customerPortalDetails: createSelector(getCustomerPortalState, fromReducer.getCustomerPortalDetails),
  customerPortalDetailsLoading: createSelector(getCustomerPortalState, fromReducer.getCustomerPortalDetailsLoading),
  savingProcessLoading: createSelector(getCustomerPortalState, fromReducer.getSavingProcessLoading),
  savingProcessHasError: createSelector(getCustomerPortalState, fromReducer.getSavingProcessError),
};
