import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {SettingsApiFacade} from "../settings-api.facade";
import * as DataActions from "./actions";
import {catchError, switchMap, tap} from "rxjs/operators";

@Injectable()
export class SettingsEffects {

  public loadUserProfile$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadUserProfile),
      switchMap(() =>
        this._settingsApiFacade.loadUserProfile$().pipe(
          switchMap((value) => [DataActions.UserProfileLoaded({ payload: value })]),
          catchError(() => [DataActions.UserProfileLoadingFailed()])
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _settingsApiFacade: SettingsApiFacade,
  ) {
  }
}
