import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GraphQLModule } from 'services/graphql.module';
import { RentalOfferEffects } from './store/effects';
import * as fromReducer from './store/reducer';

@NgModule({
  imports: [
    CommonModule,
    GraphQLModule,
    EffectsModule.forFeature([RentalOfferEffects]),
    StoreModule.forFeature('rental-offer', fromReducer.reducer)
  ],
})
export class RentalOfferDomainModule {}
