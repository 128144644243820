import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeadingComponent } from './heading/heading.component';
import { SubHeadingComponent } from './sub-heading/sub-heading.component';

@NgModule({
  declarations: [HeadingComponent, SubHeadingComponent],
  imports: [CommonModule],
  exports: [HeadingComponent, SubHeadingComponent],
})
export class HeadingsModule {}
