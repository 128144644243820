import {NgModule} from '@angular/core';
import {RemoveAriaOwnsDirective} from "./directives/RemoveAriaOwnsDirective";

@NgModule({
  imports: [],
  exports: [RemoveAriaOwnsDirective],
  declarations: [RemoveAriaOwnsDirective],
  providers: [],
})
export class SharedModule {
}
