<ng-template #content><ng-content></ng-content></ng-template>

<ng-container [ngSwitch]="integration">
  <ng-container *ngSwitchCase="ExternalSystem.Justimmo">
    <ng-container *ngIf="(isJustimmo$ | async)">
      <a *ngIf="(isJustimmoTokenValid$ | async) === false else content" [href]="justimmoLink"
        style="pointer-events: auto;">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ExternalSystem.EdiReal">
    <ng-container *ngIf="(isEdiReal$ | async)">
      <a *ngIf="(isEdiRealTokenValid$ | async) === false else content" [href]="ediLink" style="pointer-events: auto;">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ExternalSystem.Remax">
    <ng-container *ngIf="(isRemax$ | async)">
      <a *ngIf="(isRemaxTokenValid$ | async) === false else content" [href]="remaxLink" style="pointer-events: auto;">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ExternalSystem.Microsoft">
    <ng-container *ngIf="(isMicrosoft$ | async)">
      <a *ngIf="(isMicrosoftTokenValid$ | async) === false else content" [href]="microsoftLink"
         style="pointer-events: auto;">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </a>
    </ng-container>
  </ng-container>
</ng-container>
