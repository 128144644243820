
import {Action, createReducer, on} from "@ngrx/store";
import * as DataActions from "./actions";
import {ViewingPass} from "@domains/viewing-pass";
import {ExclusiveAgreement} from "../../exclusive-agreement";
import {RentalOffer} from "@domains/rental-offer";
import {BuyingOffer} from "@domains/buying-offer";
import {HandoverProtocol} from "@domains/handover-protocol";
import {PlainAgreement} from "../../plain-agreement";


export interface State {
  allViewingPasses: {
    data: ViewingPass[];
    loading: boolean;
  };
  allExclusiveAgreements: {
    data: ExclusiveAgreement[];
    loading: boolean;
  };
  allPlainAgreements: {
    data: PlainAgreement[];
    loading: boolean;
  };
  allRentalOffers: {
    data: RentalOffer[];
    loading: boolean;
  };
  allBuyingOffers: {
    data: BuyingOffer[];
    loading: boolean;
  };
  allHandoverProtocols: {
    data: HandoverProtocol[];
    loading: boolean;
  };
}

export const initialState: State = {
  allViewingPasses: {
    data: [],
    loading: false,
  },
  allExclusiveAgreements: {
    data: [],
    loading: false,
  },
  allPlainAgreements: {
    data: [],
    loading: false,
  },
  allRentalOffers: {
    data: [],
    loading: false,
  },
  allBuyingOffers: {
    data: [],
    loading: false,
  },
  allHandoverProtocols: {
    data: [],
    loading: false,
  },
};

const documentsReducer = createReducer(
  initialState,

  on(DataActions.LoadAllViewingPasses, (state: State) => ({
    ...state,
    allViewingPasses: {
      loading: true,
      data: [],
    }
  })),
  on(DataActions.AllViewingPassesLoaded, (state: State, {payload}) => ({
    ...state,
    allViewingPasses: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.AllViewingPassesLoadingFailed, (state) => ({
    ...state,
    allViewingPasses: {
      ...state.allViewingPasses,
      loading: false,
    },
  })),
  on(DataActions.DeleteViewingPass, (state, {viewingPassId}) => ({
    ...state,
    allViewingPasses: {
      ...state.allViewingPasses,
      data: state.allViewingPasses.data.filter(viewingPass => viewingPass.id !== viewingPassId)
    },
  })),

  on(DataActions.LoadAllExclusiveAgreements, (state: State) => ({
    ...state,
    allExclusiveAgreements: {
      loading: true,
      data: [],
    }
  })),
  on(DataActions.AllExclusiveAgreementsLoaded, (state: State, {payload}) => ({
    ...state,
    allExclusiveAgreements: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.AllExclusiveAgreementsLoadingFailed, (state) => ({
    ...state,
    allExclusiveAgreements: {
      ...state.allExclusiveAgreements,
      loading: false,
    },
  })),
  on(DataActions.DeleteExclusiveAgreement, (state, {exclusiveAgreementId}) => ({
    ...state,
    allExclusiveAgreements: {
      ...state.allExclusiveAgreements,
      data: state.allExclusiveAgreements.data.filter(exclusiveAgreement => exclusiveAgreement.id !== exclusiveAgreementId)
    }
  })),

  on(DataActions.LoadAllPlainAgreements, (state: State) => ({
    ...state,
    allPlainAgreements: {
      loading: true,
      data: [],
    }
  })),
  on(DataActions.AllPlainAgreementsLoaded, (state: State, {payload}) => ({
    ...state,
    allPlainAgreements: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.AllPlainAgreementsLoadingFailed, (state) => ({
    ...state,
    allPlainAgreements: {
      ...state.allPlainAgreements,
      loading: false,
    },
  })),
  on(DataActions.DeletePlainAgreement, (state, {plainAgreementId}) => ({
    ...state,
    allPlainAgreements: {
      ...state.allPlainAgreements,
      data: state.allPlainAgreements.data.filter(plainAgreement => plainAgreement.id !== plainAgreementId)
    }
  })),

  on(DataActions.LoadAllBuyingOffers, (state: State) => ({
    ...state,
    allBuyingOffers: {
      loading: true,
      data: [],
    }
  })),
  on(DataActions.AllBuyingOffersLoaded, (state: State, {payload}) => ({
    ...state,
    allBuyingOffers: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.AllBuyingOffersLoadingFailed, (state) => ({
    ...state,
    allBuyingOffers: {
      ...state.allBuyingOffers,
      loading: false,
    },
  })),
  on(DataActions.DeleteBuyingOffer, (state, {buyingOfferId}) => ({
    ...state,
    allBuyingOffers: {
      ...state.allBuyingOffers,
      data: state.allBuyingOffers.data.filter(buyingOffer => buyingOffer.id !== buyingOfferId)
    },
  })),

  on(DataActions.LoadAllRentalOffers, (state: State) => ({
    ...state,
    allRentalOffers: {
      loading: true,
      data: [],
    }
  })),
  on(DataActions.AllRentalOffersLoaded, (state: State, {payload}) => ({
    ...state,
    allRentalOffers: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.AllRentalOffersLoadingFailed, (state) => ({
    ...state,
    allRentalOffers: {
      ...state.allRentalOffers,
      loading: false,
    },
  })),
  on(DataActions.DeleteRentalOffer, (state, {rentalOfferId}) => ({
    ...state,
    allRentalOffers: {
      ...state.allRentalOffers,
      data: state.allRentalOffers.data.filter(rentalOffer => rentalOffer.id !== rentalOfferId)
    },
  })),

  on(DataActions.LoadAllHandoverProtocols, (state: State) => ({
    ...state,
    allHandoverProtocols: {
      loading: true,
      data: [],
    }
  })),
  on(DataActions.AllHandoverProtocolsLoaded, (state: State, {payload}) => ({
    ...state,
    allHandoverProtocols: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.AllHandoverProtocolsLoadingFailed, (state) => ({
    ...state,
    allHandoverProtocols: {
      ...state.allHandoverProtocols,
      loading: false,
    },
  })),
  on(DataActions.DeleteHandoverProtocol, (state, {hopId}) => ({
    ...state,
    allHandoverProtocols: {
      ...state.allHandoverProtocols,
      data: state.allHandoverProtocols.data.filter(handoverProtocol => handoverProtocol.id !== hopId)
    },
  })),


);

export const reducer = (state: State | undefined, action: Action) => documentsReducer(state, action);

export const getAllViewingPasses = (state: State) => state.allViewingPasses.data;
export const getAllViewingPassesLoading = (state: State) => state.allViewingPasses.loading;

export const getAllExclusiveAgreements = (state: State) => state.allExclusiveAgreements.data;
export const getAllExclusiveAgreementsLoading = (state: State) => state.allExclusiveAgreements.loading;

export const getAllPlainAgreements = (state: State) => state.allPlainAgreements.data;
export const getAllPlainAgreementsLoading = (state: State) => state.allPlainAgreements.loading;

export const getAllBuyingOffers = (state: State) => state.allBuyingOffers.data;
export const getAllBuyingOffersLoading = (state: State) => state.allBuyingOffers.loading;

export const getAllRentalOffers = (state: State) => state.allRentalOffers.data;
export const getAllRentalOffersLoading = (state: State) => state.allRentalOffers.loading;

export const getAllHandoverProtocols = (state: State) => state.allHandoverProtocols.data;
export const getAllHandoverProtocolsLoading = (state: State) => state.allHandoverProtocols.loading;
