import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ObjectDataFacade } from '@domains/object';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DashboardResolver implements Resolve<void> {
  constructor(private readonly _facade: ObjectDataFacade) {}

  resolve(): Observable<void> | Promise<void> | any {
    this._facade.loadObjects();
    return;
  }
}
