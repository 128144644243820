import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarComponent } from './avatar.component';
import { UserAvatarComponent } from './user-avatar.component';

@NgModule({
  declarations: [AvatarComponent, UserAvatarComponent],
  imports: [CommonModule],
  exports: [UserAvatarComponent, AvatarComponent],
})
export class AvatarModule {}
