import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { mapTo, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkStatusService {
  private onlineStatus$$ = new BehaviorSubject<boolean>(navigator.onLine);

  isOnline$: Observable<boolean> = this.onlineStatus$$.asObservable();

  constructor() {
    this._listenToNetworkChanges();
  }

  private _listenToNetworkChanges(): void {
    const online$ = fromEvent(window, 'online').pipe(mapTo(true));
    const offline$ = fromEvent(window, 'offline').pipe(mapTo(false));

    merge(online$, offline$)
      .pipe(startWith(navigator.onLine))
      .subscribe(this.onlineStatus$$);
  }
}
