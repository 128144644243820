import {createAction, props} from "@ngrx/store";
import {ExposeGenerator, ExposeGeneratorInput} from "../expose-generator.model";

export const CreateExposeGenerator = createAction(
  '[ExposeGenerator] Create Expose',
  props<{objectId: string; exposeGenerator: ExposeGeneratorInput}>()
);

export const CreateExposeGeneratorFailed = createAction('[ExposeGenerator] Failed to create Expose');
export const CreateExposeGeneratorSucceeded = createAction(
  '[ExposeGenerator] Succeeded to create Expose',
  props<{ payload: ExposeGenerator }>(),
);

export const LoadExposees = createAction('[ExposeGenerator] Load Exposees', props<{ objectId: string }>());
export const ExposeesLoaded = createAction('[ExposeGenerator] Exposees Loaded', props<{ payload: ExposeGenerator[] }>());
export const ExposeesLoadingFailed = createAction('[ExposeGenerator] Exposees Loading Failed');
