import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionStartScreenFrameLoggedOutComponent } from './section-start-screen-frame-logged-out.component';

@NgModule({
  declarations: [SectionStartScreenFrameLoggedOutComponent],
  imports: [CommonModule],
  exports: [SectionStartScreenFrameLoggedOutComponent],
})
export class SectionStartScreenFrameModule {}
