import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const amountHigherThanObjectPrice: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const objectPrice = control.get('objectPrice');
  const amount = control.get('amount');

  if (isInvalid(objectPrice, amount)) {
    const error = { amountHigherThanObjectPrice: true };
    amount?.setErrors(error); // invalidates amount-field
    return error;
  }
  return null; // returning null means successful validation
};

function isInvalid(objectPrice: any, amount: any): boolean {
  return amount && objectPrice && amount.value > objectPrice.value;
}
