import * as fromReducer from './reducer';
import {ActionReducerMap, createFeatureSelector, createSelector} from "@ngrx/store";
export interface DocumentFeatureState {
  allViewingPasses: fromReducer.State;
  allExclusiveAgreements: fromReducer.State;
  allPlainAgreements: fromReducer.State;
  allRentalOffers: fromReducer.State;
  allBuyingOffers: fromReducer.State;
  allHandoverProtocols: fromReducer.State;
}

export const reducers: ActionReducerMap<DocumentFeatureState> = {
  allViewingPasses: fromReducer.reducer,
  allExclusiveAgreements: fromReducer.reducer,
  allPlainAgreements: fromReducer.reducer,
  allRentalOffers: fromReducer.reducer,
  allBuyingOffers: fromReducer.reducer,
  allHandoverProtocols: fromReducer.reducer,
};

export const getDocumentsState = createFeatureSelector<fromReducer.State>('documents');

export const documentsSelectors = {
  allViewingPasses: createSelector(getDocumentsState, fromReducer.getAllViewingPasses),
  allViewingPassesLoading: createSelector(getDocumentsState, fromReducer.getAllViewingPassesLoading),
  allExclusiveAgreements: createSelector(getDocumentsState, fromReducer.getAllExclusiveAgreements),
  allExclusiveAgreementsLoading: createSelector(getDocumentsState, fromReducer.getAllExclusiveAgreementsLoading),
  allPlainAgreements: createSelector(getDocumentsState, fromReducer.getAllPlainAgreements),
  allPlainAgreementsLoading: createSelector(getDocumentsState, fromReducer.getAllPlainAgreementsLoading),
  allRentalOffers: createSelector(getDocumentsState, fromReducer.getAllRentalOffers),
  allRentalOffersLoading: createSelector(getDocumentsState, fromReducer.getAllRentalOffersLoading),
  allBuyingOffers: createSelector(getDocumentsState, fromReducer.getAllBuyingOffers),
  allBuyingOffersLoading: createSelector(getDocumentsState, fromReducer.getAllBuyingOffersLoading),
  allHandoverProtocols: createSelector(getDocumentsState, fromReducer.getAllHandoverProtocols),
  allHandoverProtocolsLoading: createSelector(getDocumentsState, fromReducer.getAllHandoverProtocolsLoading),
};
