import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class RoleProviderService {
  constructor(private readonly _authService: AuthService) { }

  hasRole(roleToTest: string): boolean {
    return this._authService.roles.includes(roleToTest);
  }

  getRoles() {
    return this._authService.roles
  }
}
