import {Injectable} from "@angular/core";
import {ExposeGeneratorFeatureState, exposeGeneratorSelectors} from "./store";
import {Store} from "@ngrx/store";
import {ExposeGeneratorActions} from "./store/action-types";
import {ExposeGeneratorInput} from "./expose-generator.model";

@Injectable({ providedIn: 'root' })
export class ExposeGeneratorDataFacade {
  exposees$ = this._store$.select(exposeGeneratorSelectors.exposees);
  exposeesLoading$ = this._store$.select(exposeGeneratorSelectors.exposeesLoading)
  constructor(
    private readonly _store$: Store<ExposeGeneratorFeatureState>,
  ) { }

  public createExposeGenerator(objectId: string, exposeGenerator: ExposeGeneratorInput): void {
    this._store$.dispatch(ExposeGeneratorActions.CreateExposeGenerator({ objectId, exposeGenerator }))
  }

  public loadExposees(objectId: string): void {
    this._store$.dispatch(ExposeGeneratorActions.LoadExposees({ objectId }))
  }
}
