import { createSelector, ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromReducer from './reducer';

export interface BuyingOfferFeatureState {
  buyingOffer: fromReducer.State;
}

export const reducers: ActionReducerMap<BuyingOfferFeatureState> = {
  buyingOffer: fromReducer.reducer,
};

export const getBuyingOfferState = createFeatureSelector<fromReducer.State>('buying-offer');

export const BuyingOfferSelectors = {
  buyingOffers: createSelector(getBuyingOfferState, fromReducer.getBuyingOffers),
  buyingOffersLoading: createSelector(getBuyingOfferState, fromReducer.getBuyingOffersLoading),
  buyingOfferDetails: createSelector(getBuyingOfferState, fromReducer.getBuyingOfferDetails),
  buyingOfferDetailsLoading: createSelector(getBuyingOfferState, fromReducer.getBuyingOfferDetailsLoading),
};
