import { createSelector } from '@ngrx/store';
import { GlobalState } from './global.reducers';

export const selectGlobalStore = (state: any): GlobalState => state.global;

export const selectGlobalStateIsLoaded = createSelector(
  selectGlobalStore,
  (state) => state.isGlobalStateLoaded as boolean
);

export const selectCountries = createSelector(
  selectGlobalStore,
  (state) => state.countries
);

export const selectIsEdiRealTokenValid = createSelector(
  selectGlobalStore,
  (state) => state.isEdiRealTokenValid as boolean
);

export const selectIsJustimmoTokenValid = createSelector(
  selectGlobalStore,
  (state) => state.isJustimmoTokenValid as boolean
);

export const selectIsRemaxTokenValid = createSelector(
  selectGlobalStore,
  (state) => state.isRemaxTokenValid as boolean
);

export const selectIsMicrosoftTokenValid = createSelector(
  selectGlobalStore,
  (state) => state.isMicrosoftTokenValid as boolean
);
