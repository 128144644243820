import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerFormService } from '@domains/customer';
import { ContractPartner, ContractPartnerType } from './contract-partner.model';

@Injectable({ providedIn: 'root' })
export class ContractPartnerFormService {

  constructor(
    private readonly _fb: FormBuilder,
    private readonly _customerFormService: CustomerFormService,
  ) { }

  createContractPartnerFormGroup(): FormGroup {
    return this._fb.group({
      id: undefined,
      type: [ContractPartnerType.Financing, [Validators.required]],
      companyNumber: ['', [Validators.required]],
      companyRegister: '',
      contact: this._fb.group({
        address: this._fb.group({
          country: [''],
          city: [''],
          zipCode: [''],
          streetName: [''],
          buildingNumber: [''],
          doorNumber: [''],
          stairway: [''],
          displayAddress: [''],
        }),
        email: ['', [Validators.email, Validators.required]],
        phone: '',
      }),
      customers: this._fb.array([]),
    });
  }

  prepareCustomerToPersist(customer: ContractPartner): ContractPartner {
    const copy = JSON.parse(JSON.stringify(customer));
    delete copy.contact?.address?.displayAddress;

    return copy;
  }

  createPrivateCustomers(): FormArray {
    return this._fb.array([]);
  }

  createPrivateCustomer(): FormGroup {
    return this._customerFormService.createCustomerFormGroup(true);
  }

  addCustomerValidators(customerGroup: FormGroup): void {
    const firstnameControl = customerGroup.get('firstname');
    const lastnameControl = customerGroup.get('lastname');
    const emailControl = customerGroup.get('contact.email');

    if (firstnameControl) {
      firstnameControl.setValidators([Validators.required]);
      firstnameControl.updateValueAndValidity();
    }
    if (lastnameControl) {
      lastnameControl.setValidators([Validators.required]);
      lastnameControl.updateValueAndValidity();
    }
    if (emailControl) {
      emailControl.setValidators([Validators.email, Validators.required]);
      emailControl.updateValueAndValidity();
    }

    customerGroup.markAllAsTouched();
    customerGroup.markAsDirty();
  }
}
