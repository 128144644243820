import { SnackbarService } from '../../services/snackbar.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { failedToSendEmail, succeededToSendEmail } from './emails.actions';

@Injectable()
export class EmailsEffects {
  constructor(private actions$: Actions, private snackbar: SnackbarService) {}

  triggerEmailSuccessSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(succeededToSendEmail),
        map(() =>
          this.snackbar.showSnackbar(
            'Email erfolgreich versendet',
            'mat-primary'
          )
        )
      ),
    { dispatch: false }
  );

  triggerEmailErrorSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(failedToSendEmail),
        map(() =>
          this.snackbar.showSnackbar(
            'Email konnte nicht versendet werden',
            'mat-warn'
          )
        )
      ),
    { dispatch: false }
  );
}
