import { Injectable } from '@angular/core';
import { ObjectType, OrderType, Object } from '../object.model';

@Injectable({
  providedIn: 'root',
})
export class LegacyMapperService {
  constructor() {}

  mapLegacyObjectDetails(objectData: any): Object {
    return {
      ...objectData,
      objectType: this.getProperObjectType(objectData.objectType),
      orderType: this.getProperOrderType(objectData.orderType),
    };
  }

  private getProperObjectType(objectType: string): ObjectType {
    const objectTypes: ObjectType[] = [
      'house',
      'flat',
      'premises',
      'businessObject',
      'specialObject',
    ];

    return objectTypes.includes(objectType as ObjectType)
      ? (objectType as ObjectType)
      : this.mapLegacyObjectType(objectType);
  }

  private mapLegacyObjectType(objectType: string): ObjectType {
    switch (objectType) {
      case 'Haus':
        return 'house';
      case 'Wohnung':
        return 'flat';
      case 'Grundstück':
        return 'premises';
      case undefined:
        return 'house';
      default:
        throw new Error(`Invalid objectType '${objectType}'`);
    }
  }

  private getProperOrderType(orderType: string): OrderType {
    const orderTypes: OrderType[] = ['buy', 'rent'];

    return orderTypes.includes(orderType as OrderType)
      ? (orderType as OrderType)
      : this.mapLegacyOrderType(orderType);
  }

  private mapLegacyOrderType(orderType: string): OrderType {
    switch (orderType) {
      case 'Kauf':
        return 'buy';
      case 'Miete':
        return 'rent';
      case undefined:
        return 'buy';
      default:
        throw new Error(`Invalid orderType '${orderType}'`);
    }
  }
}
