import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HandoverProtocolEffects } from './store/effects';
import * as fromReducer from './store/reducer';
import { HandoverProtocolApiFacade } from './handover-protocol-api.facade';
import { GraphQLModule } from 'services/graphql.module';

@NgModule({
  imports: [
    CommonModule,
    GraphQLModule,
    EffectsModule.forFeature([HandoverProtocolEffects]),
    StoreModule.forFeature('handover-protocol', fromReducer.reducer)
  ],
  providers: [HandoverProtocolApiFacade],
})
export class HandoverProtocolDomainModule {}
