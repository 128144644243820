import * as DataActions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import {ViewingPass, ViewingPassCreation} from '../viewing-pass.model';

export interface State {
  viewingPasses: {
    data: ViewingPass[];
    loading: boolean;
  };
  viewingPassDetails: {
    data: ViewingPass | null;
    loading: boolean;
  };
  savingProcess: {
    loading: boolean;
    error: boolean;
    offline: boolean;
  };
  offlineViewingPasses: {
    data: ViewingPassCreation[];  // New field to track offline saved viewing passes
    syncing: boolean;     // New field to track syncing state
    syncError: boolean;   // New field to track sync errors
  };
}

export const initialState: State = {
  viewingPasses: {
    data: [],
    loading: false,
  },
  viewingPassDetails: {
    data: null,
    loading: false,
  },
  savingProcess: {
    loading: false,
    error: false,
    offline: false,
  },
  offlineViewingPasses: {
    data: [], // Initially, there are no offline saved viewing passes
    syncing: false,
    syncError: false,
  }
};

const viewingPassReducer = createReducer(
  initialState,

  on(DataActions.LoadViewingPasses, DataActions.LoadViewingPassesByCustomer, (state) => ({
    ...state,
    viewingPasses: {
      loading: true,
      data: [],
    },
  })),
  on(DataActions.ViewingPassesLoaded, (state, { payload }) => ({
    ...state,
    viewingPasses: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.ViewingPassesLoadingFailed, (state) => ({
    ...state,
    viewingPasses: {
      ...state.viewingPasses,
      loading: false,
    },
  })),

  on(DataActions.LoadViewingPassDetails, (state) => ({
    ...state,
    viewingPassDetails: {
      loading: true,
      data: null,
    },
  })),
  on(DataActions.ViewingPassDetailsLoaded, (state, { payload }) => ({
    ...state,
    viewingPassDetails: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.ViewingPassDetailsLoadingFailed, (state) => ({
    ...state,
    viewingPassDetails: {
      ...state.viewingPassDetails,
      loading: false,
    },
  })),

  on(DataActions.SaveViewingPass, (state) => ({
    ...state,
    savingProcess: {
      loading: true,
      error: false,
      offline: false,
    },
  })),
  on(DataActions.SaveViewingPassSucceded, (state) => ({
    ...state,
    savingProcess: {
      loading: false,
      error: false,
      offline: false,
    },
  })),
  on(DataActions.SaveViewingPassFailed, (state) => ({
    ...state,
    savingProcess: {
      loading: false,
      error: true,
      offline: false,
    },
  })),

  on(DataActions.DeleteViewingPass, (state) => ({
    ...state,
    viewingPasses: {
      ...state.viewingPasses,
      loading: true,
    },
  })),
  on(DataActions.DeleteViewingPassSucceded, (state, { viewingPassId }) => ({
    ...state,
    viewingPasses: {
      ...state.viewingPasses,
      data: state.viewingPasses.data.filter((v: ViewingPass) => v.id !== viewingPassId),
    },
  })),
  on(DataActions.DeleteViewingPassFailed, (state) => ({
    ...state,
    viewingPasses: {
      ...state.viewingPasses,
      loading: false,
    },
  })),

  // New handler for saving viewing passes offline
  on(DataActions.SaveViewingPassOffline, (state, { viewingPass }) => ({
    ...state,
    savingProcess: {
      loading: false,
      error: false,
      offline: true, // Data is saved offline
    },
    offlineViewingPasses: {
      ...state.offlineViewingPasses,
      data: [...state.offlineViewingPasses.data, viewingPass], // Add to offline queue
    }
  })),

  // Handler for starting the sync process
  on(DataActions.SyncViewingPasses, (state) => ({
    ...state,
    offlineViewingPasses: {
      ...state.offlineViewingPasses,
      syncing: true, // Set syncing state to true
      syncError: false,
    },
  })),

  // Handler for successful sync of offline data
  on(DataActions.SyncViewingPassesSuccess, (state) => ({
    ...state,
    offlineViewingPasses: {
      ...state.offlineViewingPasses,
      syncing: false,
      syncError: false,
      data: [], // Clear offline queue after successful sync
    },
  })),

  // Handler for failed sync of offline data
  on(DataActions.SyncViewingPassesFailed, (state) => ({
    ...state,
    offlineViewingPasses: {
      ...state.offlineViewingPasses,
      syncing: false,
      syncError: true, // Set sync error to true
    },
  })),
  on(DataActions.SyncViewingPassesSkipped, (state) => ({
    ...state,
    offlineViewingPasses: {
      ...state.offlineViewingPasses,
      syncing: false,   // Reset syncing status
      syncError: false, // No error since syncing was skipped
    },
  })),
);

export const reducer = (state: State | undefined, action: Action): State => viewingPassReducer(state, action);

export const getViewingPasses = (state: State): ViewingPass[] => state.viewingPasses.data;
export const getViewingPassesLoading = (state: State): boolean => state.viewingPasses.loading;
export const getViewingPassDetails = (state: State): ViewingPass | null => state.viewingPassDetails.data;
export const getViewingPassDetailsLoading = (state: State): boolean => state.viewingPassDetails.loading;
export const getSavingProcessLoading = (state: State): boolean => state.savingProcess.loading;
export const getSavingProcessError = (state: State): boolean => state.savingProcess.error;
export const getOfflineViewingPasses = (state: State): ViewingPassCreation[] => state.offlineViewingPasses.data;
export const getOfflineViewingPassesSyncing = (state: State): boolean => state.offlineViewingPasses.syncing;
export const getOfflineViewingPassesSyncError = (state: State): boolean => state.offlineViewingPasses.syncError;
export const getIsOfflineSave = (state: State): boolean => state.savingProcess.offline;

