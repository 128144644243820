import { Action, createReducer, on } from '@ngrx/store';
import { ExclusiveAgreement } from "../exclusive-agreement.model"
import * as DataActions from './actions';

export interface State {
  exclusiveAgreements: {
    data: ExclusiveAgreement[];
    loading: boolean;
  };
  exclusiveAgreementDetails: {
    data: ExclusiveAgreement | null;
    loading: boolean;
  };
}

export const initialState: State = {
  exclusiveAgreements: {
    data: [],
    loading: false,
  },
  exclusiveAgreementDetails: {
    data: null,
    loading: false,
  },
};

const exclusiveAgreementReducer = createReducer(
  initialState,

  on(DataActions.LoadExclusiveAgreements, DataActions.LoadExclusiveAgreementsByCustomer, (state) => ({
    ...state,
    exclusiveAgreements: {
      loading: true,
      data: [],
    }
  })),
  on(DataActions.ExclusiveAgreementsLoaded, (state, { payload }) => ({
    ...state,
    exclusiveAgreements: {
      loading: false,
      data: payload,
    }
  })),
  on(DataActions.ExclusiveAgreementsLoadingFailed, (state) => ({
    ...state,
    exclusiveAgreements: {
      ...state.exclusiveAgreements,
      loading: false,
    },
  })),
  on(DataActions.LoadExclusiveAgreementDetails, (state) => ({
    ...state,
    exclusiveAgreementDetails: {
      loading: true,
      data: null,
    },
  })),
  on(DataActions.ExclusiveAgreementDetailsLoaded, (state, { payload }) => ({
    ...state,
    exclusiveAgreementDetails: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.ExclusiveAgreementDetailsLoadingFailed, (state) => ({
    ...state,
    exclusiveAgreementDetails: {
      ...state.exclusiveAgreementDetails,
      loading: false,
    },
  })),
  on(DataActions.DeleteExclusiveAgreementSuccess, (state, { exclusiveAgreementId }) => ({
    ...state,
    exclusiveAgreements: {
      ...state.exclusiveAgreements,
      data: state.exclusiveAgreements.data.filter((ea) => ea.id !== exclusiveAgreementId),
    }
  })),
);

export const reducer = (state: State | undefined, action: Action): State => exclusiveAgreementReducer(state, action);

export const getExclusiveAgreements = (state: State): ExclusiveAgreement[] => state.exclusiveAgreements.data;
export const getExclusiveAgreementsLoading = (state: State): boolean => state.exclusiveAgreements.loading;
export const getExclusiveAgreementDetails = (state: State): ExclusiveAgreement | null => state.exclusiveAgreementDetails.data;
export const getExclusiveAgreementDetailsLoading = (state: State): boolean => state.exclusiveAgreementDetails.loading;
