import { createReducer, on } from '@ngrx/store';
import { TeamInvitation, TeamModel } from '../team.model';
import * as TeamActions from './actions';

export interface State {
  teams: {
    data: TeamModel[];
    loading: boolean;
  };
  invitations: {
    data: TeamModel[];
    loading: boolean;
  };
  userId: string;
}

export const initialState: State = {
  teams: {
    data: [],
    loading: false,
  },
  invitations: {
    data: [],
    loading: false,
  },
  userId: '',
};

const teamReducer = createReducer(
  initialState,

  on(TeamActions.LoadTeams, (state) => ({
    ...state,
    teams: {
      ...state.teams,
      loading: true,
    },
  })),
  on(TeamActions.LoadTeamsSucceeded, (state, { payload }) => ({
    ...state,
    teams: {
      loading: false,
      data: payload,
    },
  })),
  on(TeamActions.LoadTeamsFailed, (state) => ({
    ...state,
    teams: {
      ...state.teams,
      loading: false,
    },
  })),
  on(TeamActions.LoadTeamsInvitations, (state) => ({
    ...state,
    invitations: {
      ...state.invitations,
      loading: true,
    },
  })),
  on(TeamActions.LoadTeamsInvitationsSucceeded, (state, { payload }) => ({
    ...state,
    invitations: {
      loading: false,
      data: payload,
    },
  })),
  on(TeamActions.LoadTeamsInvitationsFailed, (state) => ({
    ...state,
    invitations: {
      ...state.invitations,
      loading: false,
    },
  })),
  on(
    TeamActions.AddTeamMemberSuccess,
    TeamActions.RemoveTeamMemberSuccess,
    TeamActions.InviteTeamMemberSuccess,
    TeamActions.DeleteInvitationSuccess,
    (state, { payload }) => {
      const updatedTeam = payload;
      const existingTeams = state.teams.data.filter(
        (team) => team.id !== updatedTeam.id
      );

      return {
        ...state,
        teams: {
          data: [...existingTeams, updatedTeam],
          loading: false,
        },
      };
    }
  ),
  on(TeamActions.AcceptInvitationSuccess, (state, { payload }) => {
    const updatedTeam = payload;
    const existingTeams = state.teams.data.filter(
      (team) => team.id !== updatedTeam.id
    );

    const existingInvitations: TeamModel[] = state.invitations.data.filter(
      (team) => team.id !== updatedTeam.id
    );

    return {
      ...state,
      teams: {
        data: [...existingTeams, updatedTeam],
        loading: false,
      },
      invitations: {
        data: [...existingInvitations, updatedTeam],
        loading: false,
      },
    };
  }),
  on(TeamActions.DeclineInvitationSuccess, (state, { payload }) => {
    const updatedTeam = payload;

    const existingInvitations: TeamModel[] = state.invitations.data.filter(
      (team) => team.id !== updatedTeam.id
    );

    return {
      ...state,
      invitations: {
        data: [...existingInvitations],
        loading: false,
      },
    };
  }),
  on(TeamActions.UpdateUserId, (state, { userId }) => ({
    ...state,
    userId,
  }))
);

export const reducer = (state: State | undefined, action: any) =>
  teamReducer(state, action);

export const getTeams = (state: State) => state.teams.data;

export const getHasTeamMembers = (state: State): boolean => {
  const uniqueMembers = new Set();

  for (const team of state.teams.data) {
    for (const member of team.members) {
      uniqueMembers.add(member.userId);
    }
  }

  return uniqueMembers.size > 1;
};

export const getInvitations = (state: State) =>
  state.invitations.data
    .map((team) => mapInvitation(team))
    .reduce((prev, curr) => prev.concat(curr), [])
    .filter((invitation) => invitation.status === 'pending');

// teams created by user
export const getCreatorTeams = (state: State): TeamModel[] => {
  if (!state.userId) {
    return state.teams.data;
  }

  return state.teams.data.filter((team) => {
    for (const member of team.members) {
      if (member.isCreator && member.userId === state.userId) {
        return true;
      }
    }
    return false;
  });
};

// teams the user is a member but not creator
export const getParticipatingTeams = (state: State): TeamModel[] => {
  if (!state.userId) {
    return state.teams.data;
  }
  return state.teams.data.filter((team) => {
    for (const member of team.members) {
      if (!member.isCreator && member.userId === state.userId) {
        return true;
      }
    }
    return false;
  });
};

const mapInvitation = (team: TeamModel): TeamInvitation[] => {
  const teamInvitations: TeamInvitation[] = [];
  for (const invitation of team.invitations) {
    const creator = team.members.find((m) => m.isCreator);
    const creatorName = `${creator?.givenName} ${creator?.familyName}`.trim() ?? team.name;
    teamInvitations.push({
      ...invitation,
      date: new Date(invitation.date),
      name: creatorName,
      teamId: team.id,
    });
  }

  return teamInvitations;
};
