import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AgentProfile } from 'src/app/common-models/agent-profile';
import { AgentCompany } from 'src/app/common-models/agent-company';

@Injectable({ providedIn: 'root' })
export class PreListingApiFacade {
  constructor(private readonly apollo: Apollo) { }

  createPreListing$(
    objectId: string,
    agentProfile: AgentProfile,
    agentCompany: AgentCompany
  ): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation CreatePreListing(
          $objectId: String!
          $agentProfile: AgentProfile!
          $agentCompany: AgentCompany!
        ) {
          prelistingReport(
            objectId: $objectId
            agentProfile: $agentProfile
            agentCompany: $agentCompany
          )
        }
      `,
      variables: { objectId, agentProfile, agentCompany },
    }).pipe(map((result: any) => result.data.prelistingReport));
  }
}
