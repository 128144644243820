import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as fromReducer from './reducer';

export interface BusinessCustomerFeatureState {
  businessCustomer: fromReducer.State;
}

export const reducers: ActionReducerMap<BusinessCustomerFeatureState> = {
  businessCustomer: fromReducer.reducer,
};

export const getBusinessCustomerState = createFeatureSelector<fromReducer.State>('business-customer');

export const businessCustomerSelectors = {
  customers: createSelector(getBusinessCustomerState, fromReducer.getBusinessCustomers),
  customersLoading: createSelector(getBusinessCustomerState, fromReducer.getBusinessCustomersLoading),
  customerDetails: createSelector(getBusinessCustomerState, fromReducer.getBusinessCustomerDetails),
  customerDetailsLoading: createSelector(getBusinessCustomerState, fromReducer.getBusinessCustomerDetailsLoading),
};
