import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Router} from "@angular/router";
import {SnackbarService} from "services/snackbar.service";
import {AuthService} from "services/auth.service";
import * as DataActions from './actions';
import {catchError, switchMap} from "rxjs/operators";
import {DocumentsApiFacade} from "../documents-api.facade";

@Injectable()
export class DocumentEffects {

  public loadAllViewingPasses$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadAllViewingPasses),
      switchMap(() =>
        this._documentsApiFacade.loadAllViewingPasses$().pipe(
          switchMap((value) => [DataActions.AllViewingPassesLoaded({payload: value})]),
          catchError(() => [DataActions.AllViewingPassesLoadingFailed()])
        )
      )
    )
  );

  public loadAllExclusiveAgreements$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadAllExclusiveAgreements),
      switchMap(() =>
        this._documentsApiFacade.loadAllExclusiveAgreements$().pipe(
          switchMap((value) => [DataActions.AllExclusiveAgreementsLoaded({payload: value})]),
          catchError(() => [DataActions.AllExclusiveAgreementsLoadingFailed()])
        )
      )
    )
  );

  public loadAllPlainAgreements$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadAllPlainAgreements),
      switchMap(() =>
        this._documentsApiFacade.loadAllPlainAgreements$().pipe(
          switchMap((value) => [DataActions.AllPlainAgreementsLoaded({payload: value})]),
          catchError(() => [DataActions.AllPlainAgreementsLoadingFailed()])
        )
      )
    )
  );

  public loadAllRentalOffers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadAllRentalOffers),
      switchMap(() =>
        this._documentsApiFacade.loadAllRentalOffers$().pipe(
          switchMap((value) => [DataActions.AllRentalOffersLoaded({payload: value})]),
          catchError(() => [DataActions.AllRentalOffersLoadingFailed()])
        )
      )
    )
  );

  public loadAllBuyingOffers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadAllBuyingOffers),
      switchMap(() =>
        this._documentsApiFacade.loadAllBuyingOffers$().pipe(
          switchMap((value) => [DataActions.AllBuyingOffersLoaded({payload: value})]),
          catchError(() => [DataActions.AllBuyingOffersLoadingFailed()])
        )
      )
    )
  );

  public loadAllHandoverProtocols$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadAllHandoverProtocols),
      switchMap(() =>
        this._documentsApiFacade.loadAllHandoverProtocols$().pipe(
          switchMap((value) => [DataActions.AllHandoverProtocolsLoaded({payload: value})]),
          catchError(() => [DataActions.AllHandoverProtocolsLoadingFailed()])
        )
      )
    )
  );



  constructor(
    private readonly _actions$: Actions,
    private readonly _documentsApiFacade: DocumentsApiFacade,
  ) {
  }


}
