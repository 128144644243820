import { SnackbarService } from '../../services/snackbar.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  failedToLoadSettings,
  failedToSaveSettings,
  succeededToSaveSettings,
  loadSettings,
} from './settings.actions';
import { SettingsService } from 'src/app/services/settings.service';

@Injectable()
export class SettingsEffects {
  constructor(
    private actions$: Actions,
    private snackbarService: SnackbarService,
    private settingsService: SettingsService
  ) {}

  triggerLoadSettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadSettings),
        map(() => this.settingsService.loadSettings())
      ),
    { dispatch: false }
  );

  triggerSaveSettingsSuccessSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(succeededToSaveSettings),
        map(() =>
          this.snackbarService.showSnackbar(
            'Einstellungen erfolgreich gespeichert',
            'mat-primary',
            true
          )
        )
      ),
    { dispatch: false }
  );

  triggerSaveSettingsErrorSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(failedToSaveSettings),
        map(() =>
          this.snackbarService.showSnackbar(
            'Einstellungen konnten nicht gespeichert werden',
            'mat-warn'
          )
        )
      ),
    { dispatch: false }
  );

  triggerLoadSettingsErrorSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(failedToLoadSettings),
        map(() =>
          this.snackbarService.showSnackbar(
            'Einstellungen konnten nicht geladen werden',
            'mat-warn',
            true
          )
        )
      ),
    { dispatch: false }
  );
}
