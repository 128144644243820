import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {LoadingSpinnerService} from "services/loading-spinner-service";
import {Router} from "@angular/router";

@Injectable()
export class LoadingSpinnerInterceptor implements HttpInterceptor {
  constructor(
    private loadingService: LoadingSpinnerService,
    private _router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const excludedRoute = this._router.url;

    const excludedUrlPattern = new RegExp(`^\\/objects\\/[a-fA-F0-9]{24}\\/marketing$`);
    const shouldShowSpinner = !excludedUrlPattern.test(excludedRoute);

    if (shouldShowSpinner) {
      this.loadingService.show();
    }

    return next.handle(req).pipe(
      finalize(() => {
        this.loadingService.hide(); // Ensure this only gets called once per request
      })
    );
  }
}
