import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidenav-button',
  template: `
    <a
      [routerLink]="route"
      routerLinkActive="activeRoute"
      class="flex items-center gap-x-2"
    >
      <img [src]="imageSource" [alt]="text" class="block w-8" />
      <div class="inline-block self-center lg:block">{{ text }}</div>
    </a>
  `,
  styleUrls: ['./sidenav-button.component.scss'],
})
export class SidenavButtonComponent {
  @Input() imageSource!: string;
  @Input() route = 'not-existing-route';
  @Input() text!: string;
}
