import { CountrySettings } from "@types";
import { Language } from "src/app/i18n/config";

export const mapCountrySettingsToLanguage = (countrySettings: CountrySettings): Language => {
  switch (countrySettings) {
    case CountrySettings.DE:
      return Language.DE;
    case CountrySettings.EN:
      return Language.EN;
    case CountrySettings.AT:
    default:
      return Language.AT;
  }
};
