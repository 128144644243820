import { createAction, props } from '@ngrx/store';
import { Profile } from './profile.reducers';

export const loadProfile = createAction('[Profile] Load');

export const setProfile = createAction(
  '[Profile] Set',
  props<{ profile: Profile }>()
);

export const succeededToSaveProfile = createAction(
  '[Profile] Succeeded to save Profile'
);

export const failedToSaveProfile = createAction(
  '[Profile] Failed to save Profile'
);

export const succeededToLoadProfile = createAction(
  '[Profile] Succeeded to load Profile'
);

export const failedToLoadProfile = createAction(
  '[Profile] Failed to load Profile'
);
