import { AuthService } from '../services/auth.service';
import { Profile } from '../store/profile/profile.reducers';
import { AgentCompany } from './agent-company';
import { AgentProfile } from './agent-profile';

export function getAgentCompany(authService: AuthService): AgentCompany {
  return {
    legalEntity: authService.legalEntity || '',
    officeName: authService.officeName || '',
    phone: authService.phone || '',
    officeAddress: authService.officeAddress || '',
    companyLogo: authService.companyLogo || '',
  };
}

export function getAgentProfile(authService: AuthService): AgentProfile {
  return {
    givenName: authService.firstName || '',
    familyName: authService.lastName || '',
    email: authService.email || '',
    picture: authService.picture || '',
    isRemax: authService.isRemax || false,
    themeConfig: authService.themeConfig || '',
    companyLogo: authService.companyLogo ?? '',
  };
}

export function mapProfileToAgentProfile(
  profile: Profile,
  picture?: string,
): AgentProfile {
  return {
    givenName: profile.givenName || '',
    familyName: profile.familyName || '',
    email: profile.email || '',
    picture: picture || '',
    isRemax: profile.isRemax || false,
    themeConfig: profile.themeConfig || '',
    companyLogo: profile.companyLogo ?? '',
  };
}

export function mapProfileToAgentCompany(profile: Profile): AgentCompany {
  return {
    legalEntity: profile.legalEntity || '',
    officeName: profile.officeName || '',
    phone: profile.phone || '',
    officeAddress: profile.officeAddress || '',
    companyLogo: profile.companyLogo || '',
  };
}
