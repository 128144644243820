import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { SnackbarService } from "services/snackbar.service";
import { RentalContractApiFacade } from "../rental-contract-api.facade";
import * as DataActions from './actions';
import { catchError, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { RentalContract } from "../rental-contract.model";
import { CustomerWithContact } from "@domains/customer";
import { Router } from "@angular/router";

@Injectable()
export class RentalContractEffects {
  public loadRentalContracts$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadRentalContracts),
      switchMap(({ objectId }) =>
        this._rentalContractApiFacade.loadRentalContracts$(objectId).pipe(
          switchMap((value) => [DataActions.RentalContractsLoaded({ payload: value })]),
          catchError(() => [DataActions.RentalContractsLoadingFailed()])
        )
      )
    )
  );

  public loadRentalContractByCustomer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadRentalContractsByCustomer),
      switchMap(({ customerId }) =>
        this._rentalContractApiFacade.loadRentalContractsByCustomer$(customerId).pipe(
          switchMap((value) => [DataActions.RentalContractsLoaded({ payload: value })]),
          catchError(() => [DataActions.RentalContractsLoadingFailed()])
        )
      )
    )
  );

  public saveRentalContract$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveRentalContract),
      switchMap(({ rentalContract, isFinal }) => {
        const contractTenantsWithoutCustomers = rentalContract.contractTenants.map(
          (contractTenant: CustomerWithContact) => {
            const {
              id,
              title,
              firstname,
              lastname,
              birthdate,
              contact,
              nationality,
              ...contractTenantWithoutCustomer
            } = contractTenant;
            return {
              ...contractTenantWithoutCustomer,
              customerId: (contractTenant as any).customerId,
            };
          }
        );
        const contractLandlordsWithoutCustomers = rentalContract.contractLandlords.map(
          (contractLandlord: CustomerWithContact) => {
            const {
              id,
              title,
              firstname,
              lastname,
              birthdate,
              contact,
              nationality,
              ...contractLandlordWithoutCustomer
            } = contractLandlord;
            return {
              ...contractLandlordWithoutCustomer,
              customerId: (contractLandlord as any).customerId,
            };
          }
        );
        const rentalContractWithoutCustomers = {
          ...rentalContract,
          contractTenants: contractTenantsWithoutCustomers,
          contractLandlords: contractLandlordsWithoutCustomers,
        };

        return this._rentalContractApiFacade.saveRentalContract$(rentalContractWithoutCustomers).pipe(
          switchMap((rc) => {
            if (isFinal) {
              this._router.navigateByUrl(`/objects/${rc.objectId}/digital-contracts`);

              return [DataActions.CreateRentalContractSucceded()];
            }
            return [];
          })
        );
      })
    ),
  );

  public updateRentalContractCustomers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.UpdateRentalContractCustomers),
      switchMap(({ objectId, rentalContractId, contractTenants, contractLandlords }) =>
        this._rentalContractApiFacade.loadRentalContractDetails$(objectId, rentalContractId).pipe(
          switchMap((rentalContract) => {
            const updatedContractTenants = rentalContract.contractTenants.map((t, index) => ({
              ...t,
              customerId: contractTenants[index]?.id,
            }));
            rentalContract.contractTenants = updatedContractTenants;

            const updatedContractLandlords = rentalContract.contractLandlords.map((t, index) => ({
              ...t,
              customerId: contractLandlords[index]?.id,
            }));
            rentalContract.contractLandlords = updatedContractLandlords;

            const { ...rentalContractWithoutSignature } = rentalContract;

            return [DataActions.SaveRentalContract({
              rentalContract: rentalContractWithoutSignature as RentalContract,
              isFinal: false,
            })];
          })
        )
      ),
    )
  );

  public rentalContractCreationSucceeded$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreateRentalContractSucceded),
      map(() =>
        this._snackbarService.showSnackbar(
          'Mietvertrag erfolgreich erstellt',
          'mat-primary',
          true
        )
      )
    ),
    { dispatch: false }
  );

  public loadRentalContractDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadRentalContractDetails),
      switchMap(({ objectId, rentalContractId }) =>
        this._rentalContractApiFacade.loadRentalContractDetails$(objectId, rentalContractId).pipe(
          switchMap((value) => [DataActions.RentalContractDetailsLoaded({ payload: value })]),
          catchError(() => [DataActions.RentalContractDetailsLoadingFailed()])
        )
      )
    )
  );

  deleteRentalContract$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.DeleteRentalContract),
      mergeMap(({ rentalContractId, objectId }) =>
        this._rentalContractApiFacade.deleteRentalContract$(rentalContractId).pipe(
          switchMap(() => {
            this._snackbarService.showSnackbar(
              'Mietvertrag erfolgreich gelöscht',
              'mat-primary',
              true
            );
            return [DataActions.DeleteRentalContractSuccess({ rentalContractId }), DataActions.LoadRentalContracts({ objectId })];
          }), catchError(() => {
            this._snackbarService.showSnackbar(
              'Mietvertrag konnte nicht gelöscht werden',
              'mat-warn',
              true
            );
            return [DataActions.DeleteRentalContractFailed({ rentalContractId })];
          })
        )
      ),
    ),
  );

  public orderRentalContract$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.OrderRentalContract),
      switchMap(({ objectId, rentalContractId, agentProfile, agentCompany }) =>
        this._rentalContractApiFacade.orderRentalContract$(
          objectId,
          rentalContractId,
          agentProfile,
          agentCompany,
        ).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              'Mietvertrag erfolgreich beauftragt',
              'mat-primary',
              true
            );
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Mietvertrag konnte nicht beauftragt werden',
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  )

  constructor(
    private readonly _actions$: Actions,
    private readonly _router: Router,
    private readonly _snackbarService: SnackbarService,
    private readonly _rentalContractApiFacade: RentalContractApiFacade
  ) { }
}
