import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import { BusinessCustomerApiFacade } from '../business-customer-api.facade';
import { BusinessCustomerActions } from './action-types';
import {SnackbarService} from "services/snackbar.service";

@Injectable()
export class BusinessCustomerEffects {

  deleteBusinessCustomer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BusinessCustomerActions.DeleteBusinessCustomer),
      mergeMap(({ businessCustomerId }) =>
        this._businessCustomerApiFacade.deleteBusinessCustomer(businessCustomerId).pipe(
          switchMap(() => {
            this._snackbarService.showSnackbar(
              'Kunde erfolgreich gelöscht',
              'mat-primary',
              true
            );
            return [BusinessCustomerActions.DeleteBusinessCustomerSuccess({ businessCustomerId })];
          }), catchError(() => {
            this._snackbarService.showSnackbar(
              'Kunde konnte nicht gelöscht werden',
              'mat-warn',
              true
            );
            return [BusinessCustomerActions.DeleteBusinessCustomerFailed({ businessCustomerId })];
          })
        )
      ),
    ),
  );

  public loadBusinessCustomers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BusinessCustomerActions.LoadBusinessCustomers),
      switchMap(() =>
        this._businessCustomerApiFacade.getAllCustomers$().pipe(
          switchMap((customers) => [
            BusinessCustomerActions.LoadBusinessCustomersSucceeded({ payload: customers }),
          ]),
          catchError(() => [BusinessCustomerActions.LoadBusinessCustomersFailed()])
        )
      )
    )
  );

  public loadBusinessCustomerDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(BusinessCustomerActions.LoadBusinessCustomerDetails),
      switchMap(({payload}) =>
        this._businessCustomerApiFacade.getCustomerById$(payload.id).pipe(
          switchMap((customer) => [
            BusinessCustomerActions.LoadBusinessCustomerSucceeded({ payload: customer }),
          ]),
          catchError(() => [BusinessCustomerActions.LoadBusinessCustomerFailed()])
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _businessCustomerApiFacade: BusinessCustomerApiFacade,
    private readonly _snackbarService: SnackbarService
  ) { }
}
