import { RentalContract } from "../rental-contract.model";
import {createReducer, on, Action} from "@ngrx/store";
import * as DataActions from './actions';

export interface State {
  rentalContracts: {
    data: RentalContract[];
    loading: boolean;
  };
  rentalContractDetails: {
    data: RentalContract | null;
    loading: boolean;
  };
}

export const initialState: State = {
  rentalContracts: {
    data: [],
    loading: false,
  },
  rentalContractDetails: {
    data: null,
    loading: false,
  },
};

const rentalContractReducer = createReducer(
  initialState,

  on(DataActions.LoadRentalContracts, DataActions.LoadRentalContractsByCustomer, (state) => ({
    ...state,
    rentalContracts: {
      loading: true,
      data: [],
    },
  })),
  on(DataActions.RentalContractsLoaded, (state, { payload }) => ({
    ...state,
    rentalContracts: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.RentalContractsLoadingFailed, (state) => ({
    ...state,
    rentalContracts: {
      ...state.rentalContracts,
      loading: false,
    },
  })),
  on(DataActions.LoadRentalContractDetails, (state) => ({
    ...state,
    rentalContractDetails: {
      loading: true,
      data: null,
    },
  })),
  on(DataActions.RentalContractDetailsLoaded, (state, { payload }) => ({
    ...state,
    rentalContractDetails: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.RentalContractDetailsLoadingFailed, (state) => ({
    ...state,
    rentalContractDetails: {
      ...state.rentalContractDetails,
      loading: false,
    },
  })),
);

export const reducer = (state: State | undefined, action: Action): State => rentalContractReducer(state, action);
export const getRentalContracts = (state: State): RentalContract[] => state.rentalContracts.data;
export const getRentalContractsLoading = (state: State): boolean => state.rentalContracts.loading;
export const getRentalContractDetails = (state: State): RentalContract | null => state.rentalContractDetails.data;
export const getRentalContractDetailsLoading = (state: State): boolean => state.rentalContractDetails.loading;
