import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {GraphQLModule} from "services/graphql.module";
import {EffectsModule} from "@ngrx/effects";
import {ExposeGeneratorEffects} from "./store/effects";
import {StoreModule} from "@ngrx/store";
import * as fromReducer from './store/reducer';

@NgModule({
  imports: [
    CommonModule,
    GraphQLModule,
    EffectsModule.forFeature([ExposeGeneratorEffects]),
    StoreModule.forFeature('exposeGenerator', fromReducer.reducer)
  ],
})
export class ExposeGeneratorDomainModule {}
