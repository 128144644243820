import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BannerModule } from '../banner/banner.module';
import { AvatarModule } from '../avatar/avatar.module';
import { ActionPanelComponent } from './action-panel.component';
import { TeamsBannerModule } from '../teams-banner/teams-banner.module';
import { IntegrationStatusesModule } from '../../sidebar/integration-statuses/integration-statuses.module';
import {TranslationComponentModule} from "../../i18n/TranslationComponentModule";

@NgModule({
  declarations: [ActionPanelComponent],
  imports: [
    CommonModule,
    BannerModule,
    TeamsBannerModule,
    AvatarModule,
    RouterModule,
    MatTooltipModule,
    IntegrationStatusesModule,
    TranslationComponentModule,
  ],
  exports: [ActionPanelComponent],
})
export class ActionPanelModule { }
