import { createAction, props } from '@ngrx/store';
import { Invitation, TeamModel } from '../team.model';

export const LoadTeams = createAction('[Team] Load Teams');
export const LoadTeamsFailed = createAction('[Team] Failed to load Teams');
export const LoadTeamsSucceeded = createAction(
  '[Team] Succeeded to load Teams',
  props<{ payload: TeamModel[] }>()
);
export const LoadTeamsInvitations = createAction(
  '[Team] Load Teams Invitations'
);
export const LoadTeamsInvitationsFailed = createAction(
  '[Team] Failed to load Teams Invitations'
);
export const LoadTeamsInvitationsSucceeded = createAction(
  '[Team] Succeeded to load Teams Invitations',
  props<{ payload: TeamModel[] }>()
);

export const AddTeamMember = createAction(
  '[Team] Add Team Member',
  props<{ teamId: string; email: string }>()
);
export const AddTeamMemberSuccess = createAction(
  '[Team] Succeeded to add Team Member',
  props<{ payload: TeamModel }>()
);
export const AddTeamMemberFailed = createAction(
  '[Team] Failed to add Team Member'
);

export const AcceptInvitation = createAction(
  '[Team] AcceptInvitation',
  props<{ teamId: string; email: string }>()
);
export const AcceptInvitationSuccess = createAction(
  '[Team] Succeeded to accept Invitation',
  props<{ payload: TeamModel }>()
);
export const AcceptInvitationFailed = createAction(
  '[Team] Failed to accept Invitation'
);

export const DeclineInvitation = createAction(
  '[Team] Decline Invitation',
  props<{ teamId: string; email: string }>()
);
export const DeclineInvitationSuccess = createAction(
  '[Team] Succeeded to decline Invitation',
  props<{ payload: TeamModel }>()
);
export const DeclineInvitationFailed = createAction(
  '[Team] Failed to decline Invitation'
);

export const ResendInvitation = createAction(
  '[Team] Resend Invitation',
  props<{ teamId: string; email: string }>()
);

export const DeleteInvitation = createAction(
  '[Team] Delete Invitation',
  props<{ teamId: string; email: string }>()
);
export const DeleteInvitationSuccess = createAction(
  '[Team] Succeeded to Delete Invitation',
  props<{ payload: TeamModel }>()
);
export const DeleteInvitationFailed = createAction(
  '[Team] Failed to Delete Invitation'
);

export const InviteTeamMember = createAction(
  '[Team] Invite Team Member',
  props<{ teamId: string; email: string }>()
);
export const InviteTeamMemberSuccess = createAction(
  '[Team] Succeeded to invite Team Member',
  props<{ payload: TeamModel }>()
);
export const InviteTeamMemberFailed = createAction(
  '[Team] Failed to invite Team Member'
);

export const RemoveTeamMember = createAction(
  '[Team] Remove Team Member',
  props<{ teamId: string; userId: string }>()
);
export const RemoveTeamMemberSuccess = createAction(
  '[Team] Succeeded to remove Team Member',
  props<{ payload: TeamModel }>()
);
export const RemoveTeamMemberFailed = createAction(
  '[Team] Failed to remove Team Member'
);

export const UpdateUserId = createAction(
  '[Team] Update User Id',
  props<{ userId: string }>()
);
