import { createSelector, ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromReducer from './reducer';
export interface RentalContractFeatureState {
  rentalContract: fromReducer.State;
}

export const reducers: ActionReducerMap<RentalContractFeatureState> = {
  rentalContract: fromReducer.reducer,
};
export const getRentalContractState = createFeatureSelector<fromReducer.State>('rental-contract');
export const rentalContractSelectors = {
  rentalContracts: createSelector(getRentalContractState, fromReducer.getRentalContracts),
  rentalContractsLoading: createSelector(getRentalContractState, fromReducer.getRentalContractsLoading),
  rentalContractDetails: createSelector(getRentalContractState, fromReducer.getRentalContractDetails),
  rentalContractDetailsLoading: createSelector(getRentalContractState, fromReducer.getRentalContractDetailsLoading),
};
