import {Component, Inject, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {HeadingsModule} from "../headings/headings.module";

export interface DialogData {
  name: string;
  link: string;
}

@Component({
  standalone: true,
  selector: 'restore-connection-dialog',
  templateUrl: 'restore-connection-dialog.html',
  styles: [``],
  imports: [CommonModule, MatDialogModule, HeadingsModule],
})

export class RestoreConnectionDialog {

  constructor(
    private dialogRef: MatDialogRef<RestoreConnectionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    dialogRef.disableClose = false;
    dialogRef.addPanelClass('unset-default-padding'); // see global styles (MatDialog-styling is tricky
  }

  abortClicked(): void {
    this.dialogRef.close();
  }

  restoreClicked(link: string): void {
    window.open(link, '_self');
    this.dialogRef.close('restore');
  }
}
