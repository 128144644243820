import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromReducer from './store/reducer';
import { GraphQLModule } from 'services/graphql.module';
import { CustomerPortalApiFacade } from './customer-portal-api.facade';
import { CustomerPortalEffects } from './store/effects';

@NgModule({
  imports: [
    CommonModule,
    GraphQLModule,
    EffectsModule.forFeature([CustomerPortalEffects]),
    StoreModule.forFeature('customer-portal', fromReducer.reducer)
  ],
  providers: [CustomerPortalApiFacade],
})
export class CustomerPortalDomainModule {}
