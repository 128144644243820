import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { EdirealService } from 'services/edireal.service';
import { SidebarSyncItemComponent } from './sync-item.component';
import { ObjectDataFacade } from '@domains/object';
import { map } from 'rxjs/operators';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'sidebar-sync-block',
  template: `
    <div *ngIf="isCustomerSyncInProgress || (isDuplicationCheckVisible$ | async)" class="mt-2">
      <h3 class="font-source leading-5 text-sm sm:text-base font-bold">Sync</h3>
      <div class="mt-2">
        <sidebar-sync-item *ngIf="isCustomerSyncInProgress" class="mb-2" [size]="size" text="Kunden werden geladen"></sidebar-sync-item>
        <div *ngIf="duplicationCheck$ | async as duplicationCheck">
          <sidebar-sync-item *ngIf="duplicationCheck.loading" [size]="size" text="Duplikate prüfen"></sidebar-sync-item>
          <button *ngIf="!duplicationCheck.loading && duplicationCheck.hasUpdate" mat-flat-button color="warn" (click)="reloadProperties()">Objekte neu laden</button>
        </div>
      </div>
    </div>
  `,
  styles: [`
  `],
  standalone: true,
  imports: [CommonModule, MatButtonModule, SidebarSyncItemComponent],
})
export class SidebarSyncBlockComponent {
  @Input() size: 'sm' | 'lg' = 'lg';

  duplicationCheck$ = this._objectDataFacade.duplicationCheck$;
  isDuplicationCheckVisible$ = this.duplicationCheck$.pipe(map((v) => v.loading || v.hasUpdate));

  get isCustomerSyncInProgress(): boolean {
    return this._edirealService.isCustomerSyncInProgress;
  }

  constructor(
    private readonly _edirealService: EdirealService,
    private readonly _objectDataFacade: ObjectDataFacade,
  ) { }

  reloadProperties(): void {
    this._objectDataFacade.resetPropertiesDuplicationCheck();
    this._objectDataFacade.loadObjects();
  }
}
