import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  ASSIGNMENTCOVERAGE,
  COSTCOVERAGE,
  DEPOSITMETHOD,
  LANDLORDACCEPTANCE,
  PROPERTYTYPE,
  RENTALDURATION,
  RentalOffer,
  TENANCYLAWAPPLICATION,
  TENANTSELFDISCLOSURE,
  VALORIZATION,
} from './rental-offer.model';
import { Subscription } from 'rxjs';
import { BusinessCustomerFormService } from '@domains/business-customer';
import { CustomerType } from 'src/app/common-components/customer-type-selector/model';
import { CustomerFormService, CustomerWithContact } from '@domains/customer';
import { Object, RealtyPriceItem } from "@domains/object";

@Injectable({ providedIn: 'root' })
export class RentalOfferFormService implements OnDestroy {
  phoneValidator = Validators.pattern('[- +()0-9]+');

  private _subscription = new Subscription();

  constructor(
    private readonly _customerFormService: CustomerFormService,
    private readonly _businessCustomerFormService: BusinessCustomerFormService,
    private readonly _fb: FormBuilder
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public convertCustomerToFormGroup(c: CustomerWithContact) {
    return this._customerFormService.convertToFormGroup(c);
  }

  public createGeneralInformationForm(object?: any): FormGroup {
    const generalInfo = this._fb.group({
      propertyType: PROPERTYTYPE.ALLEINEIGENTUM,
      propertyTypeNotes: '',
      shareInOwnership: '',
      tenancyLaw: false,
      tenancyLawApplication: TENANCYLAWAPPLICATION.FULL,
      rentalStart: [new Date().toISOString().substring(0, 10)],
      rentalDuration: RENTALDURATION.LIMITED,
      rentDurationDetails: object?.rentalSpecifications?.rentDurationDetails ?? '',
      maximumRentalPeriod: object?.rentalSpecifications?.maximumRentalPeriod ?? 0,
      unlimitedRentalPeriod: object?.rentalSpecifications?.unlimitedRentalPeriod ?? false,
      waiverOfCancellationPeriod: object?.rentalSpecifications?.waiverOfCancellationPeriod ?? 0,
      rentalEnd: [new Date().toISOString().substring(0, 10)],
    });

    const rentalDurationControl = generalInfo.controls.rentalDuration;

    this._subscription.add(rentalDurationControl.valueChanges.subscribe((value) => {
      const rentalEndControl = generalInfo.controls.rentalEnd;

      if (value === RENTALDURATION.LIMITED) {
        rentalEndControl.setValue(generalInfo.controls.rentalStart.value);
        rentalEndControl.enable();
      } else {
        rentalEndControl.setValue(null);
        rentalEndControl.disable();
      }
    }));

    return generalInfo;
  }

  public createPriceDetailsForm(object?: Object): FormGroup {
    return this._fb.group({
      purchase_price: this.createPriceDetailsItemsForm(object?.realtyPrice?.purchase_price),
      rent: this.createPriceDetailsItemsForm(object?.realtyPrice?.rent),
      furniture_hire: this.createPriceDetailsItemsForm(object?.realtyPrice?.furniture_hire),
      parking: this.createPriceDetailsItemsForm(object?.realtyPrice?.parking),
      garage: this.createPriceDetailsItemsForm(object?.realtyPrice?.garage),
      operating: this.createPriceDetailsItemsForm(object?.realtyPrice?.operating),
      heating: this.createPriceDetailsItemsForm(object?.realtyPrice?.heating),
      hot_water: this.createPriceDetailsItemsForm(object?.realtyPrice?.hot_water),
      cooling: this.createPriceDetailsItemsForm(object?.realtyPrice?.cooling),
      maintenance_funds: this.createPriceDetailsItemsForm(object?.realtyPrice?.maintenance_funds),
      elevator: this.createPriceDetailsItemsForm(object?.realtyPrice?.elevator),
      others: this.createPriceDetailsItemsForm(object?.realtyPrice?.others),
    });
  }

  public createPriceDetailsItemsForm(realtyPrice?: RealtyPriceItem): FormGroup {
    return this._fb.group({
      currency: realtyPrice?.currency ?? '',
      net: realtyPrice?.net ?? 0,
      gross: realtyPrice?.gross ?? 0,
      vat_absolute: realtyPrice?.vat_absolute ?? 0,
      vat_percentage: realtyPrice?.vat_percentage ?? 0,
    });
  }

  public createMonthlyBillingForm(object?: Object): FormGroup {
    return this._fb.group({
      houseRent: object?.price ?? 0,
      tax: 0,
      serviceCharges: 0,
      otherCosts: 0,
      houseRentTotal: 0,
      billingNotes: '',
      usageBoundCosts: this._fb.group({
        electricity: false,
        gas: false,
        heating: false,
        telecommunication: false,
        water: false,
        other: false,
        otherDetails: '',
      }),
      vpi: '',
      valorization: VALORIZATION.ANNUALLY,
      vpiValueAdjustmentMonth: '',
      vpiValueAdjustmentChange: 0,
    });
  }

  public createHandoverAndConditionForm(): FormGroup {
    return this._fb.group({
      handoverDate: null,
      handoverDateNotes: '',
      handoverConditionDate: null,
      handoverConditionNotes: '',
      secondViewingDate: null,
      removeCondition: '',
      addingCondition: '',
    });
  }

  public createDepositDrafterFeeForm(object?: any): FormGroup {
    return this._fb.group({
      singlePayment: this._fb.group({
        singlePayment: false,
        singlePaymentInfo: '',
        singlePaymentAmount: 0,
      }),
      rentalDeposit: this._fb.group({
        depositAmount: 0,
        depositMethod: DEPOSITMETHOD.NOTDEFINED,
        depositDescription: object?.rentalSpecifications?.rentalDeposit?.depositDescription ?? '',
      }),
      drafterOfDeeds: this._fb.group({
        contractPartnerId: null,
        autoSendContractPartnerEmail: false,
        hasDrafterOfDeeds: false,
        name: '',
        address: '',
        phone: ['', this.phoneValidator],
        email: '',
        costCoverage: COSTCOVERAGE.TENANT,
        assignmentCoverage: ASSIGNMENTCOVERAGE.AGENT,
        drafterOfDeedsCosts: object?.rentalSpecifications?.drafterOfDeedsCosts ?? '',
      }),
      legalTransactionFee: this._fb.group({
        hasTransactionFee: false,
        tax: [0, [Validators.min(0), Validators.max(100)]],
        feeBase: [0, [Validators.min(0)]],
        amount: [0, [Validators.min(0)]],
      }),
    });
  }

  public createAdditionalDetailsForm(): FormGroup {
    return this._fb.group({
      subleaseForbidden: false,
      usageRules: false,
      gotUsageRules: false,
      houseRules: false,
      gotHouseRules: false,
      additionalDetails: false,
      additionalDetailsInfo: '',
    });
  }

  public createTimeLimitationForm(): FormGroup {
    return this._fb.group({
      date: [new Date().toISOString().substring(0, 10)],
    });
  }

  public createLandlordDetailsForm(): FormGroup {
    return this._fb.group({
      customerId: '',
    });
  }

  public createLandlordsArrayItemForm(): FormGroup {
    return this._fb.group({
      customer: this._customerFormService.createCustomerFormGroup(true),
      landlord: this.createLandlordDetailsForm(),
    });
  }

  public createDocumentsArrayItemForm(): FormGroup {
    return this._fb.group({
      id: '',
      description: '',
      type: '',
      storageUrl: '',
      title: '',
      documentFrom: new Date().toISOString().substring(0, 10),
      state: ''
    });
  }

  public createTenantsArrayItemForm(): FormGroup {
    return this._fb.group({
      customer: this._customerFormService.createCustomerFormGroup(true),
      tenant: this.createTenantDetailsForm(),
    });
  }

  public createPrintSettingsForm(): FormGroup {
    return this._fb.group({
      hideLandlordOnPrint: false,
    })
  }

  public createTenantOfferForm(): FormGroup {
    return this._fb.group({
      noLimitation: true,
      limitationNotes: '',
    })
  }

  public addCustomerValidators(customerGroup: FormGroup): void {
    const firstnameControl = customerGroup.get('firstname');
    const lastnameControl = customerGroup.get('lastname');
    const emailControl = customerGroup.get('contact.email');

    if (firstnameControl) {
      firstnameControl.setValidators([Validators.required]);
      firstnameControl.updateValueAndValidity();
    }
    if (lastnameControl) {
      lastnameControl.setValidators([Validators.required]);
      lastnameControl.updateValueAndValidity();
    }
    if (emailControl) {
      emailControl.setValidators([Validators.email, Validators.required]);
      emailControl.updateValueAndValidity();
    }

    customerGroup.markAllAsTouched();
    customerGroup.markAsDirty();
  }

  public createTenantDetailsForm(): FormGroup {
    return this._fb.group({
      customerId: '',
      isSponsor: false,
      creditworthinessCertificateIsConsideredForRent: false,
      isAwareOfRentCertificates: false,
      selfDisclosureIsAttached: false,
      selfDisclosureDate: new Date(),
      selfDisclosureType: TENANTSELFDISCLOSURE.MISC,
      selfDisclosureTypeDescription: '',
      sharesCreditworthinessInformation: false,
      sharesCreditworthinessInformationOfPreviousTenant: false,
      phoneNumberOfPreviousTenant: ['', this.phoneValidator],
      previousTenantNotes: '',
      sharesCreditworthinessInformationOfEmployer: false,
      phoneNumberOfEmployer: ['', this.phoneValidator],
      employerNotes: '',
      sharesCreditworthinessOther: false,
      sharesCreditworthinessOtherInfo: ''
    });
  }

  public createPropertyDescription(object?: Object): FormGroup {
    return this._fb.group({
      rooms: object?.rooms ?? 0,
      hasKitchen: false,
      hasBathroom: false,
      hasBalcony: false,
      hasLobby: false,
      hasCentralHeating: false,
      hasCellar: false,
      hasParkingSlot: false,
      miscDetails: object?.description ?? '',
    });
  }

  public createRentalOfferForm(object?: Object): FormGroup {
    const form = this._fb.group({
      propertyDescription: this.createPropertyDescription(object),
      generalInformation: this.createGeneralInformationForm(object),
      realtyPrice: this.createPriceDetailsForm(object),
      monthlyBilling: this.createMonthlyBillingForm(object),
      handoverAndCondition: this.createHandoverAndConditionForm(),
      depositDrafterFee: this.createDepositDrafterFeeForm(object),
      additionalDetails: this.createAdditionalDetailsForm(),
      printSettings: this.createPrintSettingsForm(),
      timeLimitation: this.createTimeLimitationForm(),
      tenantRestriction: this.createTenantOfferForm(),
      tenantType: [CustomerType.Private],
      tenants: this._fb.array([]),
      businessTenant: this._fb.group({
        data: this._businessCustomerFormService.createBusinessCustomerFormGroup(),
        customers: this._businessCustomerFormService.createPrivateCustomers(),
        isSponsor: false,
        creditworthinessCertificateIsConsideredForRent: false,
        isAwareOfRentCertificates: false,
        selfDisclosureIsAttached: false,
        selfDisclosureDate: new Date(),
        selfDisclosureType: TENANTSELFDISCLOSURE.MISC,
        selfDisclosureTypeDescription: '',
        sharesCreditworthinessInformation: false,
        sharesCreditworthinessInformationOfPreviousTenant: false,
        phoneNumberOfPreviousTenant: ['', this.phoneValidator],
        previousTenantNotes: '',
        sharesCreditworthinessInformationOfEmployer: false,
        phoneNumberOfEmployer: ['', this.phoneValidator],
        employerNotes: '',
        sharesCreditworthinessOther: false,
        sharesCreditworthinessOtherInfo: ''
      }),
      landlordType: [CustomerType.Private],
      landlords: this._fb.array([]),
      businessLandlord: this._fb.group({
        data: this._businessCustomerFormService.createBusinessCustomerFormGroup(),
        customers: this._businessCustomerFormService.createPrivateCustomers(),
      }),
      landlordAcceptance: [LANDLORDACCEPTANCE.NOTDEFINED],
      documents: this._fb.array([]),
    });

    form.get('tenantType')?.valueChanges.subscribe((value) => {
      if (value === CustomerType.Private) {
        form.get('businessTenant')?.disable();
        form.get('tenants')?.enable();
      } else if (value === CustomerType.Business) {
        form.get('businessTenant')?.enable();
        form.get('tenants')?.disable();
      }
    });

    form.get('landlordType')?.valueChanges.subscribe((value) => {
      if (value === CustomerType.Private) {
        form.get('businessLandlord')?.disable();
        form.get('landlords')?.enable();
      } else if (value === CustomerType.Business) {
        form.get('businessLandlord')?.enable();
        form.get('landlords')?.disable();
      }
    });

    return form;
  }

  public createRentalOfferInitialValue(object: Object): RentalOffer {
    const rentalOfferForm = this.createRentalOfferForm(object);
    const tenantForm = this.createTenantDetailsForm();
    const landlordForm = this.createLandlordDetailsForm();

    return {
      ...rentalOfferForm.value,
      objectId: object.id,
      state: 'pending',
      lastVisited: '',
      tenantType: CustomerType.Private,
      tenants: [tenantForm.value],
      businessTenant: undefined,
      landlordType: CustomerType.Private,
      landlords: [landlordForm.value],
      businessLandlord: undefined,
    };
  }
}
