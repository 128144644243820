import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { objectSelectors, ObjectFeatureState } from './store';
import { ObjectActions } from './store/action-types';
import {
  Object,
  ObjectCreation,
  ObjectSettings,
  ObjectStatus,
  ObjectsCountByAgent,
  ObjectDocument, UserMappings, UpdateUserMapping,
} from './object.model';
import { Observable } from 'rxjs';
import { ObjectApiFacade } from './object-api.facade';

@Injectable({ providedIn: 'root' })
export class ObjectDataFacade {
  cadastralCommunities$ = this._store$.select(objectSelectors.cadastralCommunities);

  availableAgents$ = this._store$.select(objectSelectors.availableAgents);

  objectsByAgents$ = this._store$.select(objectSelectors.objectsByAgents);
  objectsLoading$ = this._store$.select(objectSelectors.objectsLoading);

  objectDetails$ = this._store$.select(objectSelectors.objectDetails);
  objectDetailsLoading$ = this._store$.select(objectSelectors.objectDetailsLoading);
  objectDetailsSyncing$ = this._store$.select(objectSelectors.objectDetailsSyncing);

  propertySharingData$ = this._store$.select(objectSelectors.propertySharingData);
  propertySharingLoading$ = this._store$.select(objectSelectors.propertySharingLoading);

  propertyActivities$ = this._store$.select(objectSelectors.propertyActivitiesData);
  propertyActivitiesLoading$ = this._store$.select(objectSelectors.propertyActivitiesLoading);

  objectOrderType$ = this._store$.select(objectSelectors.objectOrderType);
  objectType$ = this._store$.select(objectSelectors.objectType);
  objectId$ = this._store$.select(objectSelectors.objectId);
  objectAddress$ = this._store$.select(objectSelectors.objectAddress);

  duplicationCheck$ = this._store$.select(objectSelectors.duplicationCheck);

  constructor(
    private readonly _store$: Store<ObjectFeatureState>,
    private readonly _objectApiFacade: ObjectApiFacade
  ) { }

  public loadCadastralCommunities(): void {
    this._store$.dispatch(ObjectActions.LoadCadastralCommunities());
  }

  public loadObjectDetails(objectId: string): void {
    this._store$.dispatch(ObjectActions.LoadObjectDetails({ objectId }));
  }

  public loadPropertySharingData(objectId: string): void {
    this._store$.dispatch(ObjectActions.LoadPropertySharingData({ objectId }));
  }

  public loadPropertyActivities(objectId: string): void {
    this._store$.dispatch(ObjectActions.LoadPropertyActivites({ objectId }));
  }

  public syncObjectDetails(objectId: string): void {
    this._store$.dispatch(ObjectActions.SyncObjectDetails({ objectId }));
  }

  public linkEdirealObject(id: string, externalId: string): void {
    this._store$.dispatch(ObjectActions.LinkEdirealObject({ id, externalId }));
  }

  public loadObjects(): void {
    this._store$.dispatch(ObjectActions.InitObjects());
  }

  public loadObjectsByAgent(agentName: string): void {
    this._store$.dispatch(ObjectActions.LoadObjectsByAgent({ agentName }));
  }

  public searchObjects(searchText: string): void {
    this._store$.dispatch(ObjectActions.SearchObjects({ searchText }));
  }

  public setAvailableAgents(agents: ObjectsCountByAgent[]): void {
    this._store$.dispatch(ObjectActions.AvailableAgentNamesLoaded({ payload: agents }));
  }

  public createObject(data: ObjectCreation): void {
    this._store$.dispatch(ObjectActions.CreateObject({ data }));
  }

  public updateOnOfficeProperty(propertyId: string): void {
    this._store$.dispatch(ObjectActions.UpdateOnOfficeProperty({ propertyId }));
  }

  public saveProperty(object: any): void {
    this._store$.dispatch(ObjectActions.SaveProperty({ object }));
  }

  public startPropertiesDuplicationCheck(): void {
    this._store$.dispatch(ObjectActions.StartPropertiesDuplicationCheck());
  }

  public resetPropertiesDuplicationCheck(): void {
    this._store$.dispatch(ObjectActions.ResetPropertiesDuplicationCheck());
  }

  public updatePropertyPartially(data: Partial<Object>): void {
    this._store$.dispatch(ObjectActions.UpdatePropertyPartially({ data }));
  }

  public updateObject(data: Object): void {
    this._store$.dispatch(ObjectActions.UpdateObject({ data }));
  }

  public deleteObjects(objectIds: string[]): void {
    this._store$.dispatch(ObjectActions.DeleteObjects({ objectIds }));
  }

  public createPropertySharing(objectId: string, email: string): void {
    this._store$.dispatch(ObjectActions.CreatePropertySharing({ objectId, email }));
  }

  public deletePropertySharing(objectId: string, sharingId: string): void {
    this._store$.dispatch(ObjectActions.DeletePropertySharing({ objectId, sharingId }));
  }

  public updateObjectStatus(
    objectId: string,
    objectStatus: ObjectStatus
  ): void {
    this._store$.dispatch(ObjectActions.UpdateObjectStatus({ objectId, objectStatus }));
  }

  public triggerExternalObjectsLoading(): void {
    this._store$.dispatch(ObjectActions.TriggerExternalObjectsLoadingSucceeded());
  }

  public getObjectsByAgent(agentName: string): Observable<Object[]> {
    return this._store$.select(objectSelectors.objectsByAgent(agentName));
  }

  public getObjectsLoadingByAgent(agentName: string): Observable<boolean> {
    return this._store$.select(objectSelectors.objectsLoadingByAgent(agentName));
  }

  public loadExternalObjectDetails$(objectId: string): Observable<Object> {
    return this._objectApiFacade.loadExternalObjectDetails$(objectId);
  }

  public createProperty$(object: any): Observable<Object> {
    return this._objectApiFacade.saveProperty$(object)
  }

  public createObject$(data: ObjectCreation): Observable<Object> {
    return this._objectApiFacade.createObject$(data);
  }

  public getUserMappings$(): Observable<UserMappings> {
    return this._objectApiFacade.loadUserMappings$();
  }

  public getPropupModel$(): Observable<any> {
    return this._objectApiFacade.loadPropupModel$();
  }

  public updateUserMappings$(mappings: UpdateUserMapping): Observable<UserMappings> {
    return this._objectApiFacade.updateUserMapping$(mappings);
  }

  public importOnOfficeProperty$(importId: string): Observable<string> {
    return this._objectApiFacade.importOnOfficeProperty$(importId);
  }

  public createAndSaveProperty$(property: any): Observable<Object> {
    return this._objectApiFacade.createObject$(property)
  }

  public getObjectRelatedDocuments$(id: string): Observable<ObjectDocument[]> {
    return this._objectApiFacade.getObjectRelatedDocuments$(id);
  }

  public importEdirealObject$(edirealPropertyId: string): Observable<boolean> {
    return this._objectApiFacade.importEdirealObject$(edirealPropertyId);
  }

  public updateObjectStatus$(
    objectId: string,
    objectStatus: ObjectStatus
  ): Observable<Object> {
    return this._objectApiFacade.updateObjectStatus$(objectId, objectStatus);
  }

  public deleteObjects$(objectIds: string[]): Observable<Object[]> {
    return this._objectApiFacade.deleteObjects$(objectIds);
  }

  public getObjectSettings$(objectId: string): Observable<ObjectSettings | null> {
    return this._objectApiFacade.getObjectSettings$(objectId);
  }

  public createObjectSettings$(objectId: string): Observable<ObjectSettings> {
    return this._objectApiFacade.createObjectSettings$(objectId);
  }

  public updateObjectSettings$(objectId: string, settings: ObjectSettings): Observable<ObjectSettings> {
    return this._objectApiFacade.updateObjectSettings$(objectId, settings);
  }

  public updateObject$(data: Object): Observable<Object> {
    return this._objectApiFacade.updateObject$(data);
  }

  public searchObjects$(searchText: string): Observable<Record<string, Object[]>> {
    return this._objectApiFacade.searchObjects$(searchText);
  }
}
