import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageManagementComponent } from './image-management.component';
import { HeadingsModule } from '../headings/headings.module';
import { ButtonModule } from '../button/button.module';
import { ImagesInputComponent } from './images-input/images-input.component';
import { ImageListComponent } from './image-list/image-list.component';
import { ImageListItemComponent } from './image-list-item/image-list-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    ImageManagementComponent,
    ImagesInputComponent,
    ImageListComponent,
    ImageListItemComponent,
  ],
  exports: [ImageManagementComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HeadingsModule,
    ButtonModule,
    MatInputModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    DragDropModule,
  ],
})
export class ImageManagementModule {}
