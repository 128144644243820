import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {Store} from "@ngrx/store";
import {RoleProviderService} from "services/role-provider.service";
import {AccessService} from "services/access-service";
import {selectProfile} from "../../store/profile/profile.selectors";
import {switchMap, take, tap} from "rxjs/operators";
import {TranslationComponentModule} from "../../i18n/TranslationComponentModule";
import {TranslationService} from "../../i18n/TranslationService";

@Component({
  selector: 'account-status',
  templateUrl: 'account-status.component.html',
  styles: [``],
  standalone: true,
  imports: [CommonModule, TranslationComponentModule],
})

export class AccountStatusComponent implements OnInit {
  @Input() title = this._translationService.instant('account_status.title');
  accountStatus = '';
  link = 'https://calendly.com/mreikersdorfer/propup';

  propupRole = this._roleProviders.getRoles()
  hasSpecialOffer = this._roleProviders.hasRole('propup-special-offer')

  hasFreeVersion$ = this.accessService.hasFreeVersion$;
  newRegistrationDays = 0;
  constructor(
    private readonly _store: Store,
    private readonly _roleProviders: RoleProviderService,
    public accessService: AccessService,
    private _translationService: TranslationService,
  ) {
  }

  ngOnInit() {
    this._store.select(selectProfile).pipe(
      take(2),
      tap((p) => {
        const createdDate = p.createdTimeStamp
        const currentDate = Date.now()
        const diff = (currentDate - createdDate)
        const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
        this.newRegistrationDays = diffDays
      }),
      switchMap(() => this.accessService.hasFreeVersion$),
      tap((hasFreeVersion) => { this.getAccountInfo(hasFreeVersion) })
    ).subscribe()
  }

  getAccountInfo(hasFreeVersion: boolean) {

    if (this.newRegistrationDays <= 14) {
      this.accountStatus = 'new_registration'
    } else {
      if (this.propupRole.includes('propup-test-user')) {
        this.accountStatus = 'test_account'
      } else if (this.propupRole.includes('propup-internal-user')) {
        this.accountStatus = 'internal'
      } else if (this.propupRole.includes('propup-special-offer')) {
        this.accountStatus = 'remax_special'
      } else if (hasFreeVersion) {
        this.accountStatus = 'remax_free'
      }

      else {
        this.accountStatus = 'test_ended'
      }
    }
  }

}
