/* eslint-disable rxjs/no-implicit-any-catch */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, catchError, map, mergeMap } from 'rxjs/operators';

import { SnackbarService } from 'services/snackbar.service';
import * as DataActions from './actions';
import { CreditCheckApiFacade } from "../credit-check-api.facade";

@Injectable()
export class CreditCheckEffects {
  public createCreditCheck$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreateCreditCheck),
      switchMap(({ creditCheck, agentCompany, agentProfile }) =>
        this._creditCheckApiFacade.createCreditCheck$(creditCheck, agentCompany, agentProfile).pipe(
          switchMap((value) => [DataActions.CreateCreditCheckSucceded({ payload: value })]),
          catchError(() => [DataActions.CreateCreditCheckFailed()])
        )
      )
    )
  );

  public createCreditCheckFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DataActions.CreateCreditCheckFailed),
        map(() =>
          this._snackbarService.showSnackbar(
            'KontoCheck konnte nicht erzeugt werden',
            'mat-warn',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public createCreditCheckSucceeded$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreateCreditCheckSucceded),
      map(() =>
        this._snackbarService.showSnackbar(
          'KontoCheck erfolgreich erstellt',
          'mat-primary',
          true
        )
      )
    ),
    { dispatch: false }
  );

  public loadCreditChecks$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadCreditChecks),
      switchMap(({ customerId }) =>
        this._creditCheckApiFacade.loadCreditChecks$(customerId).pipe(
          switchMap((value) => [DataActions.CreditChecksLoaded({ payload: value })]),
          catchError(() => [DataActions.CreditChecksDetailsLoadingFailed()])
        )
      )
    )
  );

  public deleteCreditCheck$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.DeleteCreditCheck),
      mergeMap(({ creditCheckId }) =>
        this._creditCheckApiFacade.deleteCreditCheck$(creditCheckId).pipe(
          switchMap(() => {
            this._snackbarService.showSnackbar(
              'KontoCheck erfolgreich gelöscht',
              'mat-primary',
              true
            );
            return [DataActions.DeleteCreditCheckSuccess({ creditCheckId })];
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'KontoCheck konnte nicht gelöscht werden',
              'bg-warn',
              true
            );
            return [DataActions.DeleteCreditCheckFailed()];
          }),
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _snackbarService: SnackbarService,
    private readonly _creditCheckApiFacade: CreditCheckApiFacade
  ) { }
}
