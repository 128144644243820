import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScreenSizeIndicatorComponent } from './screen-size-indicator.component';

@NgModule({
  declarations: [ScreenSizeIndicatorComponent],
  imports: [CommonModule],
  exports: [ScreenSizeIndicatorComponent],
})
export class ScreenSizeIndicatorModule {}
