import { NgModule } from '@angular/core';
import {ApolloClientOptions, ApolloLink, InMemoryCache} from '@apollo/client/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../environments/environment';
import { SentryLink } from 'apollo-link-sentry';

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  return {
    link: ApolloLink.from([
      new SentryLink(
        {
          attachBreadcrumbs: {
            includeQuery: true,
            includeVariables: true,
            includeFetchResult: true,
            includeError: true,
            includeCache: true,
            includeContext: ['operationName'],
          },
          setFingerprint: true,
          setTransaction: true,
          uri: environment.backendBaseUrl,
        }
      ),
      httpLink.create({ uri: environment.backendBaseUrl }),
    ]),
    cache: new InMemoryCache({ addTypename: false }),
  };
}

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
