import { createAction, props } from '@ngrx/store';
import { OwnerPortal, CreateOwnerPortal } from '../owner-portal.model';

export const LoadOwnerPortals = createAction(
  '[OwnerPortal] Load Owner Portals',
  props<{ objectId: string }>(),
);
export const LoadOwnerPortalsByOwner = createAction(
  '[OwnerPortal] Load Owner Portals By Owner',
  props<{ customerId: string }>(),
);
export const OwnerPortalsLoaded = createAction(
  '[OwnerPortal] Owner Portals Loaded',
  props<{ payload: OwnerPortal[] }>(),
);
export const OwnerPortalsLoadingFailed = createAction('[OwnerPortal] Owner Portals Loading Failed');


export const LoadOwnerPortalDetails = createAction(
  '[OwnerPortal] Load Owner Portal Details',
  props<{ id: string }>(),
);
export const OwnerPortalDetailsLoaded = createAction(
  '[OwnerPortal] Owner Portal Details Loaded',
  props<{ payload: OwnerPortal }>(),
);
export const OwnerPortalDetailsLoadingFailed = createAction(
  '[OwnerPortal] Owner Portal Details Loading Failed'
);

export const SaveOwnerPortal = createAction(
  '[OwnerPortal] Save Owner Portal',
  props<{ data: CreateOwnerPortal; isFinal?: boolean }>(),
);
export const SaveOwnerPortalSucceded = createAction(
  '[OwnerPortal] Succeeded To Create And Save Owner Portal',
  props<{ showToast: boolean }>(),
);
export const SaveOwnerPortalFailed = createAction(
  '[OwnerPortal] Failed To Create And Save Owner Portal',
  props<{ showToast: boolean }>(),
);

export const SendOwnerPortalEmail = createAction(
  '[OwnerPortal] Send Owner Portal Email',
  props<{
    objectId: string;
    id: string;
    emailData: any;
  }>(),
);

export const DeleteOwnerPortal = createAction(
  '[OwnerPortal] Delete Owner Portal',
  props<{ id: string }>()
);
export const DeleteOwnerPortalSucceded = createAction(
  '[OwnerPortal] Owner Portal Deleted',
  props<{ id: string }>()
);
export const DeleteOwnerPortalFailed = createAction('[OwnerPortal] Owner Portal Delete Failed');
