import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'services/auth.service';

@Injectable({ providedIn: 'root' })
export class TeamFormService {
  constructor(
    private readonly _fb: FormBuilder,
    private readonly _authService: AuthService
  ) {}

  public createTeamMemberForm(): FormGroup {
    return this._fb.group({
      email: ['', [Validators.email]],
    });
  }
}
