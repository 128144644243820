<div class="flex">
  <div>
    <div class="flex space-x-4 items-center justify-end">
      <common-user-avatar size="mid"></common-user-avatar>
      <div class="font-source text-right">
        <div *ngIf="agentProfile$ | async as profile" class="text-xl font-bold leading-tight truncate w-44">
          {{ profile.givenName + ' ' + profile.familyName }}
        </div>
        <div class="text-primary">
          <a [routerLink]="['settings/profile']">{{ 'general.edit_profile' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
