import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { GraphQLModule } from "services/graphql.module";
import { BuyingOfferEffects } from "./store/effects";
import * as fromReducer from './store/reducer';

@NgModule({
  imports: [
    CommonModule,
    GraphQLModule,
    EffectsModule.forFeature([BuyingOfferEffects]),
    StoreModule.forFeature('buying-offer', fromReducer.reducer)
  ]
})
export class BuyingOfferDomainModule { }
