import { createAction, props } from '@ngrx/store';
import { CreditCheck } from "../credit-check.model";
import { AgentCompany } from 'src/app/common-models/agent-company';
import {AgentProfile} from "../../../common-models/agent-profile";

export const CreateCreditCheck = createAction(
  '[CreditCheck] Create CreditCheck',
  props<{
    creditCheck: CreditCheck,
    agentCompany: AgentCompany;
    agentProfile: AgentProfile;
  }>()
);
export const CreateCreditCheckFailed = createAction('[CreditCheck] Failed to create Credit Check');
export const CreateCreditCheckSucceded = createAction(
  '[CreditCheck] Succeeded to create Credit Check',
  props<{ payload: CreditCheck }>(),
);

export const LoadCreditCheckDetails = createAction('[CreditCheck] Load Credit Check Details', props<{ customerId: string; creditCheckId: string; }>())

export const LoadCreditChecks = createAction('[CreditCheck] Load Credit Checks', props<{ customerId: string }>());
export const CreditChecksLoaded = createAction('[CreditCheck] Credit Checks Loaded', props<{ payload: CreditCheck[] }>());
export const CreditChecksDetailsLoadingFailed = createAction('[CreditCheck] Credit Checks Details Loading Failed');

export const DeleteCreditCheck = createAction('[CreditCheck] Delete Credit Check', props<{ creditCheckId: string }>());
export const DeleteCreditCheckSuccess = createAction('[CreditCheck] Delete Credit Check Succeeded', props<{ creditCheckId: string }>());
export const DeleteCreditCheckFailed = createAction('[CreditCheck] Delete Credit Check Failed');
