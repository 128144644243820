import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromReducer from './store/reducer';
import { GraphQLModule } from 'services/graphql.module';
import { OwnerPortalApiFacade } from './owner-portal-api.facade';
import { OwnerPortalEffects } from './store/effects';

@NgModule({
  imports: [
    CommonModule,
    GraphQLModule,
    EffectsModule.forFeature([OwnerPortalEffects]),
    StoreModule.forFeature('owner-portal', fromReducer.reducer)
  ],
  providers: [OwnerPortalApiFacade],
})
export class OwnerPortalDomainModule { }
