import { Action, createReducer, on } from '@ngrx/store';
import * as DataActions from './actions';
import { CreditCheck } from "../credit-check.model";

export interface State {
  creditChecks: {
    data: CreditCheck[];
    loading: boolean;
  }
  creditCheckDetails: {
    data: CreditCheck | null;
    loading: boolean;
  };
}

export const initialState: State = {
  creditChecks: {
    data: [],
    loading: false,
  },
  creditCheckDetails: {
    data: null,
    loading: false,
  },
};

const creditCheckReducer = createReducer(
  initialState,

  on(DataActions.LoadCreditChecks, (state) => ({
    ...state,
    creditChecks: {
      loading: true,
      data: [],
    }
  })),
  on(DataActions.CreditChecksLoaded, (state, { payload }) => ({
    ...state,
    creditChecks: {
      loading: false,
      data: payload,
    }
  })),
  on(DataActions.CreditChecksDetailsLoadingFailed, (state) => ({
    ...state,
    creditChecks: {
      ...state.creditChecks,
      loading: false,
    },
  })),
  on(DataActions.LoadCreditCheckDetails, (state) => ({
    ...state,
    creditCheckDetails: {
      loading: true,
      data: null,
    },
  })),

  on(DataActions.CreateCreditCheck, (state) => ({
    ...state,
    creditChecks: {
      ...state.creditChecks,
      loading: true,
    },
  })),
  on(DataActions.CreateCreditCheckSucceded, (state, { payload }) => ({
    ...state,
    creditChecks: {
      data: [...state.creditChecks.data, payload],
      loading: false,
    },
  })),
  on(
    DataActions.DeleteCreditCheckSuccess,
    (state, { creditCheckId }) => ({
      ...state,
      creditChecks: {
        ...state.creditChecks,
        data: state.creditChecks.data.filter((creditCheck) => creditCheck.id !== creditCheckId),
      },
    })
  )
);

export const reducer = (state: State | undefined, action: Action): State =>
  creditCheckReducer(state, action);

export const getCreditCheckDetails = (state: State): CreditCheck | null => state.creditCheckDetails.data;
export const getCreditCheckDetailsLoading = (state: State): boolean => state.creditCheckDetails.loading
export const getCreditChecks = (state: State): CreditCheck[] => state.creditChecks.data;
export const getCreditChecksLoading = (state: State): boolean => state.creditChecks.loading;
