import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { SnackbarService } from 'services/snackbar.service';
import * as DataActions from './actions';
import { OwnerPortalApiFacade } from '../owner-portal-api.facade';

@Injectable()
export class OwnerPortalEffects {
  public loadOwnerPortals$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadOwnerPortals),
      switchMap(({ objectId }) =>
        this._ownerPortalApiFacade.loadOwnerPortals$(objectId).pipe(
          switchMap((value) => [DataActions.OwnerPortalsLoaded({ payload: value })]),
          catchError(() => [DataActions.OwnerPortalsLoadingFailed()])
        )
      )
    )
  );

  public loadOwnerPortalDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadOwnerPortalDetails),
      switchMap(({ id }) =>
        this._ownerPortalApiFacade.loadOwnerPortalDetails$(id).pipe(
          switchMap((value) => [DataActions.OwnerPortalDetailsLoaded({ payload: value })]),
          catchError(() => [DataActions.OwnerPortalDetailsLoadingFailed()])
        )
      )
    )
  );

  public saveOwnerPortal$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveOwnerPortal),
      switchMap(({ isFinal, data }) =>
        this._ownerPortalApiFacade.saveOwnerPortal$(data).pipe(
          switchMap((pass) => {
            if (isFinal) {
              this._router.navigateByUrl(`/objects/${pass.objectId}/customer-portal`);

              return [DataActions.SaveOwnerPortalSucceded({ showToast: true })];
            }

            return [DataActions.SaveOwnerPortalSucceded({ showToast: false })];
          }),
          catchError(() => [DataActions.SaveOwnerPortalFailed({ showToast: isFinal ?? true })]),
        ))
    ),
  );

  public createOwnerPortalSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveOwnerPortalSucceded),
      tap(({ showToast }) => {
        if (showToast) {
          this._snackbarService.showSnackbar(
            'Abgeberportal erfolgreich erstellt',
            'mat-primary',
            true
          );
        }
      })
    ),
    { dispatch: false }
  );

  public createOwnerPortalFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveOwnerPortalFailed),
      tap(({ showToast }) => {
        if (showToast) {
          this._snackbarService.showSnackbar(
            'Fehler beim Erstellen des Abgeberportals',
            'mat-warn',
            true
          );
        }
      })
    ),
    { dispatch: false }
  );

  public sendOwnerPortalEmail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendOwnerPortalEmail),
      switchMap(({ objectId, id, emailData }) =>
        this._ownerPortalApiFacade.sendOwnerPortalEmail$(objectId, id, emailData).pipe(
          tap((success) => {
            if (success) {
              this._snackbarService.showSnackbar(
                'Abgeberportal E-Mail erfolgreich versendet',
                'mat-primary',
                true
              );
            } else {
              throwError(new Error());
            }
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Abgeberportal E-Mail konnte nicht versendet werden',
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  deleteOwnerPortal$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.DeleteOwnerPortal),
      switchMap(({ id }) =>
        this._ownerPortalApiFacade.deleteOwnerPortal$(id).pipe(
          switchMap((success) => {
            const resultAction = success ?
              [DataActions.DeleteOwnerPortalSucceded({ id })] :
              [DataActions.DeleteOwnerPortalFailed()];

            return resultAction;
          }),
        )
      ),
    )
  );

  constructor(
    private readonly _router: Router,
    private readonly _actions$: Actions,
    private readonly _snackbarService: SnackbarService,
    private readonly _ownerPortalApiFacade: OwnerPortalApiFacade,
  ) { }
}
