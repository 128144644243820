import { createAction, props } from '@ngrx/store';
import { BusinessCustomer, BusinessCustomerWithCustomersDetails } from '../business-customer.model';

export const LoadBusinessCustomers = createAction('[BusinessCustomer] Load Business Customers');
export const LoadBusinessCustomersFailed = createAction('[BusinessCustomer] Failed to load Business Customers');
export const LoadBusinessCustomersSucceeded = createAction(
  '[BusinessCustomer] Succeeded to load Business Customers',
  props<{ payload: BusinessCustomer[] }>()
);

export const LoadBusinessCustomerDetails = createAction(
  '[BusinessCustomer] Load Business Customer',
  props<{ payload: { id: string } }>()
);
export const LoadBusinessCustomerFailed = createAction('[BusinessCustomer] Failed to load Business Customer');
export const LoadBusinessCustomerSucceeded = createAction(
  '[BusinessCustomer] Succeeded to load Business Customer',
  props<{ payload: BusinessCustomerWithCustomersDetails }>()
);

export const DeleteBusinessCustomer = createAction('[Customer] Delete Business Customer', props<{ businessCustomerId: string }>());
export const DeleteBusinessCustomerSuccess = createAction('[Customer] Delete Business Customer Succeeded', props<{ businessCustomerId: string }>());
export const DeleteBusinessCustomerFailed = createAction('[Customer] Delete Business Customer Failed', props<{ businessCustomerId: string }>());
