import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import {Component, Input, ViewContainerRef} from '@angular/core';
import { Portal, PortalModule, TemplatePortal } from '@angular/cdk/portal';
import { MatIconModule } from '@angular/material/icon';
import { RightSidenavService } from 'services/right-sidenav.service';
import { AvatarModule } from 'src/app/common-components/avatar/avatar.module';
import { IntegrationStatusesModule } from 'src/app/sidebar/integration-statuses/integration-statuses.module';
import { SidebarProfileBlockComponent } from 'src/app/sidebar/profile-block/profile-block.component';
import { SidebarSyncBlockComponent } from 'src/app/sidebar/sync-block/sync-block.component';
import {SidebarActionItemComponent} from "./actions-block/action-item.component";
import {AccountStatusComponent} from "./account-status-block/account-status.component";
import {TranslationComponentModule} from "../i18n/TranslationComponentModule";
import {BannerBlockComponent} from "./banner-block/banner-block.component";


@Component({
  selector: 'right-sidebar',
  templateUrl: 'sidebar.component.html',
  standalone: true,
  imports: [
    CommonModule,
    PortalModule,
    MatIconModule,
    AvatarModule,
    SidebarProfileBlockComponent,
    SidebarSyncBlockComponent,
    IntegrationStatusesModule,
    SidebarActionItemComponent,
    AccountStatusComponent,
    TranslationComponentModule,
    BannerBlockComponent,
  ]
})

export class RightSidebarComponent {
  @Input() isExpanded = true;
  @Input() isMobile = true;

  selectedPortal: Portal<any> | null = null;

  constructor(
    private readonly _rightSidenavService: RightSidenavService,
    private readonly _viewContainerRef: ViewContainerRef,
    private readonly _observer: BreakpointObserver,
  ) {
    this._rightSidenavService.sidenavContent$.subscribe((templateRef) => {
      if (!templateRef) {
        this.selectedPortal = null;

        return;
      }

      this.selectedPortal = new TemplatePortal(templateRef, this._viewContainerRef);

    });
  }

  toggleSidenav() {
    if (this.isExpanded|| this.isMobile) {
      this._rightSidenavService.triggerClose();
    }

    if (!this.isMobile) {
      this._rightSidenavService.toggleSidenav();
      this.isExpanded = !this.isExpanded;
    }
  }
}
