import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntegrationWrapperComponent } from './integration-wrapper.component';
import {RestoreConnectionDialog} from "../restore-connection-dialog/restore-connection-dialog";

@NgModule({
  declarations: [IntegrationWrapperComponent],
  imports: [CommonModule, RestoreConnectionDialog],
  exports: [IntegrationWrapperComponent],
})
export class IntegrationWrapperModule { }
