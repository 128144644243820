import { createReducer, on } from '@ngrx/store';
import { ContractPartnerActions } from './action-types';
import { ContractPartner, ContractPartnerWithCustomersDetails } from '../contract-partner.model';

export interface State {
  customers: {
    data: ContractPartner[];
    loading: boolean;
  };
  customerDetails: {
    data?: ContractPartnerWithCustomersDetails;
    loading: boolean;
  };
}

export const initialState: State = {
  customers: {
    data: [],
    loading: false,
  },
  customerDetails: {
    data: undefined,
    loading: false,
  },
};

const contractPartnerReducer = createReducer(
  initialState,

  on(ContractPartnerActions.LoadContractPartners, (state) => ({
    ...state,
    customers: {
      ...state.customers,
      loading: true,
    },
  })),
  on(ContractPartnerActions.LoadContractPartnersSucceeded, (state, { payload }) => ({
    ...state,
    customers: {
      loading: false,
      data: payload,
    },
  })),
  on(ContractPartnerActions.LoadContractPartnersFailed, (state) => ({
    ...state,
    customers: {
      ...state.customers,
      loading: false,
    },
  })),

  on(ContractPartnerActions.LoadContractPartnerDetails, (state) => ({
    ...state,
    customerDetails: {
      data: undefined,
      loading: true,
    },
  })),
  on(ContractPartnerActions.LoadContractPartnerSucceeded, (state, { payload }) => ({
    ...state,
    customerDetails: {
      loading: false,
      data: payload,
    },
  })),
  on(ContractPartnerActions.LoadContractPartnerFailed, (state) => ({
    ...state,
    customerDetails: {
      ...state.customerDetails,
      loading: false,
    },
  })),
);

export const reducer = (state: State | undefined, action: any) =>
  contractPartnerReducer(state, action);

export const getContractPartners = (state: State) => state.customers.data;
export const getContractPartnersLoading = (state: State) => state.customers.loading;

export const getContractPartnerDetails = (state: State) => state.customerDetails.data;
export const getContractPartnerDetailsLoading = (state: State) => state.customerDetails.loading;

