import * as fromReducer from './reducer';
import {ActionReducerMap, createFeatureSelector, createSelector} from "@ngrx/store";
export interface ExposeGeneratorFeatureState {
  exposeGenerator: fromReducer.State;
}

export const reducers: ActionReducerMap<ExposeGeneratorFeatureState> = {
  exposeGenerator: fromReducer.reducer,
};

export const getExposeGeneratorState = createFeatureSelector<fromReducer.State>('exposeGenerator');

export const exposeGeneratorSelectors = {
  exposees: createSelector(getExposeGeneratorState, fromReducer.getExposees),
  exposeesLoading: createSelector(getExposeGeneratorState, fromReducer.getExposeesLoading),
}
