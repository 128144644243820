import {
  createSelector,
  ActionReducerMap,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromReducer from './reducer';

export interface ObjectFeatureState {
  object: fromReducer.State;
}

export const reducers: ActionReducerMap<ObjectFeatureState> = {
  object: fromReducer.reducer,
};

export const getObjectState =
  createFeatureSelector<fromReducer.State>('object');

export const objectSelectors = {
  objectsByAgent: (name: string) => createSelector(
    getObjectState,
    fromReducer.getObjectsByAgent(name),
  ),
  objectsLoadingByAgent: (name: string) => createSelector(
    getObjectState,
    fromReducer.getObjectsLoadingByAgent(name),
  ),

  objectsByAgents: createSelector(getObjectState, fromReducer.getObjectsByAgents),
  objectsLoading: createSelector(getObjectState, fromReducer.getObjectsLoading),

  cadastralCommunities: createSelector(getObjectState, fromReducer.getCadastralCommunities),
  availableAgents: createSelector(getObjectState, fromReducer.getAvailableAgents),
  objectDetails: createSelector(getObjectState, fromReducer.getObjectDetails),
  objectDetailsLoading: createSelector(
    getObjectState,
    fromReducer.getObjectDetailsLoading
  ),
  propertySharingData: createSelector(getObjectState, fromReducer.getPropertySharingData),
  propertySharingLoading: createSelector(
    getObjectState,
    fromReducer.getPropertySharingLoading
  ),
  propertyActivitiesData: createSelector(getObjectState, fromReducer.getPropertyActivitiesData),
  propertyActivitiesLoading: createSelector(
    getObjectState,
    fromReducer.getPropertyActivitiesLoading
  ),
  objectDetailsSyncing: createSelector(
    getObjectState,
    fromReducer.getObjectDetailsSyncing,
  ),
  objectOrderType: createSelector(
    getObjectState,
    fromReducer.getObjectOrderType
  ),
  objectType: createSelector(getObjectState, fromReducer.getObjectType),
  objectId: createSelector(getObjectState, fromReducer.getObjectId),
  objectAddress: createSelector(getObjectState, fromReducer.getObjectAddress),

  duplicationCheck: createSelector(getObjectState, fromReducer.getDuplicationCheck),
};
