import {createAction, props} from "@ngrx/store";
import {ViewingPass} from "@domains/viewing-pass";
import {ExclusiveAgreement} from "../../exclusive-agreement";
import {RentalOffer} from "@domains/rental-offer";
import {BuyingOffer} from "@domains/buying-offer";
import {HandoverProtocol} from "@domains/handover-protocol";
import {PlainAgreement} from "../../plain-agreement";

//Viewing Passes
export const LoadAllViewingPasses = createAction(
  '[DOCUMENTS] Load all Viewing Passes',
);
export const AllViewingPassesLoaded = createAction(
  '[DOCUMENTS] All Viewing Passes Loaded',
  props<{ payload: ViewingPass[] }>(),
);
export const AllViewingPassesLoadingFailed = createAction('[DOCUMENTS] All Viewing Passes Loading Failed');
export const DeleteViewingPass = createAction(
  '[DOCUMENTS] Delete Viewing Pass',
  props<{ viewingPassId: string }>(),
);

//Exclusive Agreements
export const LoadAllExclusiveAgreements = createAction(
  '[DOCUMENTS] Load all Exclusive Agreements',
);
export const AllExclusiveAgreementsLoaded = createAction(
  '[DOCUMENTS] All Exclusive Agreements Loaded',
  props<{ payload: ExclusiveAgreement[]}>(),
);
export const DeleteExclusiveAgreement = createAction(
  '[DOCUMENTS] Delete Exclusive Agreement',
  props<{ exclusiveAgreementId: string }>(),
)
export const AllExclusiveAgreementsLoadingFailed = createAction('[DOCUMENTS] All Exclusive Agreements Loading Failed');

//Plain Agreements
export const LoadAllPlainAgreements = createAction(
  '[DOCUMENTS] Load all Plain Agreements',
);
export const AllPlainAgreementsLoaded = createAction(
  '[DOCUMENTS] All Plain Agreements Loaded',
  props<{ payload: PlainAgreement[]}>(),
);
export const DeletePlainAgreement = createAction(
  '[DOCUMENTS] Delete Plain Agreement',
  props<{ plainAgreementId: string }>(),
)
export const AllPlainAgreementsLoadingFailed = createAction('[DOCUMENTS] All Plain Agreements Loading Failed');

//RentalOffers
export const LoadAllRentalOffers = createAction(
  '[DOCUMENTS] Load all Rental Offers',
);
export const AllRentalOffersLoaded = createAction(
  '[DOCUMENTS] All Rental Offers Loaded',
  props<{ payload: RentalOffer[]}>(),
);
export const AllRentalOffersLoadingFailed = createAction('[DOCUMENTS] All Rental Offers Loading Failed');
export const DeleteRentalOffer = createAction(
  '[DOCUMENTS] Delete Rental Offer',
  props<{ rentalOfferId: string }>(),
)

//Buying Offer
export const LoadAllBuyingOffers = createAction(
  '[DOCUMENTS] Load all Buying Offers',
);
export const AllBuyingOffersLoaded = createAction(
  '[DOCUMENTS] All Buying Offers Loaded',
  props<{ payload: BuyingOffer[] }>(),
);
export const AllBuyingOffersLoadingFailed = createAction('[DOCUMENTS] All Buying Offers Loading Failed');
export const DeleteBuyingOffer = createAction(
  '[DOCUMENTS] Delete Buying Offer',
  props<{ buyingOfferId: string }>(),
);

//Handover Protocols
export const LoadAllHandoverProtocols = createAction(
  '[DOCUMENTS] Load all Handover Protocols',
);
export const AllHandoverProtocolsLoaded = createAction(
  '[DOCUMENTS] All Handover Protocols Loaded',
  props<{ payload: HandoverProtocol[] }>(),
)
export const AllHandoverProtocolsLoadingFailed = createAction('[DOCUMENTS] All Handover Protocols Loading Failed');
export const DeleteHandoverProtocol = createAction(
  '[DOCUMENTS] Delete Handover Protocol',
  props<{ hopId: string }>(),
);
