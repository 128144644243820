import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { FormGroup } from '@angular/forms';

import { ContractPartnerApiFacade } from './contract-partner-api.facade';
import { ContractPartnerFormService } from './contract-partner-form.service';
import { ContractPartner, ContractPartnerType, ContractPartnerWithCustomersDetails } from './contract-partner.model';
import { ContractPartnerActions } from './store/action-types';
import { ContractPartnerFeatureState, contractPartnerSelectors } from './store';

@Injectable({ providedIn: 'root' })
export class ContractPartnerDataFacade {
  contractPartners$ = this._store$.select(contractPartnerSelectors.customers);
  contractPartnersLoading$ = this._store$.select(contractPartnerSelectors.customersLoading);
  customerDetails$ = this._store$.select(contractPartnerSelectors.customerDetails);
  customerDetailsLoading$ = this._store$.select(contractPartnerSelectors.customerDetailsLoading);

  constructor(
    private readonly _store$: Store<ContractPartnerFeatureState>,
    private readonly _contractPartnerApiFacade: ContractPartnerApiFacade,
    private readonly _contractPartnerFormService: ContractPartnerFormService
  ) { }

  loadContractPartners(): void {
    this._store$.dispatch(ContractPartnerActions.LoadContractPartners());
  }

  loadContractPartnerDetails(id: string): void {
    this._store$.dispatch(ContractPartnerActions.LoadContractPartnerDetails({ payload: { id } }));
  }

  searchContractPartner$(companyNumber: string): Observable<ContractPartner[]> {
    return this._contractPartnerApiFacade.searchContractPartner$(companyNumber);
  }

  getContractPartnersByType$(type: ContractPartnerType): Observable<ContractPartner[]> {
    return this._contractPartnerApiFacade.getContractPartnersByType$(type);
  }

  getCustomerById$(id: string): Observable<ContractPartnerWithCustomersDetails> {
    return this._contractPartnerApiFacade.getCustomerById$(id);
  }

  saveCustomer$(customerForm: FormGroup): Observable<ContractPartner> {
    customerForm.updateValueAndValidity();
    customerForm.markAllAsTouched();

    if (customerForm.invalid) {
      console.warn('Customer Form Invalid!', customerForm.value);
      return of();
    }

    const customer = this._contractPartnerFormService.prepareCustomerToPersist(customerForm.value);

    return this._contractPartnerApiFacade.createContractPartner$(customer);
  }

  public deleteContractPartner$(contractPartnerId: string): void {
    return this._store$.dispatch(ContractPartnerActions.DeleteContractPartner({ contractPartnerId }))
  }
}
