import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { AvatarModule } from 'src/app/common-components/avatar/avatar.module';
import { selectProfile } from 'src/app/store/profile/profile.selectors';
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'sidebar-profile-block',
  templateUrl: './profile-block.component.html',
  styles: [`
  `],
  standalone: true,
    imports: [
        CommonModule,
        AvatarModule,
        RouterModule,
        TranslateModule,
    ],
})
export class SidebarProfileBlockComponent {
  agentProfile$ = this._store.select(selectProfile);

  constructor(private readonly _store: Store) { }
}
