import { Injectable } from '@angular/core';
import { LOCAL_DOCUMENT_ID_PREFIX, LocalDatabase } from 'src/app/offline/local-database';

@Injectable({ providedIn: 'root' })
export class ViewingPassOfflineService extends LocalDatabase {
  protected storeName = 'viewing-passes';

  constructor() {
    super();
    this.initStorage();
  }

  protected generateLocalId(data: any): string {
    return `${LOCAL_DOCUMENT_ID_PREFIX}${Date.now()}`;
  }
}
