import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { CreditCheck } from "./credit-check.model";
import { AgentCompany } from 'src/app/common-models/agent-company';
import {AgentProfile} from "../../common-models/agent-profile";

@Injectable({ providedIn: 'root' })
export class CreditCheckApiFacade {
  constructor(private readonly apollo: Apollo) { }

  createCreditCheck$(
    data: any,
    agentCompany: AgentCompany,
    agentProfile: AgentProfile,
  ): Observable<CreditCheck> {
    return this.apollo
      .mutate({
        mutation: gql`
        mutation CreateCreditCheck(
          $creditCheck: CreditCheckInput
          $agentCompany: AgentCompany!
          $agentProfile: AgentProfile!
        ) {
          creditCheck(
            creditCheck: $creditCheck
            agentCompany: $agentCompany
            agentProfile: $agentProfile
          ) {
            id
            customerId
            checkId
            checkType
            checkUrl
            rentPrice
          }
        }
      `,
        variables: {
          creditCheck: data,
          agentCompany,
          agentProfile
        },
      })
      .pipe(
        first(),
        map((result: any) => ({ ...result.data.creditCheck }))
      );
  }

  loadCreditChecks$(customerId: string): Observable<CreditCheck[]> {
    return this.apollo.query({
      query: gql`
        query GetCreditChecks($customerId: String!) {
          creditChecks(customerId: $customerId) {
            id
            customerId
            checkType
            checkId
            checkUrl
            assessment
            persona {
              firstName
              lastName
              middleName
              dateOfBirth
              residence {
                country
                street
                houseNumber
                topNumber
                city
                postalCode
              }
              contacts {
                email
                phoneNumber
              }
            }
            bankAccount {
              accountHolder
              iban
              bic
              bankName
              bankCountry
            }
            reportsBaseData {
              name
              createdAt
              updatedAt
            }
            reports {
              name
              status
              report {
                code
                result
                params {
                  threshold
                  incomeType
                  rent
                  dstLevel
                  repaymentPeriod
                  interestRate
                  includeExistingLoans
                }
              }
            }
            metadata {
              check {
                url
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        customerId: customerId
      },
    }).pipe(first(), map((result: any) => result.data.creditChecks))
  }

  loadCreditCheckDetails$(customerId: string, creditCheckId: string): Observable<CreditCheck> {
    return this.apollo.query({
      query: gql`
        query GetCreditCheckById($customerId: String!, $creditCheckId: String!) {
          creditCheck(customerId: $customerId, creditCheckId: $creditCheckId) {
            id
            customerId
            checkType
            checkId
            checkUrl
            assessment
            persona {
              firstName
              lastName
              middleName
              dateOfBirth
              residence {
                country
                street
                houseNumber
                topNumber
                city
                postalCode
              }
              contacts {
                email
                phoneNumber
              }
            }
            bankAccount {
              accountHolder
              iban
              bic
              bankName
              bankCountry
            }
            reportsBaseData {
              name
              createdAt
              updatedAt
            }
            reports {
              name
              status
              report {
                code
                result
                params {
                  threshold
                  incomeType
                  rent
                  dstLevel
                  repaymentPeriod
                  interestRate
                  includeExistingLoans
                }
              }
            }
            metadata {
              check {
                url
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        customerId: customerId,
        creditCheckId: creditCheckId,
      },
    }).pipe(first(), map((result: any) => result.data.creditCheck))
  }

  deleteCreditCheck$(creditCheckId: string): Observable<Boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation DeleteCreditCheck($creditCheckId: String!) {
          deleteCreditCheck(creditCheckId: $creditCheckId)
        }
      `,
      variables: {
        creditCheckId,
      },
    }).pipe(
      map((result: any) => result.data.deleteCreditCheck)
    );
  }

}
