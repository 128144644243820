import { Action, createReducer, on } from '@ngrx/store';
import * as DataActions from './actions';

export interface State {
  preListingStatus: {
    loading: boolean;
  };
}

export const initialState: State = {
  preListingStatus: {
    loading: false,
  },
};

const preListingReducer = createReducer(
  initialState,

  on(DataActions.CreatePreListing, (state) => ({
    ...state,
    preListingStatus: {
      loading: true,
    },
  })),
  on(
    DataActions.CreatePreListingSucceded,
    DataActions.CreatePreListingFailed,
    (state) => ({
      ...state,
      preListingStatus: {
        loading: false,
      },
    })
  )
);

export const reducer = (state: State | undefined, action: Action): State =>
  preListingReducer(state, action);

export const getPreListingLoading = (state: State): boolean =>
  state.preListingStatus.loading;
