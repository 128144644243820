import { createSelector, ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromReducer from './reducer';

export interface HandoverProtocolFeatureState {
  handoverProtocol: fromReducer.State;
}

export const reducers: ActionReducerMap<HandoverProtocolFeatureState> = {
  handoverProtocol: fromReducer.reducer,
};

export const getHopState = createFeatureSelector<fromReducer.State>('handover-protocol');

export const hopSelectors = {
  handoverProtocols: createSelector(getHopState, fromReducer.getHandoverProtocols),
  handoverProtocolsLoading: createSelector(getHopState, fromReducer.getHandoverProtocolsLoading),
  handoverProtocolDetails: createSelector(getHopState, fromReducer.getHandoverProtocolDetails),
  handoverProtocolDetailsLoading: createSelector(getHopState, fromReducer.getHandoverProtocolDetailsLoading),
  savingProcessLoading: createSelector(getHopState, fromReducer.getSavingProcessLoading),
  savingProcessHasError: createSelector(getHopState, fromReducer.getSavingProcessError),
};
