import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ButtonModule } from '../button/button.module';
import { TeamsBannerComponent } from './teams-banner.component';

@NgModule({
  imports: [CommonModule, ButtonModule, RouterModule],
  exports: [TeamsBannerComponent],
  declarations: [TeamsBannerComponent],
})
export class TeamsBannerModule {}
