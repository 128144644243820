import {Component, OnInit} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TranslationService} from "../../i18n/TranslationService";
import {MatCardModule} from "@angular/material/card";
import {TranslationComponentModule} from "../../i18n/TranslationComponentModule";
import {TracingService} from "services/tracing.service";

@Component({
  selector: 'banner-block-component',
  template: `

  <mat-card class="w-full">
    <mat-card-content>
      <p class="font-source">{{'banner.text' | translate}}</p>
      <div class="flex items-center mt-4">
        <a href={{link}} target="_blank"
           class="text-base font-source font-bold py-2 bg-primary text-white rounded-lg px-3 text-center"
           (click)="onButtonClick()"
        >{{ 'banner.buttonText' | translate}}</a>
      </div>
    </mat-card-content>
  </mat-card>

  `,
  styles: [`
    mat-card {
      max-width: 240px;
      background-color: #EEF3F9;
    }
  `],
  standalone: true,
  imports: [CommonModule, MatCardModule, TranslationComponentModule],
})

export class BannerBlockComponent {
  link = 'https://www.youtube.com/playlist?list=PLwA19okU0p8lQ9OZF64u8zuQvJJviSO_l'

  constructor(
    private _tracingService: TracingService,
  ) {
  }

  onButtonClick() {
    this._tracingService.postTracing('bannerSidenavClicked').subscribe();
  }
}
