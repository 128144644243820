import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

// todo: add snackbar categories (info, error, success)

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  showSnackbar(message: string, bgColor: string, autoclose = false): void {
    let additionalParams = {};

    if (autoclose) {
      additionalParams = { duration: 3000 };
    }

    this.snackBar.open(message, 'X', {
      ...additionalParams,
      panelClass: ['mat-toolbar', bgColor],
    });
  }
}
