import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, mergeMap, catchError, map, tap, filter, first } from 'rxjs/operators';
import { EMPTY, throwError } from 'rxjs';

import { getAgentCompany } from 'src/app/common-models/mapping';
import { SnackbarService } from 'services/snackbar.service';
import { AuthService } from 'services/auth.service';
import * as DataActions from './actions';
import { ViewingPassApiFacade } from '../viewing-pass-api.facade';
import { ViewingPassDataFacade } from '../viewing-pass-data.facade';
import { DocumentsDataFacade } from "../../documents";
import { TranslationService } from "../../../i18n/TranslationService";
import { NetworkStatusService } from "services/networking-status.service";


@Injectable()
export class ViewingPassEffects {
  public loadViewingPasses$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadViewingPasses),
      switchMap(({ objectId }) =>
        this._viewingPassApiFacade.loadViewingPasses$(objectId).pipe(
          switchMap((value) => [DataActions.ViewingPassesLoaded({ payload: value })]),
          catchError(() => [DataActions.ViewingPassesLoadingFailed()])
        )
      )
    )
  );

  public loadViewingPassesByCustomer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadViewingPassesByCustomer),
      switchMap(({ customerId }) =>
        this._viewingPassApiFacade.loadViewingPassesByCustomer$(customerId).pipe(
          switchMap((value) => [DataActions.ViewingPassesLoaded({ payload: value })]),
          catchError(() => [DataActions.ViewingPassesLoadingFailed()])
        )
      )
    )
  );

  public loadViewingPassDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadViewingPassDetails),
      switchMap(({ objectId, viewingPassId }) =>
        this._viewingPassApiFacade.loadViewingPassDetails$(objectId, viewingPassId).pipe(
          switchMap((value) => [DataActions.ViewingPassDetailsLoaded({ payload: value })]),
          catchError(() => [DataActions.ViewingPassDetailsLoadingFailed()])
        )
      )
    )
  );

  public saveViewingPass$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveViewingPass),
      switchMap(({ viewingPass, isFinal, shouldPrint }) =>
        this._networkStatusService.isOnline$.pipe(
          first(),
          filter(Boolean),
          switchMap(() => this._viewingPassApiFacade.saveViewingPass$(viewingPass).pipe(
            switchMap((pass) => {
              if (isFinal) {
                if (shouldPrint) {
                  this._viewingPassDataFacade.showViewingPassDocument(pass.objectId, pass.id);
                }
                this._router.navigateByUrl(`/objects/${pass.objectId}/viewing-passes`);
                return [DataActions.SaveViewingPassSucceded({ showToast: true })];
              }
              return [DataActions.SaveViewingPassSucceded({ showToast: false })];
            }),
            catchError(() => {
              return [DataActions.SaveViewingPassFailed({ showToast: isFinal ?? true })];
            })
          )),
        )
      ),
    )
  );

  public createAndUploadHandoverProtocolDocumentFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreateAndUploadViewingPassDocumentFailed),
      map(() => {
        const errorMessage = this._translationService.instant('general.document') + ' ' + this._translationService.instant('general.pdf_creation_failure');
        return this._snackbarService.showSnackbar(
          errorMessage,
          'mat-warn',
          true
        )
      }
      )
    ),
    { dispatch: false }
  );

  public viewingPassCreationSucceded$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveViewingPassSucceded),
      tap(({ showToast }) => {
        if (showToast) {
          const successMessage = this._translationService.instant('general.document') + ' ' + this._translationService.instant('general.saved_successfully');
          this._snackbarService.showSnackbar(
            successMessage,
            'mat-primary',
            true
          );
        }
      })
    ),
    { dispatch: false }
  );

  public viewingPassCreationFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveViewingPassFailed),
      tap(({ showToast }) => {
        if (showToast) {
          const errorMessage = this._translationService.instant('general.creation_error') + ' ' + this._translationService.instant('general.document');
          this._snackbarService.showSnackbar(
            errorMessage,
            'mat-warn',
            true
          );
        } else {
          this._snackbarService.showSnackbar(
            `${this._translationService.instant('viewing_pass')} konnte nicht gespeichert werden`,
            'mat-warn',
            true
          );
        }
      })
    ),
    { dispatch: false }
  );

  public trySignAndCompleteViewingPass$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SignAndCompleteViewingPass),
      mergeMap(({ viewingPassSignature: { objectId, viewingPassId, customersSignatureData } }) =>
        this._viewingPassApiFacade.signAndCompleteViewingPass$(
          objectId,
          viewingPassId,
          customersSignatureData,
          getAgentCompany(this._authService)
        ).pipe(
          tap(() => {
            this._router.navigateByUrl(`/objects/${objectId}/viewing-passes/${viewingPassId}/email`);
          }),
          catchError(() => {
            this._router.navigateByUrl(`/objects/${objectId}/viewing-passes`);

            return EMPTY;
          })
        )),
    ),
    { dispatch: false }
  );

  public sendviewingPassEmail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendViewingPassEmail),
      switchMap(({ viewingPassId, emailData, agentCompany }) =>
        this._viewingPassApiFacade.sendViewingPassEmail$(viewingPassId, emailData, agentCompany).pipe(
          tap((success) => {
            if (success) {
              const successMessage = this._translationService.instant('general.document') + ' ' + this._translationService.instant('general.email_sent_successfully');
              this._snackbarService.showSnackbar(
                successMessage,
                'mat-primary',
                true
              );
            } else {
              throwError(new Error());
            }
          }),
          catchError(() => {
            const errorMessage = this._translationService.instant('general.document') + ' ' + this._translationService.instant('general.email_sent_failure');
            this._snackbarService.showSnackbar(
              errorMessage,
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  deleteViewingPass$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.DeleteViewingPass),
      mergeMap(({ viewingPassId }) =>
        this._viewingPassApiFacade
          .deleteViewingPass$(viewingPassId)
          .pipe(
            switchMap(() => {
              const successMessage = this._translationService.instant('general.document') + ' ' + this._translationService.instant('general.deleted_successfully');
              this._snackbarService.showSnackbar(
                successMessage,
                'mat-primary',
                true
              );
              this._documentDataFacade.deleteViewingPass(viewingPassId);
              return [
                DataActions.DeleteViewingPassSucceded({
                  viewingPassId,
                }),
              ];
            }),
            catchError(() => {
              const errorMessage = this._translationService.instant('general.document') + ' ' + this._translationService.instant('general.deleted_failure');
              this._snackbarService.showSnackbar(
                errorMessage,
                'mat-warn',
                true
              );
              return [DataActions.DeleteViewingPassFailed({ viewingPassId })];
            }),
          )
      ),
    )
  );

  public sendCustomerPortalLink$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendCustomerPortalLink),
      switchMap(({ viewingPassId, emailData }) =>
        this._viewingPassApiFacade.sendCustomerPortalLinkEmail$(
          viewingPassId,
          emailData,
        ).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              'Kundenportal E-Mail erfolgreich gesendet',
              'mat-primary',
              true
            );
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Kundenportal E-Mail konnte nicht gesendet werden',
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _router: Router,
    private readonly _snackbarService: SnackbarService,
    private readonly _viewingPassApiFacade: ViewingPassApiFacade,
    private readonly _authService: AuthService,
    private readonly _viewingPassDataFacade: ViewingPassDataFacade,
    private readonly _documentDataFacade: DocumentsDataFacade,
    private readonly _translationService: TranslationService,
    private readonly _networkStatusService: NetworkStatusService,
  ) { }
}
