import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {RentalContractFeatureState, rentalContractSelectors} from "./store";
import {AuthService} from "services/auth.service";
import { RentalContractActions } from './store/action-types';
import {Observable} from "rxjs";
import {RentalContract} from "./rental-contract.model";
import {RentalContractApiFacade} from "./rental-contract-api.facade";
import {getAgentCompany, getAgentProfile} from "../../common-models/mapping";
import { CustomerWithContact } from "@domains/customer";

@Injectable({ providedIn: 'root'})
export class RentalContractDataFacade {
  rentalContracts$ = this._store$.select(rentalContractSelectors.rentalContracts);
  rentalContractsLoading$ = this._store$.select(rentalContractSelectors.rentalContractDetailsLoading);
  rentalContractDetails$ = this._store$.select(rentalContractSelectors.rentalContractDetails);
  rentalContractDetailsLoading$ = this._store$.select(rentalContractSelectors.rentalContractDetailsLoading);
  constructor(
    private readonly _store$: Store<RentalContractFeatureState>,
    private readonly _authService: AuthService,
    private readonly _rentalContractApiFacade: RentalContractApiFacade,
  ) { }
  public loadRentalContracts(objectId: string): void {
    this._store$.dispatch(RentalContractActions.LoadRentalContracts({ objectId }));
  }

  public loadRentalContractsByCustomer(customerId: string): void {
    this._store$.dispatch(RentalContractActions.LoadRentalContractsByCustomer({ customerId }));
  }

  public loadRentalContractDetails(objectId: string, rentalContractId: string): void {
    this._store$.dispatch(RentalContractActions.LoadRentalContractDetails({ objectId, rentalContractId }));
  }

  public saveRentalContract(rentalContract: any): void {
    this._store$.dispatch(RentalContractActions.SaveRentalContract({ rentalContract }));
  }

  public createRentalContract$(rentalContract: any): Observable<RentalContract> {
    return this._rentalContractApiFacade.saveRentalContract$(rentalContract)
  }

  public getRentalContractDetails$(objectId: string, rentalContractId: string): Observable<RentalContract> {
    return this._rentalContractApiFacade.loadRentalContractDetails$(objectId, rentalContractId);
  }

  public deleteRentalContract(rentalContractId: string, objectId: string): void {
    this._store$.dispatch(RentalContractActions.DeleteRentalContract({ rentalContractId, objectId }))
  }

  public updateRentalContractCustomers(
    contractTenants: CustomerWithContact[],
    contractLandlords: CustomerWithContact[],
    rentalContractId: string,
    objectId: string
  ): void {
    this._store$.dispatch(RentalContractActions.UpdateRentalContractCustomers({
      contractTenants,
      contractLandlords,
      rentalContractId,
      objectId,
    }));
  }

  public orderRentalContract$(objectId: string, rentalContractId: string): void {
    const agentProfile = getAgentProfile(this._authService);
    const agentCompany = getAgentCompany(this._authService);
    this._store$.dispatch(RentalContractActions.OrderRentalContract({
      objectId,
      rentalContractId,
      agentProfile,
      agentCompany,
    })
    );
  }

}
