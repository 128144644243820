import { Component } from '@angular/core';
import {LoadingSpinnerService} from "services/loading-spinner-service";


@Component({
  selector: 'app-spinner',
  template: `
    <div class="spinner-overlay" *ngIf="isLoading$ | async">
      <div class="spinner"></div>
    </div>
  `,
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
  isLoading$ = this.loadingService.loading$;

  constructor(private loadingService: LoadingSpinnerService) {}
}
