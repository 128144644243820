import {ExposeGenerator} from "../expose-generator.model";
import * as DataActions from './actions';
import {Action, createReducer, on} from "@ngrx/store";

export interface State {
  exposees: {
    data: ExposeGenerator[];
    loading: boolean;
  };
}

export const initialState: State = {
  exposees: {
    data: [],
    loading: false,
  },
};

const exposeGeneratorReducer = createReducer(
  initialState,

  on(DataActions.LoadExposees, (state) => ({
    ...state,
    exposees: {
      loading: true,
      data: [],
    }
  })),
  on(DataActions.ExposeesLoaded, (state, { payload }) => ({
    ...state,
    exposees: {
      loading: false,
      data: payload
    }
  })),
  on(DataActions.CreateExposeGenerator, (state) => ({
    ...state,
    exposees: {
      ...state.exposees,
      loading: true,
    }
  })),
  on(DataActions.CreateExposeGeneratorSucceeded, (state, { payload }) => ({
    ...state,
    exposees: {
      data: [...state.exposees.data, payload],
      loading: false,
    },
  })),
);

export const reducer = (state: State | undefined, action: Action): State =>
  exposeGeneratorReducer(state, action);

export const getExposees = (state: State): ExposeGenerator[] => state.exposees.data;
export const getExposeesLoading = (state: State): boolean => state.exposees.loading;


