import { createAction, props } from '@ngrx/store';
import { AgentCompany } from 'src/app/common-models/agent-company';
import {
  HandoverProtocol,
  HandoverProtocolCreation,
  HandoverProtocolSignatureCreation,
} from '../handover-protocol.model';

export const LoadHandoverProtocols = createAction(
  '[HandoverProtocol] Load Handover Protocols',
  props<{ objectId: string }>(),
);
export const LoadHandoverProtocolsByCustomer = createAction(
  '[HandoverProtocol] Load Handover Protocols By Customer',
  props<{ customerId: string }>(),
);
export const HandoverProtocolsLoaded = createAction(
  '[HandoverProtocol] Handover Protocols Loaded',
  props<{ payload: HandoverProtocol[] }>(),
);
export const HandoverProtocolsLoadingFailed = createAction('[HandoverProtocol] Handover Protocols Loading Failed');

export const DeleteHandoverProtocol = createAction('[HandoverProtocol] Delete Handover Protocol', props<{ hopId: string, objectId: string }>());
export const DeleteHandoverProtocolSuccess = createAction('[HandoverProtocol] Delete Handover Protocol Succeeded', props<{ hopId: string }>());
export const DeleteHandoverProtocolFailed = createAction('[HandoverProtocol] Delete Handover Protocol Failed', props<{ hopId: string }>());

export const LoadHandoverProtocolDetails = createAction(
  '[HandoverProtocol] Load Handover Protocol Details',
  props<{ objectId: string; hopId: string }>(),
);
export const HandoverProtocolDetailsLoaded = createAction(
  '[HandoverProtocol] Handover Protocol Details Loaded',
  props<{ payload: HandoverProtocol }>(),
);
export const HandoverProtocolDetailsLoadingFailed = createAction(
  '[HandoverProtocol] Handover Protocol Details Loading Failed'
);

export const SaveHandoverProtocol = createAction(
  '[HandoverProtocol] Save Handover Protocol',
  props<{ handoverProtocol: HandoverProtocolCreation; isFinal?: boolean; shouldPrint?: boolean }>(),
);
export const DuplicateHandoverProtocol = createAction(
  '[HandoverProtocol] Duplicate Handover Protocol',
  props<{ hopId: string; objectId: string }>(),
);
export const DuplicateHandoverProtocolSuccess = createAction(
  '[HandoverProtocol] Duplicate Handover Protocol Succeeded',
);
export const DuplicateHandoverProtocolFailed = createAction(
  '[HandoverProtocol] Duplicate Handover Protocol Failed',
);
export const SaveHandoverProtocolSucceded = createAction(
  '[HandoverProtocol] Succeeded To Create And Save Handover Protocol',
  props<{ showToast: boolean }>(),
);
export const SaveHandoverProtocolFailed = createAction(
  '[HandoverProtocol] Failed To Create And Save Handover Protocol',
  props<{ showToast: boolean }>(),
);

export const CreateAndUploadHandoverProtocolDocumentFailed =
  createAction('[HandoverProtocol] Failed To Create And Upload Handover Protocol Document');

export const SignAndCompleteHandoverProtocol = createAction(
  '[HandoverProtocol] Sign And Complete Handover Protocol',
  props<{ handoverProtocolSignature: HandoverProtocolSignatureCreation }>()
);
export const DraftSignHandoverProtocol = createAction(
  '[HandoverProtocol] Draft Sign Handover Protocol',
  props<{
    handoverProtocolSignature: HandoverProtocolSignatureCreation,
    openEmailDialog: boolean,
  }>()
);

export const SendHandoverProtocolEmail = createAction(
  '[Handover Protocol] Send Handover Protocol Email',
  props<{
    objectId: string;
    handoverProtocolId: string;
    emailData: any;
  }>(),
);
