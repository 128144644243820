import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GraphQLModule } from 'services/graphql.module';
import * as fromReducer from './store/reducer';
import {CreditCheckEffects} from "./store/effects";

@NgModule({
  imports: [
    CommonModule,
    GraphQLModule,
    EffectsModule.forFeature([CreditCheckEffects]),
    StoreModule.forFeature('creditCheck', fromReducer.reducer),
  ],
})
export class CreditCheckDomainModule {}
