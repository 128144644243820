import { Injectable } from '@angular/core';
import { LocalDatabase, LOCAL_DOCUMENT_ID_PREFIX } from 'src/app/offline/local-database';

@Injectable({ providedIn: 'root' })
export class BusinessCustomerLocalDbService extends LocalDatabase {
  protected storeName = 'business-customers';

  constructor() {
    super();
    this.initStorage();
  }

  protected generateLocalId(data: any): string {
    const email = data.contact?.email?.toLowerCase();
    const name = (data.companyNumber || '').trim().toLowerCase();

    let random = '';

    if (!email && !name) {
      random = Date.now().toString();
    }

    return `${LOCAL_DOCUMENT_ID_PREFIX}${email || name}${random}`;
  }
}