import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AvatarSize } from './avatar-size';

@Component({
  selector: 'common-avatar',
  template: `
    <div
      *ngIf="showInitials"
      [ngClass]="{
        'w-16 h-16': size === 'large',
        'w-12 h-12': size === 'mid',
        'w-10 h-10': size === 'small'
      }"
      class="flex justify-center items-center rounded-full bg-primary"
    >
      <span
        [ngClass]="{
          'text-2xl': size === 'large',
          'text-xl': size === 'mid',
          'text-lg': size === 'small'
        }"
        class="text-white font-bold"
        >{{ initials }}</span
      >
    </div>
    <div
      *ngIf="!showInitials"
      [ngClass]="{
        'w-16 h-16': size === 'large',
        'w-12 h-12': size === 'mid',
        'w-10 h-10': size === 'small'
      }"
      class="flex w-16 h-16 items-center"
    >
      <img
        class="w-full h-full rounded-full object-top object-cover"
        [src]="imageSrc"
        alt="Makler Avatar"
      />
    </div>
  `,
  styles: [],
})
export class AvatarComponent implements OnChanges {
  @Input()
  imageSrc: string | undefined;
  @Input()
  namesForInitials: string[] | undefined;
  @Input()
  size: AvatarSize = 'large';

  initials: string | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    this.initials = this.getInitials();
  }

  private getInitials(): string | undefined {
    if (!!this.imageSrc) {
      return undefined;
    }

    if (this.namesForInitials && this.namesForInitials.length >= 1) {
      return this.namesForInitials
        .map((n) => n.substr(0, 1).toUpperCase())
        .join('');
    }

    return 'NN';
  }

  get showInitials(): boolean {
    return !!this.initials && this.initials.length >= 1;
  }
}
