import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import { keycloak } from 'src/keycloak.gcp.staging.config';

@Injectable({
  providedIn: 'root',
})
export class AccountLinkingService {
  constructor(private _authService: AuthService) { }

  generateLink(identityProviderAlias: string, redirectUri: string): string {
    const nonce = uuidv4();
    const hash = this.createHash(nonce, identityProviderAlias);
    return `${keycloak.issuer}/broker/${identityProviderAlias}/link?client_id=${keycloak.clientId
      }&redirect_uri=${encodeURIComponent(redirectUri)}&nonce=${nonce}&hash=${hash}`;
  }

  private createHash(nonce: string, identityProviderAlias: string): string {
    const hashBase = nonce + this._authService.sessionState + keycloak.clientId + identityProviderAlias;
    const nonEncoded = sha256(Utf8.parse(hashBase));
    return this.base64url(nonEncoded);
  }

  private base64url(source: CryptoJS.lib.WordArray): string {
    // Encode in classical base64
    let encodedSource = Base64.stringify(source);

    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');

    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  }

  hmac() {
    const timestamp = "1704298858";
    const token = "1a9552782b6ed85c01f56f83d25b1d73e29acc37c6d420df6dc0cfca32873a6a";
    const resourceType = "unlockProvider"
    const actionId = "urn:onoffice-de-ns:smart:2.5:smartml:action:do"
    const secret = "T2_wizFF7Di7c2QJyv76ZfDo"

    const fields = {
      timestamp: timestamp,
      token: token,
      resourceType: resourceType,
      actionId: actionId
    }

    const message = Object.values(fields).join('');

    const hash = CryptoJS.HmacSHA256(message, secret);

    const base64 = Base64.stringify(hash)

    return base64

  }
}
