import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { SnackbarService } from './snackbar.service';

@Injectable({ providedIn: 'root' })
export class JustimmoService {
  constructor(private apollo: Apollo, private _snackbar: SnackbarService) { }

  checkTokenValidity$(): Observable<boolean> {
    return this.apollo.query({
      query: gql`
        query JustimmoTokenValidity {
          justimmoTokenValidity
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }).pipe(map((response: any) => response.data.justimmoTokenValidity));
  }

  getJustimmoAgentData$(): Observable<string> {
    return this.apollo.query({
      query: gql`
        query JustimmoAgentData {
          justimmoAgentData
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }).pipe(map((response: any) => response.data.justimmoAgentData));
  }

  triggerCustomersImport$(): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation TriggerImportJustimmoCustomers {
          importJustimmoCustomers
        }
      `,
    }).pipe(
      first(),
      map((result: any) => result.data.importJustimmoCustomers),
      tap((isSuccess: boolean) => {
        if (isSuccess) {
          this._snackbar.showSnackbar(
            'Justimmo Kunden erfolgreich importiert',
            'mat-primary',
            true
          );
        } else {
          this._snackbar.showSnackbar(
            'Justimmo Kunden konnte nicht importiert werden',
            'mat-warn',
            true
          );
        }
      }),
    );
  }

  triggerPropertiesImport$(): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation TriggerImportJustimmoProperties {
          importJustimmoProperties
        }
      `,
    }).pipe(
      first(),
      map((result: any) => result.data.importJustimmoProperties),
      tap((isSuccess: boolean) => {
        if (isSuccess) {
          this._snackbar.showSnackbar(
            'Justimmo-Objekte erfolgreich importiert',
            'mat-primary',
            true
          );
        } else {
          this._snackbar.showSnackbar(
            'Justimmo-Objekte konnten nicht importiert werden',
            'mat-warn',
            true
          );
        }
      }),
    );
  }

  postJustimmoImport(propertyid: string) {
    return this.apollo.mutate({
      mutation: gql`
        mutation postJustimmoImport($justimmoPropertyId: String!) {
          postJustimmoImport(justimmoPropertyId: $justimmoPropertyId)
        }
      `,
      variables: {
        justimmoPropertyId: propertyid
      },
      fetchPolicy: 'no-cache'
    }).pipe(map((res: any) => res.data.postJustimmoImport));
  }


}
