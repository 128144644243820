import {Injectable} from "@angular/core";
import {AuthService} from "services/auth.service";
import {Store} from "@ngrx/store";
import {DocumentFeatureState, documentsSelectors} from "./store";
import {DocumentActions} from './store/action-types';

@Injectable({providedIn: 'root'})
export class DocumentsDataFacade {

  constructor(
    private readonly _store$: Store<DocumentFeatureState>,
    private readonly _authService: AuthService,
  ) { }

  allViewingPasses$ = this._store$.select(documentsSelectors.allViewingPasses);
  allViewingPassesLoading$ = this._store$.select(documentsSelectors.allViewingPassesLoading);
  allExclusiveAgreements$ = this._store$.select(documentsSelectors.allExclusiveAgreements);
  allExclusiveAgreementsLoading$ = this._store$.select(documentsSelectors.allExclusiveAgreementsLoading);
  allPlainAgreements$ = this._store$.select(documentsSelectors.allPlainAgreements);
  allPlainAgreementsLoading$ = this._store$.select(documentsSelectors.allPlainAgreementsLoading);
  allRentalOffers$ = this._store$.select(documentsSelectors.allRentalOffers);
  allRentalOffersLoading$ = this._store$.select(documentsSelectors.allRentalOffersLoading);
  allBuyingOffers$ = this._store$.select(documentsSelectors.allBuyingOffers);
  allBuyingOffersLoading$ = this._store$.select(documentsSelectors.allBuyingOffersLoading);
  allHandoverProtocols$ = this._store$.select(documentsSelectors.allHandoverProtocols);
  allHandoverProtocolsLoading$ = this._store$.select(documentsSelectors.allHandoverProtocolsLoading);

  public loadAllViewingPasses(): void {
    this._store$.dispatch(DocumentActions.LoadAllViewingPasses());
  }
  public deleteViewingPass(id: string): void {
    this._store$.dispatch(DocumentActions.DeleteViewingPass({ viewingPassId: id }));
  };

  public loadAllExclusiveAgreements(): void {
    this._store$.dispatch(DocumentActions.LoadAllExclusiveAgreements());
  }
  public deleteExclusiveAgreement(id: string): void {
    this._store$.dispatch(DocumentActions.DeleteExclusiveAgreement({ exclusiveAgreementId: id }));
  }

  public loadAllPlainAgreements(): void {
    this._store$.dispatch(DocumentActions.LoadAllPlainAgreements());
  }
  public deletePlainAgreement(id: string): void {
    this._store$.dispatch(DocumentActions.DeletePlainAgreement({ plainAgreementId: id }));
  }

  public loadAllRentalOffers(): void {
    this._store$.dispatch(DocumentActions.LoadAllRentalOffers());
  }
  public deleteRentalOffer(id: string): void {
    this._store$.dispatch(DocumentActions.DeleteRentalOffer({ rentalOfferId: id }));
  };

  public loadAllBuyingOffers(): void {
    this._store$.dispatch(DocumentActions.LoadAllBuyingOffers());
  }
  public deleteBuyingOffer(id: string): void {
    this._store$.dispatch(DocumentActions.DeleteBuyingOffer({ buyingOfferId: id }));
  };

  public loadAllHandoverProtocols(): void {
    this._store$.dispatch(DocumentActions.LoadAllHandoverProtocols());
  }
  public deleteHandoverProtocol(id: string): void {
    this._store$.dispatch(DocumentActions.DeleteHandoverProtocol({ hopId: id }));
  };

}
