import { SigneeSignatureResult } from "@domains/handover-protocol";

export const mapSignatureDataForGraphQL = (signatureData: SigneeSignatureResult) => {
  const signature: any = {
    type: signatureData.type,
    signatureDate: signatureData.date,
    signatureLocation: signatureData.location,
    signatureAsSvg: signatureData.signatureAsSvg,
    signedViaTAN: signatureData.signedViaTAN,
    present: signatureData.present,
  };

  return signature;
}