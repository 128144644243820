import { createReducer, on } from '@ngrx/store';
import { BusinessCustomerActions } from './action-types';
import { BusinessCustomer, BusinessCustomerWithCustomersDetails } from '../business-customer.model';

export interface State {
  customers: {
    data: BusinessCustomer[];
    loading: boolean;
  };
  customerDetails: {
    data?: BusinessCustomerWithCustomersDetails;
    loading: boolean;
  };
}

export const initialState: State = {
  customers: {
    data: [],
    loading: false,
  },
  customerDetails: {
    data: undefined,
    loading: false,
  },
};

const businessCustomerReducer = createReducer(
  initialState,

  on(BusinessCustomerActions.LoadBusinessCustomers, (state) => ({
    ...state,
    customers: {
      ...state.customers,
      loading: true,
    },
  })),
  on(BusinessCustomerActions.LoadBusinessCustomersSucceeded, (state, { payload }) => ({
    ...state,
    customers: {
      loading: false,
      data: payload,
    },
  })),
  on(BusinessCustomerActions.LoadBusinessCustomersFailed, (state) => ({
    ...state,
    customers: {
      ...state.customers,
      loading: false,
    },
  })),

  on(BusinessCustomerActions.LoadBusinessCustomerDetails, (state) => ({
    ...state,
    customerDetails: {
      data: undefined,
      loading: true,
    },
  })),
  on(BusinessCustomerActions.LoadBusinessCustomerSucceeded, (state, { payload }) => ({
    ...state,
    customerDetails: {
      loading: false,
      data: payload,
    },
  })),
  on(BusinessCustomerActions.LoadBusinessCustomerFailed, (state) => ({
    ...state,
    customerDetails: {
      ...state.customerDetails,
      loading: false,
    },
  })),
);

export const reducer = (state: State | undefined, action: any) =>
  businessCustomerReducer(state, action);

export const getBusinessCustomers = (state: State) => state.customers.data;
export const getBusinessCustomersLoading = (state: State) => state.customers.loading;

export const getBusinessCustomerDetails = (state: State) => state.customerDetails.data;
export const getBusinessCustomerDetailsLoading = (state: State) => state.customerDetails.loading;

