import { Environment } from './environment-config';

export const environment: Environment = {
  production: true,
  enablePWA: true,
  backendBaseUrl: 'https://plattform.staging.propup.at/api/graphql',
  documentServiceBaseUrl: 'https://api.staging.propup.at/object-service/',
  customerServiceBaseUrl: 'https://api.staging.propup.at/customer-service/',
  propnowRemaxUrl: 'https://remax-at.bidkast.net/dashboard/search?idp=propup&property=',
  propnowUrl: 'https://app.platform.bidkast.net/dashboard/search?idp=propup&property=',
  SENTRY_ENVIRONMENT: 'staging',
  customerPortalUrl: 'https://app.staging.propup.at/',
  staticToggles: {
    enableLoggingTracing: false,
    showSimplifiedAddressPage: true,
    hopShowDummyDocumentGenerationButton: false,
    allowDevPlayground: true,
    showImageManagement: true,
    showMarketplacePage: false,
    showNewHopScreen: true,
    showValuationLight: true,
    showValuationFull: false,
    showLocationScore: true,
    showPOIs: true,
    showDossier: false,
    showAccessibility: true,
    showSimplifiedFAGG: false,
    showAdditionalEmails: true,
    showContaminatedSites: true,
    showHORAPass: true,
    showMaps: true,
    enableTeams: true,
    showBanner: true,
    showDownloadDocuments: true,
    showRentalContracts: true,
    showBusinessCustomer: true,
    showCustomerPortal: true,
    showCreditCheck: false,
    showPublicCustomerPortalLink: true,
    showCustomerPortalBuyingOffer: true,
    showExposeGenerator: true,
    showSendCustomerLinkPortal: true,
    showCustomerPortalQuestionnaire: true,
    showCustomerPortalFeedback: true,
    showOnOffice: true,
    showObjectImages: true,
    showPropertiesDuplicationCheck: true,
    showOfficeArea: false,
    showExtendedObjectCreation: true,
    showVPAddDocs: true,
    showPlainAgreement: true,
    showPropertySharing: true,
    showPropertyActivities: true,
    showMicrosoft: true,
    showCustomerPortalOwnerSigning: true,
    showPlatformExport: true,
    showBuyingContracts: true,
    showPROPUPValuation: false,
    showViewingPassCustomerPortal: false,
    showUpdateOnOfficeProperty: true,
    showPropertySettings: true,
  },
};
