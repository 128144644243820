import * as DataActions from './actions';
import { Action, createReducer, on } from '@ngrx/store';
import { OwnerPortal } from '../owner-portal.model';

export interface State {
  ownerPortals: {
    data: OwnerPortal[];
    loading: boolean;
  };
  ownerPortalDetails: {
    data: OwnerPortal | null;
    loading: boolean;
  };
  savingProcess: {
    loading: boolean;
    error: boolean;
  };
}

export const initialState: State = {
  ownerPortals: {
    data: [],
    loading: false,
  },
  ownerPortalDetails: {
    data: null,
    loading: false,
  },
  savingProcess: {
    loading: false,
    error: false,
  }
};

const ownerPortalReducer = createReducer(
  initialState,

  on(DataActions.LoadOwnerPortals, (state) => ({
    ...state,
    ownerPortals: {
      loading: true,
      data: [],
    },
  })),
  on(DataActions.OwnerPortalsLoaded, (state, { payload }) => ({
    ...state,
    ownerPortals: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.OwnerPortalsLoadingFailed, (state) => ({
    ...state,
    ownerPortals: {
      ...state.ownerPortals,
      loading: false,
    },
  })),

  on(DataActions.LoadOwnerPortalDetails, (state) => ({
    ...state,
    ownerPortalDetails: {
      loading: true,
      data: null,
    },
  })),
  on(DataActions.OwnerPortalDetailsLoaded, (state, { payload }) => ({
    ...state,
    ownerPortalDetails: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.OwnerPortalDetailsLoadingFailed, (state) => ({
    ...state,
    ownerPortalDetails: {
      ...state.ownerPortalDetails,
      loading: false,
    },
  })),

  on(DataActions.SaveOwnerPortal, (state) => ({
    ...state,
    savingProcess: {
      loading: true,
      error: false,
    },
  })),
  on(DataActions.SaveOwnerPortalSucceded, (state) => ({
    ...state,
    savingProcess: {
      loading: false,
      error: false,
    },
  })),
  on(DataActions.SaveOwnerPortalFailed, (state) => ({
    ...state,
    savingProcess: {
      loading: false,
      error: true,
    },
  })),

  on(DataActions.DeleteOwnerPortal, (state) => ({
    ...state,
    ownerPortals: {
      ...state.ownerPortals,
      loading: true,
    },
  })),
  on(DataActions.DeleteOwnerPortalSucceded, (state, { id }) => ({
    ...state,
    ownerPortals: {
      data: state.ownerPortals.data.filter((v: OwnerPortal) => v.id !== id),
      loading: false,
    },
  })),
  on(DataActions.DeleteOwnerPortalFailed, (state) => ({
    ...state,
    ownerPortals: {
      ...state.ownerPortals,
      loading: false,
    },
  })),
);

export const reducer = (state: State | undefined, action: Action): State => ownerPortalReducer(state, action);

export const getOwnerPortals = (state: State): OwnerPortal[] => state.ownerPortals.data;
export const getOwnerPortalsLoading = (state: State): boolean => state.ownerPortals.loading;
export const getOwnerPortalDetails = (state: State): OwnerPortal | null => state.ownerPortalDetails.data;
export const getOwnerPortalDetailsLoading = (state: State): boolean => state.ownerPortalDetails.loading;
export const getSavingProcessLoading = (state: State): boolean => state.savingProcess.loading;
export const getSavingProcessError = (state: State): boolean => state.savingProcess.error;
