import { createSelector, ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromReducer from './reducer';
import {getIsOfflineSave} from "./reducer";

export interface ViewingPassFeatureState {
  viewingPass: fromReducer.State;
}

export const reducers: ActionReducerMap<ViewingPassFeatureState> = {
  viewingPass: fromReducer.reducer,
};

export const getViewingPassState = createFeatureSelector<fromReducer.State>('viewing-pass');

export const viewingPassSelectors = {
  viewingPasses: createSelector(getViewingPassState, fromReducer.getViewingPasses),
  viewingPassesLoading: createSelector(getViewingPassState, fromReducer.getViewingPassesLoading),
  viewingPassDetails: createSelector(getViewingPassState, fromReducer.getViewingPassDetails),
  viewingPassDetailsLoading: createSelector(getViewingPassState, fromReducer.getViewingPassDetailsLoading),
  savingProcessLoading: createSelector(getViewingPassState, fromReducer.getSavingProcessLoading),
  savingProcessHasError: createSelector(getViewingPassState, fromReducer.getSavingProcessError),
  getOfflineViewingPasses: createSelector(getViewingPassState, fromReducer.getOfflineViewingPasses),
  getOfflineViewingPassesSyncing: createSelector(getViewingPassState, fromReducer.getOfflineViewingPassesSyncing),
  getOfflineViewingPassesSyncError: createSelector(getViewingPassState, fromReducer.getOfflineViewingPassesSyncError),
  getIsOfflineSave: createSelector(getViewingPassState, fromReducer.getIsOfflineSave),
};
