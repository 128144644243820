import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Apollo, gql} from "apollo-angular";

@Injectable({providedIn: 'root'})
export class TracingService {

  constructor(
    private apollo: Apollo,
  ) {
  }

  postTracing(feature: string): Observable<any> {
    return this.apollo.mutate({
      mutation: gql`
        mutation postTracing($feature: String!) {
          postTracing(feature: $feature)
        }
      `,
      variables: {
        feature
      },
      fetchPolicy: 'no-cache',
    })
  }

}
