<div
  class="pt-12 lg:pt-20 pb-12 lg:pb-20 px-11 lg:px-16 space-y-10"
>
  <div>
    <common-heading
      label="Verbindung wiederherstellen"
      align="center"
      class="font-bold text-lg pb-4"
    >
    </common-heading>
    <p class="text-md font-normal text-center text-gray-400 pb-4">
      Ihre Verbindung zu {{ data.name }} wurde aus Sicherheitsgründen unterbrochen. Bitte klicken Sie auf den Button, um die Verbindung wiederherzustellen.
    </p>

    <div class="space-y-6 flex flex-column justify-center">
      <div class="space-y-2">
        <div>
          <button
            (click)="restoreClicked(data.link)"
            class="w-64 lg:w-72 h-11 font-source rounded-xl text-lg text-white font-bold px-7 py-2 bg-primary disabled:bg-gray-300"
          >
            Erneut verbinden
          </button>
        </div>
        <div>
          <button
            (click)="abortClicked()"
            class="w-64 lg:w-72 h-11 font-source rounded-xl text-lg text-white font-bold px-7 py-2 bg-secondary-dark disabled:bg-gray-300"
          >
            Später
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
