import { createAction, props } from '@ngrx/store';
import { ContractPartner, ContractPartnerWithCustomersDetails } from '../contract-partner.model';

export const LoadContractPartners = createAction('[ContractPartner] Load Contract Partners');
export const LoadContractPartnersFailed = createAction('[ContractPartner] Failed to load Contract Partners');
export const LoadContractPartnersSucceeded = createAction(
  '[ContractPartner] Succeeded to load Contract Partners',
  props<{ payload: ContractPartner[] }>()
);

export const LoadContractPartnerDetails = createAction(
  '[ContractPartner] Load Contract Partner',
  props<{ payload: { id: string } }>()
);
export const LoadContractPartnerFailed = createAction('[ContractPartner] Failed to load Contract Partner');
export const LoadContractPartnerSucceeded = createAction(
  '[ContractPartner] Succeeded to load Contract Partner',
  props<{ payload: ContractPartnerWithCustomersDetails }>()
);

export const DeleteContractPartner = createAction('[Customer] Delete Contract Partner', props<{ contractPartnerId: string }>());
export const DeleteContractPartnerSuccess = createAction('[Customer] Delete Contract Partner Succeeded', props<{ contractPartnerId: string }>());
export const DeleteContractPartnerFailed = createAction('[Customer] Delete Contract Partner Failed', props<{ contractPartnerId: string }>());
