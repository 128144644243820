import {Injectable} from '@angular/core';
import {Store} from "@ngrx/store";
import {AuthService} from "services/auth.service";
import {RoleProviderService} from "services/role-provider.service";
import {selectProfile} from "../store/profile/profile.selectors";
import {map, take, tap} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class AccessService {
  newRegistrationDays: number = 0;
  disablePropupAccess: boolean = false;

  registrationDays$ = this._store.select(selectProfile).pipe(
    tap((p) => {
      const createdDate = p.createdTimeStamp
      const currentDate = Date.now()
      const diff = (currentDate - createdDate)
      const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
      this.newRegistrationDays = diffDays
    }),
  );

  hasFreeVersion$: Observable<boolean> = this.registrationDays$.pipe(
    map((p) => {
      const isRemax = this._authService.isRemax
      const roles = this._roleProviders.getRoles()
      if (this.newRegistrationDays <= 14) {
        return false
      } else if (isRemax) {
        return !(roles.includes('propup-paid-user') || roles.includes('propup-test-user') || roles.includes('propup-internal-user'));
      }
      return false
    })
  );

  hasPropupLicence$: Observable<boolean> = this.registrationDays$.pipe(
    map((p) => {
      const roles = this._roleProviders.getRoles()
      if (this.newRegistrationDays <= 14) {
        return this.disablePropupAccess = false
      } else {
        if (roles.includes('propup-paid-user') || roles.includes('propup-test-user') || roles.includes('propup-internal-user')) {
          return this.disablePropupAccess = false
        }
        return this.disablePropupAccess = true
      }
    })
  );

  constructor(
    private readonly _roleProviders: RoleProviderService,
    private readonly _authService: AuthService,
    private readonly _store: Store,
  ) { }
}
