import { CustomerWithContact } from "@domains/customer";
import { Address } from "src/app/common-models/address";

export enum ContractPartnerType {
  Financing = 'financing',
  Notary = 'notary',
  Lawyer = 'lawyer',
  Insurance = 'insurance',
  ElectricityGasSupplier = 'electricity-gas-supplier',
  PropertyManagement = 'property-management',
}

export interface ContractPartner {
  id?: string;
  userId?: string;
  type: ContractPartnerType;
  companyNumber: string;
  companyRegister: string;
  contact: {
    address: Address;
    phone: string;
    email: string;
  };
  customers: Array<string>;
}

export interface ContractPartnerWithCustomersDetails extends Omit<ContractPartner, 'customers'> {
  customers: Array<CustomerWithContact>;
}
