import { createSelector, ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromReducer from './reducer';

export interface PlainAgreementFeatureState {
  plainAgreement: fromReducer.State;
}

export const reducers: ActionReducerMap<PlainAgreementFeatureState> = {
  plainAgreement: fromReducer.reducer,
};

export const getPlainAgreementState = createFeatureSelector<fromReducer.State>('plain-agreement');

export const PlainAgreementSelectors = {
  plainAgreements: createSelector(getPlainAgreementState, fromReducer.getPlainAgreements),
  plainAgreementsLoading: createSelector(getPlainAgreementState, fromReducer.getPlainAgreementsLoading),
  plainAgreementDetails: createSelector(getPlainAgreementState, fromReducer.getPlainAgreementDetails),
  plainAgreementDetailsLoading: createSelector(getPlainAgreementState, fromReducer.getPlainAgreementDetailsLoading),
};
