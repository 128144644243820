import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../button/button.module';
import { BannerComponent } from './banner.component';
import {TranslationComponentModule} from "../../i18n/TranslationComponentModule";

@NgModule({
  imports: [CommonModule, ButtonModule, TranslationComponentModule],
  exports: [BannerComponent],
  declarations: [BannerComponent],
})
export class BannerModule { }
