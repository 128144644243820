import {Injectable, OnDestroy} from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class NewVersionCheckerService implements OnDestroy {
  isNewVersionAvailable: boolean = false;
  newVersionSubscription: Subscription | undefined;
  routerSubscription: Subscription | undefined;

  constructor(
    private swUpdate: SwUpdate,
    private router: Router
  ) {
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.checkForUpdate();
      });
  }
  checkForUpdate(): void {
    console.log('Checking for new app version...');
    this.newVersionSubscription?.unsubscribe();
    if (!this.swUpdate.isEnabled) {
      return;
    }
    this.newVersionSubscription = this.swUpdate.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.isNewVersionAvailable = true;
          window.location.reload();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.newVersionSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }
}
