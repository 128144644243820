import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { TeamModel } from './team.model';
import { map } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client';

@Injectable({
  providedIn: 'root',
})
export class TeamApiFacade {
  constructor(private readonly apollo: Apollo) {}

  getTeams$(): Observable<TeamModel[]> {
    return this.apollo
      .query({
        query: gql`
          query GetTeams {
            teams {
              id
              name
              members {
                userId
                isCreator
                roles
                email
                familyName
                givenName
              }
              invitations {
                email
                status
                date
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
      })
      .pipe(map((result: ApolloQueryResult<any>) => result.data.teams));
  }

  getTeamsInvitations$(): Observable<TeamModel[]> {
    return this.apollo
      .query({
        query: gql`
          query GetTeamsInvitations {
            teamsInvitations {
              id
              name
              members {
                userId
                isCreator
                roles
                email
                familyName
                givenName
              }
              invitations {
                email
                status
                date
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result: ApolloQueryResult<any>) => result.data.teamsInvitations)
      );
  }

  addTeamMember$(teamId: string, email: string): Observable<TeamModel> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation AddTeamMember($teamId: String!, $email: String!) {
            addTeamMember(teamId: $teamId, email: $email) {
              id
              name
              members {
                userId
                isCreator
                roles
                email
                familyName
                givenName
              }
              objects
              customers
              invitations {
                email
                status
                date
              }
            }
          }
        `,
        variables: {
          teamId: teamId,
          email: email,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((result: any) => result.data.addTeamMember));
  }

  acceptInvitation$(teamId: string, email: string): Observable<TeamModel> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation AcceptInvitation($teamId: String!, $email: String!) {
            acceptInvitation(teamId: $teamId, email: $email) {
              id
              name
              members {
                userId
                isCreator
                roles
                email
                familyName
                givenName
              }
              objects
              customers
              invitations {
                email
                status
                date
              }
            }
          }
        `,
        variables: {
          teamId: teamId,
          email: email,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((result: any) => result.data.acceptInvitation));
  }

  declineInvitation$(teamId: string, email: string): Observable<TeamModel> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation DeclineInvitation($teamId: String!, $email: String!) {
            declineInvitation(teamId: $teamId, email: $email) {
              id
              name
              members {
                userId
                isCreator
                roles
                email
                familyName
                givenName
              }
              objects
              customers
              invitations {
                email
                status
                date
              }
            }
          }
        `,
        variables: {
          teamId: teamId,
          email: email,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((result: any) => result.data.declineInvitation));
  }

  inviteTeamMember$(teamId: string, email: string): Observable<TeamModel> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation InviteTeamMember($teamId: String!, $email: String!) {
            inviteTeamMember(teamId: $teamId, email: $email) {
              id
              name
              members {
                userId
                isCreator
                roles
                email
                familyName
                givenName
              }
              objects
              customers
              invitations {
                email
                status
                date
              }
            }
          }
        `,
        variables: {
          teamId: teamId,
          email: email,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((result: any) => result.data.inviteTeamMember));
  }

  deleteInvitation$(teamId: string, email: string): Observable<TeamModel> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation DeleteInvitation($teamId: String!, $email: String!) {
            deleteInvitation(teamId: $teamId, email: $email) {
              id
              name
              members {
                userId
                isCreator
                roles
                email
                familyName
                givenName
              }
              objects
              customers
              invitations {
                email
                status
                date
              }
            }
          }
        `,
        variables: {
          teamId: teamId,
          email: email,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((result: any) => result.data.deleteInvitation));
  }

  resendInvitation$(teamId: string, email: string): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation ResendInvitation($teamId: String!, $email: String!) {
            resendInvitation(teamId: $teamId, email: $email)
          }
        `,
        variables: {
          teamId: teamId,
          email: email,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((result: any) => result.data.resendInvitation));
  }

  removeTeamMember$(teamId: string, userId: string): Observable<TeamModel> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation RemoveTeamMember($teamId: String!, $userId: String!) {
            removeTeamMember(teamId: $teamId, userId: $userId) {
              id
              name
              members {
                userId
                isCreator
                roles
                email
                familyName
                givenName
              }
              invitations {
                email
                status
                date
              }
            }
          }
        `,
        variables: {
          teamId: teamId,
          userId: userId,
        },
        fetchPolicy: 'no-cache',
      })
      .pipe(map((result: any) => result.data.removeTeamMember));
  }
}
