import { CustomerPortalEmailVariables } from "./customer-portal.model";

const PROPERTY_ADDRESS = '{PROPERTY_ADDRESS}';
const DOCUMENT_URL = '{DOCUMENT_URL}';
const SIGNATURE = '{SIGNATURE}';
const CUSTOMER_PORTAL_LINK = '{CUSTOMER_PORTAL_LINK}';

const mergeVariables = (template: string, vars: CustomerPortalEmailVariables) => {
  return template
    .replaceAll(PROPERTY_ADDRESS, vars.propertyAddress)
    .replaceAll(SIGNATURE, vars.signature)
    .replaceAll(DOCUMENT_URL, vars.documentUrl)
    .replaceAll(CUSTOMER_PORTAL_LINK, vars.customerPortalLink);
}

export const buildTemplate = (title: string, body: string, vars: CustomerPortalEmailVariables) => {
  const mail = {
    subject: mergeVariables(title, vars),
    body: mergeVariables(body, vars),
  };

  return mail;
}

export const CustomerPortalEmail = {
  buildTemplate,
  mergeVariables,
};