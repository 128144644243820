import { Action, createReducer, on } from '@ngrx/store';
import { RentalOffer } from '../rental-offer.model';
import * as DataActions from './actions';

export interface State {
  rentalOffers: {
    data: RentalOffer[];
    loading: boolean;
  };
  rentalOfferDetails: {
    data: RentalOffer | null;
    loading: boolean;
  };
}

export const initialState: State = {
  rentalOffers: {
    data: [],
    loading: false,
  },
  rentalOfferDetails: {
    data: null,
    loading: false,
  },
};

const digitalOfferingReducer = createReducer(
  initialState,

  on(DataActions.LoadRentalOffers, DataActions.LoadRentalOffersByCustomer, (state) => ({
    ...state,
    rentalOffers: {
      loading: true,
      data: [],
    },
  })),
  on(DataActions.RentalOffersLoaded, (state, { payload }) => ({
    ...state,
    rentalOffers: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.RentalOffersLoadingFailed, (state) => ({
    ...state,
    rentalOffers: {
      ...state.rentalOffers,
      loading: false,
    },
  })),

  on(DataActions.LoadRentalOfferDetails, (state) => ({
    ...state,
    rentalOfferDetails: {
      loading: true,
      data: null,
    },
  })),
  on(DataActions.RentalOfferDetailsLoaded, (state, { payload }) => ({
    ...state,
    rentalOfferDetails: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.RentalOfferDetailsLoadingFailed, (state) => ({
    ...state,
    rentalOfferDetails: {
      ...state.rentalOfferDetails,
      loading: false,
    },
  })),
  on(DataActions.DeleteRentalOfferSuccess, (state, { rentalOfferId }) => ({
    ...state,
    rentalOffers: {
      ...state.rentalOffers,
      data: state.rentalOffers.data.filter((rentalOffer) => rentalOffer.id !== rentalOfferId),
    },
  })),
);

export const reducer = (state: State | undefined, action: Action): State => digitalOfferingReducer(state, action);

export const getRentalOffers = (state: State): RentalOffer[] => state.rentalOffers.data;
export const getRentalOffersLoading = (state: State): boolean => state.rentalOffers.loading;
export const getRentalOfferDetails = (state: State): RentalOffer | null => state.rentalOfferDetails.data;
export const getRentalOfferDetailsLoading = (state: State): boolean => state.rentalOfferDetails.loading;
