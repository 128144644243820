import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';

import { IntegrationStatusesComponent } from './integration-statuses.component';
import { StatusIndicatorComponent } from './indicator/indicator.component';
import { IntegrationWrapperModule } from 'src/app/common-components/integration-wrapper/integration-wrapper.module';

@NgModule({
  declarations: [
    IntegrationStatusesComponent,
    StatusIndicatorComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule,
    IntegrationWrapperModule,
  ],
  exports: [IntegrationStatusesComponent],
})
export class IntegrationStatusesModule { }
