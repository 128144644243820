import {
  createSelector,
  ActionReducerMap,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromReducer from './reducer';

export interface CreditCheckFeatureState {
  creditCheck: fromReducer.State;
}

export const reducers: ActionReducerMap<CreditCheckFeatureState> = {
  creditCheck: fromReducer.reducer,
};

export const getCreditCheckState =
  createFeatureSelector<fromReducer.State>('creditCheck');

export const creditCheckSelectors = {
  creditCheckDetails: createSelector(getCreditCheckState, fromReducer.getCreditCheckDetails),
  creditChecksDetailsLoading: createSelector(getCreditCheckState, fromReducer.getCreditCheckDetailsLoading),
  creditChecks: createSelector(getCreditCheckState, fromReducer.getCreditChecks),
  creditChecksLoading: createSelector(getCreditCheckState, fromReducer.getCreditChecksLoading),
};
