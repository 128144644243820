import {createAction, props} from "@ngrx/store";
import {RentalContract} from "../rental-contract.model";
import {AgentProfile} from "../../../common-models/agent-profile";
import {AgentCompany} from "../../../common-models/agent-company";
import { CustomerWithContact } from "@domains/customer";

export const LoadRentalContracts = createAction('[RentalContract] Load Rental Contracts', props<{ objectId: string }>());
export const LoadRentalContractsByCustomer = createAction('[RentalContract] Load Rental Contract By Customer', props<{ customerId: string }>());
export const RentalContractsLoaded = createAction('[RentalContract] Rental Contracts Loaded', props<{ payload: RentalContract[] }>());
export const RentalContractsLoadingFailed = createAction('[RentalContract] Rental Contract Loading Failed');
export const LoadRentalContractDetails = createAction('[RentalContract] Load Rental Contract Details', props<{
  objectId: string;
  rentalContractId: string;
}>());
export const RentalContractDetailsLoaded = createAction('[RentalContract] Rental Contract Details Loaded', props<{ payload: RentalContract }>());
export const RentalContractDetailsLoadingFailed = createAction('[RentalContract] Rental Contract Details Loading Failed');
export const SaveRentalContract = createAction('[RentalContract] Save Rental Contract', props<{ rentalContract: RentalContract, isFinal?: boolean }>());
export const CreateRentalContractSucceded = createAction('[RentalContract] Succeeded to create and save Rental Contract');
export const DeleteRentalContract = createAction('[RentalContract] Delete Rental Contract', props<{ rentalContractId: string, objectId: string }>());
export const DeleteRentalContractSuccess = createAction('[RentalContract] Delete Rental Offer Succeeded', props<{ rentalContractId: string }>());
export const DeleteRentalContractFailed = createAction('[RentalContract] Delete Rental Contract Failed', props<{ rentalContractId: string }>());
export const UpdateRentalContractCustomers = createAction(
  '[RentalContract] Update Rental Contract Customers',
  props<{
    contractTenants: CustomerWithContact[];
    contractLandlords: CustomerWithContact[];
    rentalContractId: string,
    objectId: string,
  }>()
);
export const OrderRentalContract = createAction(
  '[RentalContract] Rental Contract ordered',
  props<{
    objectId: string;
    rentalContractId: string;
    agentProfile: AgentProfile;
    agentCompany: AgentCompany;
  }>()
);
