import { S } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BusinessCustomerFormService } from '@domains/business-customer';
import { CustomerFormService, CustomerWithContact } from '@domains/customer';
import { startDateValidator } from '@utils/validators';
import { CustomerType } from 'src/app/common-components/customer-type-selector/model';
import { HandoverProtocol } from 'src/app/domains/handover-protocol/handover-protocol.model';
import { RoleProviderService } from "services/role-provider.service";

@Injectable({ providedIn: 'root' })
export class HandoverProtocolFormService {
  constructor(
    private readonly _customerFormService: CustomerFormService,
    private readonly _businessCustomerFormService: BusinessCustomerFormService,
    private readonly _fb: FormBuilder,
    private readonly _roleProviderService: RoleProviderService
  ) { }

  public createDocumentsArrayItemForm(): FormGroup {
    return this._fb.group({
      id: '',
      description: '',
      type: '',
      storageUrl: '',
      title: '',
      documentFrom: new Date().toISOString().substring(0, 10),
      state: ''
    });
  }

  hasCustomerPortalEnabled(): boolean {
    return !this._roleProviderService.hasRole('propup-special-offer')
  }

  public createCustomersArrayItemForm(): FormGroup {
    return this._customerFormService.createCustomerFormGroup(true);
  }

  public convertCustomerToFormGroup(c: CustomerWithContact): FormGroup {
    return this._customerFormService.convertToFormGroup(c);
  }

  public createBusinessCustomersArrayItemForm(): FormGroup {
    return this._businessCustomerFormService.createPrivateCustomer();
  }

  public createImagesArrayItemForm(): FormGroup {
    return this._fb.group({
      id: '',
      description: '',
      type: '',
      storageUrl: ['', Validators.required],
    });
  }

  public createMetersArrayItemForm(): FormGroup {
    return this._fb.group({
      type: '',
      number: '',
      reading: '',
      readingDate: [new Date().toISOString().substring(0, 10)],
      notes: '',
      images: this._fb.array([]),
    });
  }

  public createPropertyInformationForm(objectData?: any): FormGroup {
    return this._fb.group({
      areaInformation: '',
      typeInformation: '',
      rentalUnit: objectData?.rentalSpecifications?.rentalUnit ?? '',
      rentalUnitFloor: objectData?.rentalSpecifications?.rentalUnitFloor ?? '',
      rentalNumberTransferor: '',
      rentalNumberTransferee: objectData?.rentalSpecifications?.rentalNumberTransferee ?? '',
      rentalDate: null,
      moveOutDate: null,
    });
  }

  public createWallWorksForm(): FormGroup {
    return this._fb.group({
      roomNotes: '',
    });
  }

  public createCleaningForm(): FormGroup {
    return this._fb.group({
      specialDirt: false,
      bathDescaling: false,
      jointing: false,
      showerCabin: false,
      showerSealing: false,
      siliconeJoint: false,
      floorCleaning: false,
      balconyCleaning: false,
    });
  }

  public createEmailsArrayItemControl(email?: string): FormControl {
    return this._fb.control(email ?? '');
  }

  public createHandoverRoomsForm(): FormGroup {
    return this._fb.group({
      paintedSurface: false,
      ingrainWallpaper: false,
      individualRoomHandover: false,
      livingRoom: this.createHandoverRoomDetailsForm(0),
      kitchen: this.createHandoverRoomDetailsKitchenForm(1),
      bedroom: this.createHandoverRoomDetailsForm(2),
      bathroom: this.createHandoverRoomDetailsForm(3),
      toilet: this.createHandoverRoomDetailsForm(4),
      storageRoom: this.createHandoverRoomDetailsForm(5),
      balcony: this.createHandoverRoomDetailsForm(6),
      terrace: this.createHandoverRoomDetailsForm(7),
      garden: this.createHandoverRoomDetailsForm(8),
      loggia: this.createHandoverRoomDetailsForm(9),
      houseEntrance: this.createHandoverRoomDetailsForm(10),
      stairwell: this.createHandoverRoomDetailsForm(11),
      cellar: this.createHandoverRoomDetailsForm(12),
      parkingSlot: this.createHandoverRoomDetailsForm(13),
      otherRooms: this._fb.array([]),
    });
  }

  public createOtherRoomsArrayItemForm(initialOrder = 0): FormGroup {
    return this._fb.group({
      title: '',
      freeFromDefects: true,
      notes: '',
      images: this._fb.array([]),
      order: initialOrder,
    });
  }

  public createHandoverRoomDetailsForm(initialOrder = 0): FormGroup {
    return this._fb.group({
      isAvailable: false,
      freeFromDefects: true,
      notes: '',
      images: this._fb.array([]),
      order: initialOrder,
    });
  }

  public createHandoverRoomDetailsKitchenForm(initialOrder: number): FormGroup {
    return this._fb.group({
      isAvailable: false,
      freeFromDefects: true,
      builtInKitchen: false,
      notes: '',
      images: this._fb.array([]),
      order: initialOrder,
    });
  }

  public createInstallationAndConversionsForm(): FormGroup {
    return this._fb.group({
      available: false,
      notes: '',
      stayAsIs: false,
      removalDate: null,
      otherConditionsNotes: '',
      images: this._fb.array([]),
    });
  }

  public createMiscForm(): FormGroup {
    return this._fb.group({
      handoverDate: new Date().toISOString().substring(0, 10),
      notes: '',
      sendCustomerPortalLink: false,
      images: this._fb.array([]),
    });
  }

  public createHandoverProtocolForm(isRentObject: boolean = false, objectData?: any, mode?: string): FormGroup {

    const form = this._fb.group({
      state: 'pending',
      protocolType: mode ?? 'handover',
      propertyInformation: this.createPropertyInformationForm(objectData),
      transferorType: [CustomerType.Private],
      transferors: this._fb.array([]),
      businessTransferor: this._fb.group({
        data: this._businessCustomerFormService.createBusinessCustomerFormGroup(),
        customers: this._businessCustomerFormService.createPrivateCustomers(),
      }),
      transfereeType: [CustomerType.Private],
      transferees: this._fb.array([]),
      businessTransferee: this._fb.group({
        data: this._businessCustomerFormService.createBusinessCustomerFormGroup(),
        customers: this._businessCustomerFormService.createPrivateCustomers(),
      }),
      handoverRooms: this.createHandoverRoomsForm(),
      propertyCondition: this._fb.group({
        asAgreed: true,
        notes: '',
        images: this._fb.array([]),
      }),
      installations: this.createInstallationAndConversionsForm(),
      conversions: this.createInstallationAndConversionsForm(),
      operatorRegistration: this._fb.group({
        doEvnRegistration: false,
        electricity: this._fb.group({
          doRegister: false,
          startDate: this._fb.control('', { validators: startDateValidator }),
          provider: '',
          contractPartnerId: '',
        }),
        gas: this._fb.group({
          doRegister: false,
          startDate: this._fb.control('', { validators: startDateValidator }),
          provider: '',
          contractPartnerId: '',
        }),
        additionalServices: this._fb.group({
          tvAndInternet: false,
          photovoltaics: false,
          insurances: false,
          insuranceOffer: this._fb.group({
            wefox: false,
            contractPartnerId: '',
          }),
          rentControlPackage: false,
        }),
      }),
      meters: this._fb.array([]),
      misc: this.createMiscForm(),
      otherAgreements: this._fb.group({
        specialFloorTreatment: false,
        continuosDescaling: false,
        repairmentInfo: false,
        gardenCare: false,
        landlordAccess: false,
        watering: false,
        wasteInformation: false,
        escapeRoute: false,
        buildingInspection: null,
        contractFurniture: null,
        firstMoveIn: null,
        wallWorks: this.createWallWorksForm(),
        cleaning: this.createCleaningForm(),
      }),
      emails: this._fb.array([]),
      documents: this._fb.array([]),
      keys: this._fb.group({
        received: true,
        notes: '',
        images: this._fb.array([]),
      }),
      deposit: this._fb.group({
        received: false,
        amount: 0,
        paymentMethod: '',
        notes: '',
      }),
      agent: [{}],
    });

    if (!isRentObject) {
      form.controls.deposit.disable();
    }

    form.get('transferorType')?.valueChanges.subscribe((value) => {
      if (value === CustomerType.Private) {
        form.get('businessTransferor')?.disable();
        form.get('transferors')?.enable();
      } else if (value === CustomerType.Business) {
        form.get('businessTransferor')?.enable();
        form.get('transferors')?.disable();
      }
    });

    form.get('transfereeType')?.valueChanges.subscribe((value) => {
      if (value === CustomerType.Private) {
        form.get('businessTransferee')?.disable();
        form.get('transferees')?.enable();
      } else if (value === CustomerType.Business) {
        form.get('businessTransferee')?.enable();
        form.get('transferees')?.disable();
      }
    });

    return form;
  }

  public createHandoverProtocolInitialValue(
    isRentObjects: boolean,
    data: { objectId: string, defaultEmails: string[] },
    objectData: any,
    mode: string,
  ): HandoverProtocol {
    const hopForm = this.createHandoverProtocolForm(isRentObjects, objectData, mode);
    const meterForm = this.createMetersArrayItemForm();
    const emails = data.defaultEmails ?? [];

    return {
      ...hopForm.value,
      objectId: data.objectId,
      id: '',
      state: 'pending',
      lastVisited: '',
      emails,
      meters: [meterForm.value],
      businessTransferee: undefined,
      businessTransferor: undefined,
    };
  }

  public addCustomerValidators(customerGroup: FormGroup): void {
    const firstnameControl = customerGroup.get('firstname');
    const lastnameControl = customerGroup.get('lastname');
    const emailControl = customerGroup.get('contact.email');

    if (firstnameControl) {
      firstnameControl.setValidators([Validators.required]);
      firstnameControl.updateValueAndValidity();
    }
    if (lastnameControl) {
      lastnameControl.setValidators([Validators.required]);
      lastnameControl.updateValueAndValidity();
    }
    if (emailControl) {
      emailControl.setValidators([Validators.email, Validators.required]);
      emailControl.updateValueAndValidity();
    }

    customerGroup.markAllAsTouched();
    customerGroup.markAsDirty();
  }
}
