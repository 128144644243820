import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectProfile } from 'src/app/store/profile/profile.selectors';
import { AuthService } from '../../services/auth.service';
import { AvatarSize } from './avatar-size';

@Component({
  selector: 'common-user-avatar',
  template: `
    <common-avatar
      [imageSrc]="imageSrc"
      [namesForInitials]="namesForInitials"
      [size]="size"
    ></common-avatar>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class UserAvatarComponent implements OnDestroy {
  @Input() size: AvatarSize = 'large';

  imageSrc: string | undefined;
  namesForInitials: string[] | undefined;

  private _subscription = new Subscription();

  constructor(private _authService: AuthService, private readonly store: Store) {
    this._subscription.add(
      this._authService.picture$.subscribe((picture: string | undefined) => {
        if (picture) {
          this.imageSrc = picture;
        } else {
          this.store.select(selectProfile).subscribe((profile) => {
            this.setNamesForInitials(profile.givenName, profile.familyName);
          });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private setNamesForInitials(
    firstname: string | undefined,
    lastname: string | undefined
  ): void {
    this.namesForInitials = [];
    if (firstname) {
      this.namesForInitials?.push(firstname);
    }
    if (lastname) {
      this.namesForInitials?.push(lastname);
    }
  }
}
