import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'common-screen-size-indicator',
  template: `
    <div class="w-full text-center border bg-gray-300 border-gray-400">
      <p class="p-1 text-black bg-yellow-50 block  sm:hidden">
        <b>X-Small</b>&nbsp;<small>...639px</small>
        <ng-container *ngTemplateOutlet="lowsign"></ng-container>
      </p>
      <p class="p-1 text-black bg-yellow-100 hidden sm:block md:hidden">
        <b>Small</b>&nbsp;<small>640px...767px</small>
        <ng-container *ngTemplateOutlet="lowsign"></ng-container>
      </p>
      <p
        class="p-1 text-black bg-yellow-300 hidden          md:block lg:hidden xl:hidden"
      >
        <b>Medium</b>&nbsp;<small>768px...1023px</small>
        <ng-container *ngTemplateOutlet="lowsign"></ng-container>
      </p>
      <p
        class="p-1 text-black bg-yellow-500 hidden                   lg:block  xl:hidden"
      >
        <b>Large</b>&nbsp;<small>1024px...1279px</small>
        <ng-container *ngTemplateOutlet="lowsign"></ng-container>
      </p>
      <p
        class="p-1 text-white bg-yellow-700 hidden                             xl:block 2xl:hidden"
      >
        <b>XLarge</b>&nbsp;<small>1280px...1536px</small>
        <ng-container *ngTemplateOutlet="lowsign"></ng-container>
      </p>
      <p
        class="p-1 text-white bg-yellow-900 hidden                                      2xl:block"
      >
        <b>2XLarge</b>&nbsp;<small>1536px...</small>
        <ng-container *ngTemplateOutlet="lowsign"></ng-container>
      </p>
    </div>

    <ng-template #lowsign>
      <small class="hidden low:inline-block ml-4 text-black font-bold"
        >low viewport</small
      >
    </ng-template>
  `,
  styles: [
    `
      :host {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        opacity: 50%;
      }
    `,
  ],
})
export class ScreenSizeIndicatorComponent {}
