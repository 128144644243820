import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {SnackbarService} from "services/snackbar.service";
import {ExposeGeneratorApiFacade} from "../expose-generator-api.facade";
import * as DataActions from './actions';
import {catchError, map, switchMap} from "rxjs/operators";

@Injectable()
export class ExposeGeneratorEffects {

  public createExposeGenerator$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreateExposeGenerator),
      switchMap(({ objectId, exposeGenerator }) =>
        this._exposeGeneratorApiFacade.createExposeGenerator$(objectId, exposeGenerator).pipe(
          switchMap((value) => [DataActions.CreateExposeGeneratorSucceeded({ payload: value })]),
          catchError(() => [DataActions.CreateExposeGeneratorFailed()])
        )
      )
    )
  );

  public createExposeGeneratorFailed$ = createEffect(
    () =>
    this._actions$.pipe(
      ofType(DataActions.CreateExposeGeneratorFailed),
      map(() =>
        this._snackbarService.showSnackbar(
          'Exposé konnte nicht erzeugt werden',
          'mat-warn',
          true
        )
      )
    ),
    { dispatch: false }
  );

  public createExposeGeneratorSucceeded$ = createEffect(() =>
      this._actions$.pipe(
        ofType(DataActions.CreateExposeGeneratorSucceeded),
        map(() =>
          this._snackbarService.showSnackbar(
            'Exposé erfolgreich erstellt',
            'mat-primary',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public loadExposees$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadExposees),
      switchMap(({ objectId }) =>
        this._exposeGeneratorApiFacade.loadExposees$(objectId).pipe(
          switchMap((value) => [DataActions.ExposeesLoaded({ payload: value })]),
          catchError(() => [DataActions.ExposeesLoadingFailed()])
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _snackbarService: SnackbarService,
    private readonly _exposeGeneratorApiFacade: ExposeGeneratorApiFacade
  ) {
  }
}
