import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TeamDataFacade, TeamInvitation } from 'src/app/domains/team';
import { selectProfile } from 'src/app/store/profile/profile.selectors';

@Component({
  selector: 'app-teams-banner',
  template: `
    <div *ngIf="invitations$ | async as invitations">
      <div
        *ngIf="getUserInvitations(invitations).length > 0 && !isInTeamsPage"
        class="flex justify-between rounded-lg banner-container p-2 pl-4 mt-4"
      >
        <div class="flex items-center">
          <div class="img-container"></div>
          <div class="text-content text-primary">
            Sie wurden zu einem Team in PROPUP eingeladen. Klicken Sie auf den
            Link um das Team anzuzeigen.
          </div>
        </div>
        <div class="flex items-center ml-2">
          <a
            [routerLink]="[routerLink]"
            class="text-base font-source font-bold py-2 bg-primary text-white rounded-lg px-3"
            >zu PROPUP Teams</a
          >
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .banner-container {
        background-color: #eef3fa;
      }
    `,
  ],
})
export class TeamsBannerComponent implements OnInit {
  invitations$ = this._facade.invitations$;
  email!: string;
  routerLink = 'settings/teams';

  private _subscription = new Subscription();

  get isInTeamsPage(): boolean {
    return this._router.url.includes(this.routerLink);
  }

  constructor(
    private readonly _facade: TeamDataFacade,
    private readonly _store: Store,
    private readonly _router: Router
  ) { }

  ngOnInit(): void {
    this._subscription.add(
      this._store.select(selectProfile).subscribe((profile) => {
        this.email = profile.email;
        this._facade.loadTeamsInvitations();
      })
    );
  }

  getUserInvitations(invitations: TeamInvitation[]) {
    return invitations.filter((i) => i.email === this.email);
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
