import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of, timer} from 'rxjs';
import {switchMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class LoadingSpinnerService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$: Observable<boolean> = this.loadingSubject.asObservable().pipe(
    switchMap((loading) =>
      loading ? timer(500).pipe(switchMap(() => of(true))) : of(false)
    )
  );

  show(): void {
    this.loadingSubject.next(true);
  }

  hide(): void {
    this.loadingSubject.next(false);
  }
}
