import {UserProfile} from "../settings.model";
import {Action, createReducer, on} from "@ngrx/store";
import * as DataActions from "./actions";

export interface State {
  userProfile: {
    data: UserProfile | null;
    loading: boolean;
  };
}

export const initialState: State = {
  userProfile: {
    data: null,
    loading: false,
  },
};

const settingsReducer = createReducer(
  initialState,
  on(DataActions.LoadUserProfile, (state) => ({
    ...state,
    userProfile: {
      loading: true,
      data: null,
    },
  })),
  on(DataActions.UserProfileLoaded, (state, { payload }) => ({
    ...state,
    userProfile: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.UserProfileLoadingFailed, (state) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      loading: false,
    },
  }))
);

export const reducer = (state: State | undefined, action: Action): State => settingsReducer(state, action);

export const getUserProfile = (state: State): UserProfile | null => state.userProfile.data;
