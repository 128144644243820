import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export const startDateValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const doRegister: boolean = control.parent?.get('doRegister')?.value;
  const required = Validators.required;
  return doRegister ? required(control) : null;
};
