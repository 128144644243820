import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CreditCheckFeatureState, creditCheckSelectors } from "./store";
import { CreditCheckActions } from "./store/action-types";
import { AuthService } from 'services/auth.service';
import {getAgentCompany, getAgentProfile} from 'src/app/common-models/mapping';

@Injectable({ providedIn: 'root' })
export class CreditCheckDataFacade {
  creditCheckDetails$ = this._store$.select(creditCheckSelectors.creditCheckDetails);
  creditChecks$ = this._store$.select(creditCheckSelectors.creditChecks);
  creditCheckLoading$ = this._store$.select(creditCheckSelectors.creditChecksLoading)

  constructor(
    private readonly _store$: Store<CreditCheckFeatureState>,
    private readonly _authService: AuthService,
  ) { }

  public loadCreditChecks(customerId: string): void {
    this._store$.dispatch(CreditCheckActions.LoadCreditChecks({ customerId }));
  }

  public loadCreditCheckDetails(customerId: string, creditCheckId: string): void {
    this._store$.dispatch(CreditCheckActions.LoadCreditCheckDetails({ customerId, creditCheckId }));
  }

  public createCreditCheck(creditCheck: any): void {
    const agentCompany = getAgentCompany(this._authService);
    const agentProfile = getAgentProfile(this._authService);
    this._store$.dispatch(CreditCheckActions.CreateCreditCheck({ creditCheck, agentCompany, agentProfile }));
  }

  public deleteCreditCheck(creditCheckId: string): void {
    this._store$.dispatch(CreditCheckActions.DeleteCreditCheck({ creditCheckId }));
  }
}
