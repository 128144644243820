import { SnackbarService } from '../../services/snackbar.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  failedToLoadProfile,
  failedToSaveProfile,
  loadProfile,
  succeededToSaveProfile,
} from './profile.actions';
import { ProfileService } from 'services/profile.service';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private profileService: ProfileService
  ) {}

  triggerLoadProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadProfile, succeededToSaveProfile),
        map(() => this.profileService.loadProfile())
      ),
    { dispatch: false }
  );

  triggerSaveProfileSuccessSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(succeededToSaveProfile),
        map(() =>
          this.snackbar.showSnackbar(
            'Nutzerprofil erfolgreich gespeichert',
            'mat-primary',
            true
          )
        )
      ),
    { dispatch: false }
  );

  triggerSaveProfileErrorSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(failedToSaveProfile),
        map(() =>
          this.snackbar.showSnackbar(
            'Nutzerprofil konnte nicht gespeichert werden',
            'mat-warn'
          )
        )
      ),
    { dispatch: false }
  );

  triggerLoadProfileErrorSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(failedToLoadProfile),
        map(() =>
          this.snackbar.showSnackbar(
            'Nutzerprofil konnte nicht geladen werden',
            'mat-warn',
            true
          )
        )
      ),
    { dispatch: false }
  );
}
