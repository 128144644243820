<div
  matTooltip="Jetzt aktivieren!"
  class="flex items-center px-2 py-1 rounded-md"
  [ngClass]="{
    'token-container valid': isValid,
    'bg-yellow-300 cursor-pointer': !isValid
  }"
>
  <div
    class="status-ring mr-2"
    [ngClass]="{ 'border-gray-300': !isValid, valid: isValid}"
  ></div>
  {{name}} {{ isValid ? 'aktiv' : 'inaktiv' }}
</div>