import { createAction, props } from '@ngrx/store';
import { AgentCompany } from 'src/app/common-models/agent-company';
import { AgentProfile } from 'src/app/common-models/agent-profile';

export const CreatePreListing = createAction(
  '[PreListing] Create Pre-Listing',
  props<{
    objectId: string;
    agentProfile: AgentProfile;
    agentCompany: AgentCompany;
  }>()
);
export const CreatePreListingFailed = createAction(
  '[PreListing] Failed to create and upload Pre-Listing document'
);
export const CreatePreListingSucceded = createAction(
  '[PreListing] Succeeded to create and save Pre-Listing'
);
