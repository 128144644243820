import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { ContractPartnerApiFacade } from '../contract-partner-api.facade';
import { ContractPartnerActions } from './action-types';
import { SnackbarService } from "services/snackbar.service";

@Injectable()
export class ContractPartnerEffects {

  deleteContractPartner$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContractPartnerActions.DeleteContractPartner),
      mergeMap(({ contractPartnerId }) =>
        this._contractPartnerApiFacade.deleteContractPartner(contractPartnerId).pipe(
          switchMap(() => {
            this._snackbarService.showSnackbar(
              'Kunde erfolgreich gelöscht',
              'mat-primary',
              true
            );
            return [ContractPartnerActions.DeleteContractPartnerSuccess({ contractPartnerId })];
          }), catchError(() => {
            this._snackbarService.showSnackbar(
              'Kunde konnte nicht gelöscht werden',
              'mat-warn',
              true
            );
            return [ContractPartnerActions.DeleteContractPartnerFailed({ contractPartnerId })];
          })
        )
      ),
    ),
  );

  public loadContractPartners$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContractPartnerActions.LoadContractPartners),
      switchMap(() =>
        this._contractPartnerApiFacade.getAllCustomers$().pipe(
          switchMap((customers) => [
            ContractPartnerActions.LoadContractPartnersSucceeded({ payload: customers }),
          ]),
          catchError(() => [ContractPartnerActions.LoadContractPartnersFailed()])
        )
      )
    )
  );

  public loadContractPartnerDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ContractPartnerActions.LoadContractPartnerDetails),
      switchMap(({ payload }) =>
        this._contractPartnerApiFacade.getCustomerById$(payload.id).pipe(
          switchMap((customer) => [
            ContractPartnerActions.LoadContractPartnerSucceeded({ payload: customer }),
          ]),
          catchError(() => [ContractPartnerActions.LoadContractPartnerFailed()])
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _contractPartnerApiFacade: ContractPartnerApiFacade,
    private readonly _snackbarService: SnackbarService
  ) { }
}
