import { Injectable } from '@angular/core';
import { CustomerDataFacade, CustomerFormService, CustomerWithContact } from '@domains/customer';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import { SnackbarService } from 'services/snackbar.service';
import { RentalContractDataFacade } from '@domains/rental-contract';
import { CustomerApiFacade } from '../customer-api.facade';
import { CustomerActions } from './action-types';
import * as DataActions from "../../handover-protocol/store/actions";

@Injectable()
export class CustomerEffects {

  deleteCustomer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CustomerActions.DeleteCustomer),
      mergeMap(({ customerId }) =>
        this._customerApiFacade.deleteCustomer(customerId).pipe(
          switchMap(() => {
            this._snackbar.showSnackbar(
              'Kunde erfolgreich gelöscht',
              'mat-primary',
              true
            );
            return [CustomerActions.DeleteCustomerSuccess({ customerId })];
          }), catchError(() => {
            this._snackbar.showSnackbar(
              'Kunde konnte nicht gelöscht werden',
              'mat-warn',
              true
            );
            return [CustomerActions.DeleteCustomerFailed({ customerId })];
          })
        )
      ),
    ),
  );

  public loadCustomers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CustomerActions.LoadCustomers),
      switchMap(({ pagingOptions, searchText }) =>
        this._customerApiFacade.getCustomers$(pagingOptions, searchText).pipe(
          switchMap((data) => [CustomerActions.LoadCustomersSucceeded({ payload: data })]),
          catchError(() => [CustomerActions.LoadCustomersFailed()])
        )
      )
    )
  );

  public loadCustomerDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CustomerActions.LoadCustomerDetails),
      switchMap(({ payload }) =>
        this._customerApiFacade.getCustomerById$(payload.id).pipe(
          switchMap((customer) => [
            CustomerActions.LoadCustomerSucceeded({ payload: customer }),
          ]),
          catchError(() => [CustomerActions.LoadCustomerFailed()])
        )
      )
    )
  );

  triggerExternalCustomersLoadSuccessSnackbar$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(CustomerActions.TriggerExternalCustomersLoadingSucceeded),
        map(() =>
          this._snackbar.showSnackbar(
            'Kundenliste wird geladen',
            'mat-primary',
            true
          )
        )
      ),
    { dispatch: false }
  );

  saveRentalContractCustomers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(CustomerActions.SaveRentalContractCustomers),
      switchMap(({ contractTenants, contractLandlords, rentalContractId, objectId }) =>
        combineLatest([
          forkJoin(
            contractTenants.map((customer: CustomerWithContact) => {
              return this._saveAndUpdateCustomer(customer);
            })
          ),
          forkJoin(
            contractLandlords.map((customer: CustomerWithContact) => {
              return this._saveAndUpdateCustomer(customer);
            })
          ),
        ]).pipe(
          map(([savedContractTenantCustomers, savedContractLandlordCustomers]) => {
            const validContractTenants = savedContractTenantCustomers.filter(t => Boolean);
            const validContractLandlords = savedContractLandlordCustomers.filter(t => Boolean);

            this._rentalContractDataFacade.updateRentalContractCustomers(
              validContractTenants as CustomerWithContact[],
              validContractLandlords as CustomerWithContact[],
              rentalContractId,
              objectId,
            );
          })
        )
      ),
    ),
    { dispatch: false }
  );

  private _saveAndUpdateCustomer(
    customer: CustomerWithContact
  ): Observable<CustomerWithContact | null> {
    if (!this._isValidCustomer(customer)) {
      return of(null);
    }

    return this._customerDataFacade.saveCustomer$(
      this._customerFormService.convertToFormGroup(customer)
    );
  }

  private _isValidCustomer(customer: CustomerWithContact): boolean {
    const firstnameValid = !!customer.firstname;
    const lastnameValid = !!customer.lastname;
    const emailValid = !!customer.contact.email;

    return firstnameValid && lastnameValid && emailValid;
  }

  constructor(
    private readonly _actions$: Actions,
    private readonly _snackbar: SnackbarService,
    private readonly _customerApiFacade: CustomerApiFacade,
    private readonly _customerDataFacade: CustomerDataFacade,
    private readonly _customerFormService: CustomerFormService,
    private readonly _rentalContractDataFacade: RentalContractDataFacade,
  ) { }
}
