import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { first, map } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client';

import { BusinessCustomer, BusinessCustomerWithCustomersDetails } from './business-customer.model';

@Injectable({ providedIn: 'root' })
export class BusinessCustomerApiFacade {
  constructor(private readonly apollo: Apollo) { }

  createBusinessCustomer$(data: BusinessCustomer): Observable<BusinessCustomer> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation CreateBusinessCustomer($customer: CreateBusinessCustomer!) {
            businessCustomer(customer: $customer) {
              id
              companyNumber
              companyRegister
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
                phone
                email
              }
              dataPrivacy {
                propertyInformation
                newsletter
                questionnaire
                sellingLead
                interestInFinanceOffer
              }
              customers
            }
          }
        `,
        variables: {
          customer: data,
        },
      })
      .pipe(map((result: any) => result.data.businessCustomer));
  }

  searchBusinessCustomer$(companyNumber: string): Observable<BusinessCustomer[]> {
    return this.apollo
      .watchQuery({
        query: gql`
          query GetBusinessCustomers($companyNumber: String) {
            searchBusinessCustomers(companyNumber: $companyNumber) {
              id
              companyNumber
              companyRegister
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
                phone
                email
              }
              customers
            }
          }
        `,
        variables: {
          companyNumber: companyNumber ?? '',
        },
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) => result.data.searchBusinessCustomers)
      );
  }

  getAllCustomers$(): Observable<BusinessCustomer[]> {
    return this.apollo
      .watchQuery({
        query: gql`
          query GetBusinessCustomers {
            businessCustomers {
              id
              companyNumber
              companyRegister
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
                phone
                email
              }
              dataPrivacy {
                propertyInformation
                newsletter
                questionnaire
                sellingLead
                interestInFinanceOffer
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((result: ApolloQueryResult<any>) => result.data.businessCustomers)
      );
  }

  getCustomerById$(id: string): Observable<BusinessCustomerWithCustomersDetails> {
    return this.apollo
      .query({
        query: gql`
          query GetBusinessCustomerDetails($customerId: String!) {
            businessCustomer(id: $customerId) {
              id
              companyNumber
              companyRegister
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
                email
                phone
              }
              dataPrivacy {
                propertyInformation
                newsletter
                questionnaire
                sellingLead
                interestInFinanceOffer
              }
              customers {
                id
                title
                firstname
                lastname
                birthdate
                nationality
                contact {
                  address {
                    country
                    city
                    zipCode
                    streetName
                    buildingNumber
                    doorNumber
                    stairway
                    longitude
                    latitude
                  }
                  email
                  phone
                }
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
        variables: {
          customerId: id,
        },
      })
      .pipe(
        first(),
        map((result: ApolloQueryResult<any>) => result.data.businessCustomer)
      );
  }

  deleteBusinessCustomer(businessCustomerId: string): Observable<Boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation DeleteBusinessCustomer($businessCustomerId: String!) {
          deleteBusinessCustomer(businessCustomerId: $businessCustomerId)
        }
      `,
      variables: {
        businessCustomerId,
      },
    }).pipe(
      map((result: any) => result.data.deleteBusinessCustomer)
    );
  }

}
