import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as fromReducer from './reducer';

export interface CustomerFeatureState {
  customer: fromReducer.State;
}

export const reducers: ActionReducerMap<CustomerFeatureState> = {
  customer: fromReducer.reducer,
};

export const getCustomerState = createFeatureSelector<fromReducer.State>('customer');

export const customerSelectors = {
  customersList: createSelector(getCustomerState, fromReducer.getCustomersList),
  customersListData: createSelector(getCustomerState, fromReducer.getCustomersListData),
  customersListMetadata: createSelector(getCustomerState, fromReducer.getCustomersListMetadata),
  customersLoading: createSelector(getCustomerState, fromReducer.getCustomersLoading),
  customerDetails: createSelector(getCustomerState, fromReducer.getCustomerDetails),
  customerDetailsLoading: createSelector(getCustomerState, fromReducer.getCustomerDetailsLoading),
};
