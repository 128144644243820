import { Action, createReducer, on } from '@ngrx/store';
import { PlainAgreement } from "../plain-agreement.model"
import * as DataActions from './actions';

export interface State {
  plainAgreements: {
    data: PlainAgreement[];
    loading: boolean;
  };
  plainAgreementDetails: {
    data: PlainAgreement | null;
    loading: boolean;
  };
}

export const initialState: State = {
  plainAgreements: {
    data: [],
    loading: false,
  },
  plainAgreementDetails: {
    data: null,
    loading: false,
  },
};

const plainAgreementReducer = createReducer(
  initialState,

  on(DataActions.LoadPlainAgreements, DataActions.LoadPlainAgreementsByCustomer, (state) => ({
    ...state,
    plainAgreements: {
      loading: true,
      data: [],
    }
  })),
  on(DataActions.PlainAgreementsLoaded, (state, { payload }) => ({
    ...state,
    plainAgreements: {
      loading: false,
      data: payload,
    }
  })),
  on(DataActions.PlainAgreementsLoadingFailed, (state) => ({
    ...state,
    plainAgreements: {
      ...state.plainAgreements,
      loading: false,
    },
  })),
  on(DataActions.LoadPlainAgreementDetails, (state) => ({
    ...state,
    plainAgreementDetails: {
      loading: true,
      data: null,
    },
  })),
  on(DataActions.PlainAgreementDetailsLoaded, (state, { payload }) => ({
    ...state,
    plainAgreementDetails: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.PlainAgreementDetailsLoadingFailed, (state) => ({
    ...state,
    plainAgreementDetails: {
      ...state.plainAgreementDetails,
      loading: false,
    },
  })),
  on(DataActions.DeletePlainAgreementSuccess, (state, { plainAgreementId }) => ({
    ...state,
    plainAgreements: {
      ...state.plainAgreements,
      data: state.plainAgreements.data.filter((ea) => ea.id !== plainAgreementId),
    }
  })),
);

export const reducer = (state: State | undefined, action: Action): State => plainAgreementReducer(state, action);

export const getPlainAgreements = (state: State): PlainAgreement[] => state.plainAgreements.data;
export const getPlainAgreementsLoading = (state: State): boolean => state.plainAgreements.loading;
export const getPlainAgreementDetails = (state: State): PlainAgreement | null => state.plainAgreementDetails.data;
export const getPlainAgreementDetailsLoading = (state: State): boolean => state.plainAgreementDetails.loading;
