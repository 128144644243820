import { createSelector, ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromReducer from './reducer';

export interface RentalOfferFeatureState {
  rentalOffer: fromReducer.State;
}

export const reducers: ActionReducerMap<RentalOfferFeatureState> = {
  rentalOffer: fromReducer.reducer,
};

export const getRentalOfferState = createFeatureSelector<fromReducer.State>('rental-offer');

export const digitalOfferingSelectors = {
  rentalOffers: createSelector(getRentalOfferState, fromReducer.getRentalOffers),
  rentalOffersLoading: createSelector(getRentalOfferState, fromReducer.getRentalOffersLoading),
  rentalOfferDetails: createSelector(getRentalOfferState, fromReducer.getRentalOfferDetails),
  rentalOfferDetailsLoading: createSelector(getRentalOfferState, fromReducer.getRentalOfferDetailsLoading),
};
