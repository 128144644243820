import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as fromReducer from './reducer';

export interface TeamFeatureState {
  team: fromReducer.State;
}

export const reducers: ActionReducerMap<TeamFeatureState> = {
  team: fromReducer.reducer,
};

export const getTeamState = createFeatureSelector<fromReducer.State>('team');

export const teamSelectors = {
  teams: createSelector(getTeamState, fromReducer.getTeams),
  hasTeamMembers: createSelector(getTeamState, fromReducer.getHasTeamMembers),
  creatorTeams: createSelector(getTeamState, fromReducer.getCreatorTeams),
  participatingTeams: createSelector(
    getTeamState,
    fromReducer.getParticipatingTeams
  ),
  invitations: createSelector(getTeamState, fromReducer.getInvitations),
};
