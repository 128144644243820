import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { RentalContract } from "./rental-contract.model";
import { CustomerFormService, CustomerWithContact } from "@domains/customer";

@Injectable({ providedIn: 'root' })
export class RentalContractFormService implements OnDestroy {
  shareValidator = Validators.pattern(/[0-9]+\/[0-9]+/i);

  private _subscription = new Subscription();

  constructor(
    private readonly _customerFormService: CustomerFormService,
    private readonly _fb: FormBuilder
  ) { }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public convertCustomerToFormGroup(c: CustomerWithContact) {
    return this._customerFormService.convertToFormGroup(c);
  }

  public createCondominiumOwnershipForm(): FormGroup {
    return this._fb.group({
      condominiumOwnership: true,
    })
  }

  public createRentalContractDetailsForm(): FormGroup {
    return this._fb.group({
      moreThanTwoProperties: false,
      buildingPermit1945: false,
      buildingPermit1953: false,
      fundedConstruction: false,
      historicPreservation: false,
      propertyCategory: '',
      rentalWithin6Months: false,
      goodLocation: false,
      goodLocationDescription: '',
    })
  }

  public createRentalDurationForm(): FormGroup {
    return this._fb.group({
      rentalDuration: 'Befristet',
      rentalStart: [new Date().toISOString().substring(0, 10)],
      rentalDurationInYears: null,
    })
  }

  public createTerminationRightForm(): FormGroup {
    return this._fb.group({
      prematureTermination: false,
      contractPartiesTermination: '',
      terminationStart: null,
      terminationDuration: null,
    })
  }

  public createRentPriceForm(): FormGroup {
    return this._fb.group({
      landlordVAT: false,
      rentPrice: null,
      parkingSlotPrice: null,
      indexProtection: 'annually',
      indexPercentage: null,
    })
  }

  public createOperatingExpensesForm(): FormGroup {
    return this._fb.group({
      expenseType: 'akonto',
      operatingExpenses: null,
    })
  }

  public createRentMiscForm(): FormGroup {
    return this._fb.group({
      obligationsTenant: this._fb.group({
        snowRemoval: false,
        lawnMowing: false,
        gardenCare: false,
        repairDevices: false,
        serviceGasBoiler: false,
        cleaningStairway: false,
      }),
      subleaseBan: true,
      rentItem: 'fullProperty',
      usageRules: false,
      gotHouseRules: false,
      additionalNotes: '',
    })
  }

  public createRentBillingForm(): FormGroup {
    return this._fb.group({
      obligationContractCosts: 'landlord',
    })
  }

  public createRentPropertyDataForm(): FormGroup {
    return this._fb.group({
      description: '',
      properties: this._fb.array([]),
    })
  }

  public createPropertiesArrayItemForm(): FormGroup {
    return this._fb.group({
      cadastralCommunity: this._fb.control({
        KGNr: '',
        KGName: '',
        PGNr: '',
        PolName: ''
      }),
      entryNumber: '',
      parcelNumbers: this._fb.array([]),
      top: '',
      stake: ['', this.shareValidator],
      bLnr: this._fb.array([]),
      parkingSlot: this._fb.array([]),
    })
  }

  public createStakesArrayItemForm(): FormGroup {
    return this._fb.group({
      top: '',
      stake: ['', this.shareValidator],
      bLnr: this._fb.array([])
    })
  }

  public createParkingSlotArrayItemForm(): FormGroup {
    return this._fb.group({
      top: '',
      stake: ['', this.shareValidator],
      bLnr: this._fb.array([])
    })
  }

  public createRentContractDepositForm(): FormGroup {
    return this._fb.group({
      depositMethod: 'NONE',
      amount: null,
    })
  }

  public createContractTenantsArrayItemForm(): FormGroup {
    return this._fb.group({
      customer: this._customerFormService.createCustomerFormGroup(true),
      contractTenant: this.createContractTenantDetailsForm(),
    });
  }

  public createContractTenantDetailsForm(): FormGroup {
    return this._fb.group({
      customerId: '',
    })
  }

  public createContractLandlordsArrayItemForm(): FormGroup {
    return this._fb.group({
      customer: this._customerFormService.createCustomerFormGroup(true),
      contractLandlord: this.createContractLandlordDetailsForm(),
    });
  }

  public createContractLandlordDetailsForm(): FormGroup {
    return this._fb.group({
      customerId: '',
      shares: this._fb.array([]),
    })
  }

  public createCustomerStakesArrayItemForm(): FormGroup {
    return this._fb.group({
      stake: ['', this.shareValidator],
      bLnr: ''
    })
  }

  public createRentalContractForm(): FormGroup {
    return this._fb.group({
      condominiumOwnership: this.createCondominiumOwnershipForm(),
      rentalContractDetails: this.createRentalContractDetailsForm(),
      rentalDuration: this.createRentalDurationForm(),
      terminationRight: this.createTerminationRightForm(),
      rentPrice: this.createRentPriceForm(),
      operatingExpenses: this.createOperatingExpensesForm(),
      rentMisc: this.createRentMiscForm(),
      rentBilling: this.createRentBillingForm(),
      rentContractDeposit: this.createRentContractDepositForm(),
      rentPropertyData: this.createRentPropertyDataForm(),
      contractTenants: this._fb.array([]),
      contractLandlords: this._fb.array([]),
    })
  }

  public createRentalContractInitialValue(objectId: string): RentalContract {
    const rentalContractForm = this.createRentalContractForm();
    const contractTenantForm = this.createContractTenantDetailsForm();
    const contractLandlordForm = this.createContractLandlordDetailsForm();

    return {
      ...rentalContractForm.value,
      objectId: objectId,
      state: 'pending',
      lastVisited: '',
      contractTenants: [contractTenantForm.value],
      contractLandlords: [contractLandlordForm.value],
    }
  };

  public addCustomerValidators(customerGroup: FormGroup): void {
    const firstnameControl = customerGroup.get('firstname');
    const lastnameControl = customerGroup.get('lastname');
    const emailControl = customerGroup.get('contact.email');

    if (firstnameControl) {
      firstnameControl.setValidators([Validators.required]);
      firstnameControl.updateValueAndValidity();
    }
    if (lastnameControl) {
      lastnameControl.setValidators([Validators.required]);
      lastnameControl.updateValueAndValidity();
    }
    if (emailControl) {
      emailControl.setValidators([Validators.email, Validators.required]);
      emailControl.updateValueAndValidity();
    }

    customerGroup.markAllAsTouched();
    customerGroup.markAsDirty();
  }


}
