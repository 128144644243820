import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AccountLinkingService } from 'services/account-linking.service';
import { selectIsEdiReal, selectIsJustimmo, selectIsMicrosoft, selectIsRemax } from 'src/app/store/settings/settings.selectors';
import { selectIsEdiRealTokenValid, selectIsJustimmoTokenValid, selectIsMicrosoftTokenValid, selectIsRemaxTokenValid } from 'src/app/store/_global/global.selectors';
import { RestoreConnectionDialog } from 'src/app/common-components/restore-connection-dialog/restore-connection-dialog';

@Injectable({
  providedIn: 'root',
})
export class IntegrationStatusService {
  constructor(
    private readonly _store: Store,
    private readonly _accountLinkingService: AccountLinkingService,
    private readonly dialog: MatDialog
  ) { }

  initializeSubscriptions(): void {
    this.handleIntegrationStatus(
      this._store.select(selectIsRemax),
      this._store.select(selectIsRemaxTokenValid),
      'RE/MAX',
      this._accountLinkingService.generateLink('remax-idp', window.location.href)
    );

    this.handleIntegrationStatus(
      this._store.select(selectIsMicrosoft),
      this._store.select(selectIsMicrosoftTokenValid),
      'Microsoft',
      this._accountLinkingService.generateLink('microsoft', window.location.href)
    );

    this.handleIntegrationStatus(
      this._store.select(selectIsEdiReal),
      this._store.select(selectIsEdiRealTokenValid),
      'EDI-Real',
      this._accountLinkingService.generateLink('edi-real', window.location.href)
    );

    this.handleIntegrationStatus(
      this._store.select(selectIsJustimmo),
      this._store.select(selectIsJustimmoTokenValid),
      'Justimmo',
      this._accountLinkingService.generateLink('justimmo', window.location.href)
    );
  }

  private handleIntegrationStatus(
    integration$: Observable<boolean>,
    tokenValid$: Observable<boolean>,
    systemName: string,
    restoreLink: string
  ): void {
    combineLatest([integration$, tokenValid$])
      .pipe(debounceTime(300)) // Adjust debounce time as needed
      .subscribe(([hasConnected, connectionValid]) => {
        if (hasConnected && !connectionValid) {
          this.openDialog(systemName, restoreLink);
        }
      });
  }

  private openDialog(externalSystem: string, restoreLink: string): void {
    const dialogRef = this.dialog.open(RestoreConnectionDialog, {
      data: { name: externalSystem, link: restoreLink },
    });
    dialogRef.afterClosed().toPromise().then(() => {
      dialogRef.close();
    });
  }
}