import { createAction, props } from '@ngrx/store';
import { Settings } from './settings.reducers';

export const setSettings = createAction(
  '[Settings] Set',
  props<{ settings: Partial<Settings> }>()
);

export const loadSettings = createAction('[Settings] Load');

export const succeededToSaveSettings = createAction(
  '[Settings] Succeeded to save Settings'
);

export const failedToSaveSettings = createAction(
  '[Settings] Failed to save Settings'
);

export const failedToLoadSettings = createAction(
  '[Settings] Failed to load Settings'
);
