import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerDataFacade } from '@domains/customer';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, first, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'services/auth.service';
import { EdirealService } from 'services/edireal.service';
import { loadProfile } from 'src/app/store/profile/profile.actions';
import { loadSettings, succeededToSaveSettings } from 'src/app/store/settings/settings.actions';
import {
  initializeCountries,
  setEdiRealTokenValidity,
  setJustimmoTokenValidity,
  setRemaxTokenValidity,
} from 'src/app/store/_global/global.actions';
import { ObjectDataFacade } from '../domains/object/object-data.facade';
import { JustimmoService } from 'services/justimmo.service';
import { selectIsEdiReal, selectIsJustimmo, selectIsRemax } from "../store/settings/settings.selectors";
import { SettingsService } from "services/settings.service";
import { RemaxService } from "services/remax.service";

@Component({
  selector: 'app-post-login',
  template: ` <div class="loading-wrapper">
    <mat-spinner [diameter]="50"></mat-spinner>
    <span class="ml-2">Bitte haben Sie einen Augenblick Geduld, wir laden Ihre Daten.</span>
  </div>`,
  styleUrls: ['./post-login.component.scss'],
})
export class PostLoginComponent implements OnInit, AfterViewInit {
  isEdiReal$!: Observable<boolean>;
  isJustimmo$!: Observable<boolean>;
  isOnoffice$!: Observable<boolean>;
  isRemax$!: Observable<boolean>;

  constructor(
    private readonly _store: Store,
    private readonly _router: Router,
    private readonly _authService: AuthService,
    private readonly _edirealService: EdirealService,
    private readonly _justimmoService: JustimmoService,
    private readonly _customerDataFacade: CustomerDataFacade,
    private readonly _objectDataFacade: ObjectDataFacade,
    private readonly _settingsService: SettingsService,
    private readonly _remaxService: RemaxService,
  ) { }

  ngOnInit(): void {
    this.isEdiReal$ = this._store.select(selectIsEdiReal);
    this.isJustimmo$ = this._store.select(selectIsJustimmo);
    this.isRemax$ = this._store.select(selectIsRemax);
    this.storeExternalIds();
  }

  ngAfterViewInit(): void {
    if (this._authService.authenticated) {
      this.triggerRemaxSync();
      this.triggerEdirealSync();
      this.triggerJustimmoSync();
      this._store.dispatch(loadSettings());
      this._store.dispatch(loadProfile());
      this.loadCountries();
      this.loadCadastralCommunities();
      this._router.navigateByUrl('/dashboard');
    } else {
      this._authService.login();
    }
  }

  triggerRemaxSync(): void {
    this._remaxService.checkTokenValidity$().pipe(
      first(),
      tap((value) => { this._store.dispatch(setRemaxTokenValidity({ isTokenValid: value })) }),
      filter(Boolean),
      tap(() => { this._objectDataFacade.triggerExternalObjectsLoading() }),
      switchMap(() => this._remaxService.triggerObjectsSync$()),
      tap((isSuccessful) => {
        if (isSuccessful) {
          setTimeout(() => {
            this._objectDataFacade.loadObjects();
          }, 1000);
        }
      })
    ).subscribe();
  }

  triggerEdirealSync(): void {
    this._edirealService.checkTokenValidity().pipe(
      first(),
      tap((value) => { this._store.dispatch(setEdiRealTokenValidity({ isTokenValid: value })) }),
      filter(Boolean),
      switchMap(() => this._edirealService.triggerCustomersSync()),
    ).subscribe();
  }

  triggerJustimmoSync(): void {
    this._justimmoService.checkTokenValidity$().pipe(
      first(),
      tap((value) => { this._store.dispatch(setJustimmoTokenValidity({ isTokenValid: value })) }),
      filter(Boolean),
      switchMap(() =>
        forkJoin([
          this._justimmoService.triggerPropertiesImport$(),
          this._justimmoService.triggerCustomersImport$(),
        ])
      ),
      tap(([objectSyncSuccess]: [boolean, boolean]) => {
        if (objectSyncSuccess) {
          // TODO: object list could be updated here
          setTimeout(() => {
            this._objectDataFacade.loadObjects();
          }, 3000);
        }
      })
    ).subscribe();
  }

  loadCadastralCommunities(): void {
    this._objectDataFacade.loadCadastralCommunities();
  }

  loadCountries(): void {
    this._customerDataFacade.getCountries$().pipe(
      first(),
      tap((countries) => {
        this._store.dispatch(initializeCountries({ countries }));
      }),
    ).subscribe();
  }

  storeExternalIds(): void {
    if (this.isRemax$) {
      this._remaxService.getRemaxAgentData$().pipe(
        first(),
        tap((userId) => {
          this._settingsService.updateUserProfileSettings$({ remaxUserId: userId }).pipe(
            first(),
            tap(() => this._store.dispatch(succeededToSaveSettings())),
            catchError(() => {
              this._store.dispatch(succeededToSaveSettings())
              return [];
            }),
          ).subscribe()
        }),
      ).subscribe()

    }
    if (this.isJustimmo$) {
      this._justimmoService.getJustimmoAgentData$().pipe(
        first(),
        tap((userId) => {
          this._settingsService.updateUserProfileSettings$({ justimmoUserId: userId }).pipe(
            first(),
            tap(() => this._store.dispatch(succeededToSaveSettings())),
            catchError(() => {
              this._store.dispatch(succeededToSaveSettings())
              return [];
            }),
          ).subscribe()
        }),
      ).subscribe()
    }
    if (this.isEdiReal$) {
      this._edirealService.getEdirealAgentData().pipe(
        first(),
        tap((userId) => {
          this._settingsService.updateUserProfileSettings$({ edirealUserId: userId }).pipe(
            first(),
            tap(() => this._store.dispatch(succeededToSaveSettings())),
            catchError(() => {
              this._store.dispatch(succeededToSaveSettings())
              return [];
            }),
          ).subscribe()
        }),
      ).subscribe();
    }
  }
}
