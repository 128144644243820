import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import * as fromReducer from './reducer';

export interface ContractPartnerFeatureState {
  contractPartner: fromReducer.State;
}

export const reducers: ActionReducerMap<ContractPartnerFeatureState> = {
  contractPartner: fromReducer.reducer,
};

export const getContractPartnerState = createFeatureSelector<fromReducer.State>('contract-partner');

export const contractPartnerSelectors = {
  customers: createSelector(getContractPartnerState, fromReducer.getContractPartners),
  customersLoading: createSelector(getContractPartnerState, fromReducer.getContractPartnersLoading),
  customerDetails: createSelector(getContractPartnerState, fromReducer.getContractPartnerDetails),
  customerDetailsLoading: createSelector(getContractPartnerState, fromReducer.getContractPartnerDetailsLoading),
};
