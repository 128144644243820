import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as TeamActions from './actions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { TeamApiFacade } from '../team-api.facade';
import { SnackbarService } from 'services/snackbar.service';

@Injectable()
export class TeamEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _teamApiFacade: TeamApiFacade,
    private readonly _snackbarService: SnackbarService
  ) { }

  public loadTeams$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TeamActions.LoadTeams),
      switchMap(() =>
        this._teamApiFacade.getTeams$().pipe(
          switchMap((teams) => [
            TeamActions.LoadTeamsSucceeded({ payload: teams }),
          ]),
          catchError(() => [TeamActions.LoadTeamsFailed()])
        )
      )
    )
  );

  public loadTeamsInvitations$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TeamActions.LoadTeamsInvitations),
      switchMap(() =>
        this._teamApiFacade.getTeamsInvitations$().pipe(
          switchMap((invitations) => [
            TeamActions.LoadTeamsInvitationsSucceeded({ payload: invitations }),
          ]),
          catchError(() => [TeamActions.LoadTeamsInvitationsFailed()])
        )
      )
    )
  );

  public addTeamMember$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TeamActions.AddTeamMember),
      switchMap(({ teamId, email }) =>
        this._teamApiFacade.addTeamMember$(teamId, email).pipe(
          switchMap((team) => [
            TeamActions.AddTeamMemberSuccess({ payload: team }),
          ]),
          catchError(() => [TeamActions.AddTeamMemberFailed()])
        )
      )
    )
  );

  public addTeamMemberSucceeded$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TeamActions.AddTeamMemberSuccess),
        map(() =>
          this._snackbarService.showSnackbar(
            'Mitglied wurde erfolgreich zum Team hinzugefügt',
            'mat-primary',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public addTeamMemberFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TeamActions.AddTeamMemberFailed),
        map(() =>
          this._snackbarService.showSnackbar(
            'Mitglied konnte nicht zum Team hinzugefügt werden',
            'mat-warn',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public acceptInvitation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TeamActions.AcceptInvitation),
      switchMap(({ teamId, email }) =>
        this._teamApiFacade.acceptInvitation$(teamId, email).pipe(
          switchMap((team) => [
            TeamActions.AcceptInvitationSuccess({ payload: team }),
          ]),
          catchError(() => [TeamActions.AcceptInvitationFailed()])
        )
      )
    )
  );

  public declineInvitation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TeamActions.DeclineInvitation),
      switchMap(({ teamId, email }) =>
        this._teamApiFacade.declineInvitation$(teamId, email).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              'Einladung abgelehnt',
              'mat-primary',
              true
            );
          }),
          switchMap((team) => [
            TeamActions.DeclineInvitationSuccess({ payload: team }),
          ]),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Einladung konnte nicht abgelehnt werden',
              'mat-warn',
              true
            );
            return [TeamActions.DeclineInvitationFailed()];
          })
        )
      )
    )
  );

  public acceptInvitationSucceeded$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TeamActions.AcceptInvitationSuccess),
        map(() =>
          this._snackbarService.showSnackbar(
            'Einladung akzeptiert',
            'mat-primary',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public acceptInvitationFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TeamActions.AcceptInvitationFailed),
        map(() =>
          this._snackbarService.showSnackbar(
            'Mitglied konnte nicht zum Team hinzugefügt werden',
            'mat-warn',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public inviteTeamMember$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TeamActions.InviteTeamMember),
      switchMap(({ teamId, email }) =>
        this._teamApiFacade.inviteTeamMember$(teamId, email).pipe(
          switchMap((team) => [
            TeamActions.InviteTeamMemberSuccess({ payload: team }),
          ]),
          catchError(() => [TeamActions.InviteTeamMemberFailed()])
        )
      )
    )
  );

  public deleteInvitation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TeamActions.DeleteInvitation),
      switchMap(({ teamId, email }) =>
        this._teamApiFacade.deleteInvitation$(teamId, email).pipe(
          switchMap((team) => [
            TeamActions.DeleteInvitationSuccess({ payload: team }),
          ]),
          catchError(() => [TeamActions.DeleteInvitationFailed()])
        )
      )
    )
  );

  public resendInvitation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TeamActions.ResendInvitation),
      switchMap(({ teamId, email }) =>
        this._teamApiFacade.resendInvitation$(teamId, email).pipe(
          tap((success: boolean) => {
            if (success) {
              this._snackbarService.showSnackbar(
                'Mitglied wurde erfolgreich zum Team eingeladet',
                'mat-primary',
                true
              );
            } else {
              this._snackbarService.showSnackbar(
                'Mitglied konnte nicht zum Team eingeladet werden',
                'mat-warn',
                true
              );
            }
          }),
        )
      )
    ),
    { dispatch: false }
  );

  public inviteTeamMemberSucceeded$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TeamActions.InviteTeamMemberSuccess),
        map(() =>
          this._snackbarService.showSnackbar(
            'Mitglied wurde erfolgreich zum Team eingeladet',
            'mat-primary',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public inviteTeamMemberFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TeamActions.InviteTeamMemberFailed),
        map(() =>
          this._snackbarService.showSnackbar(
            'Mitglied konnte nicht zum Team eingeladet werden',
            'mat-warn',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public removeTeamMember$ = createEffect(() =>
    this._actions$.pipe(
      ofType(TeamActions.RemoveTeamMember),
      switchMap(({ teamId, userId }) =>
        this._teamApiFacade.removeTeamMember$(teamId, userId).pipe(
          switchMap((team) => [
            TeamActions.RemoveTeamMemberSuccess({ payload: team }),
          ]),
          catchError(() => [TeamActions.RemoveTeamMemberFailed()])
        )
      )
    )
  );

  public removeTeamMemberSucceeded$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TeamActions.RemoveTeamMemberSuccess),
        map(() =>
          this._snackbarService.showSnackbar(
            'Mitglied wurde erfolgreich aus dem Team entfernt',
            'mat-primary',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public removeTeamMemberFailed$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(TeamActions.RemoveTeamMemberFailed),
        map(() =>
          this._snackbarService.showSnackbar(
            'Mitglied konnte nicht aus dem Team entfernt werden',
            'mat-warn',
            true
          )
        )
      ),
    { dispatch: false }
  );
}
