import { ModuleWithProviders, NgModule } from '@angular/core';
import { BROWSER_GLOBALS_PROVIDERS } from 'src/app/utils/propup-agm/globals';
import {
  PropupLazyMapsAPILoader,
  PropupLazyMapsAPILoaderConfigLiteral,
  PROPUP_LAZY_MAPS_API_CONFIG,
  PropupMapsAPILoader,
} from 'src/app/utils/propup-agm/maps-api-loader';

@NgModule()
export class PropupAgmModule {
  static forRoot(
    lazyMapsAPILoaderConfig?: PropupLazyMapsAPILoaderConfigLiteral
  ): ModuleWithProviders<PropupAgmModule> {
    return {
      ngModule: PropupAgmModule,
      providers: [
        ...BROWSER_GLOBALS_PROVIDERS, { provide: PropupMapsAPILoader, useClass: PropupLazyMapsAPILoader },
        { provide: PROPUP_LAZY_MAPS_API_CONFIG, useValue: lazyMapsAPILoaderConfig },
      ],
    };
  }
}
