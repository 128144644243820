import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, retry, shareReplay } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { DocumentUploadInfo } from 'src/app/common-components/documents-management/documents-management.component';
import { ImageUploadInfo } from 'src/app/common-components/image-management/image-data-store.service';
import { UploadedImage } from 'src/app/common-models/image-upload';
import { FormArray, FormGroup } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';

interface FileMetadata {
  id: string;
  parentId: string;
  type: string;
}

@Injectable({ providedIn: 'root' })
export class DocumentService {
  constructor(
    private readonly apollo: Apollo,
    private readonly _http: HttpClient,
    private readonly _authService: AuthService,
  ) { }

  saveImage(payload: ImageUploadInfo): Observable<HttpEvent<object>> {
    const formData = this._buildFormData(payload, 'image', 'imagemetadata');
    const headers = this._buildHeaders();

    return this._http.post(`${environment.documentServiceBaseUrl}files/images`, formData, {
      headers,
      reportProgress: true,
      observe: 'events',
    }).pipe(
      shareReplay(),
      retry(2),
      catchError(error => {
        console.error('Error posting images')
        throw new Error(error)
      }),
    );
  }

  saveFile(payload: DocumentUploadInfo): Observable<HttpEvent<object>> {
    const formData = this._buildFormData(payload, 'file', 'filemetadata');
    const headers = this._buildHeaders();

    return this._http.post(`${environment.documentServiceBaseUrl}files/documents`, formData, {
      headers,
      reportProgress: true,
      observe: 'events',
    }).pipe(
      shareReplay(),
      retry(2),
      catchError(error => {
        console.error('Error posting images')
        throw new Error(error)
      }),
    );
  }

  deleteImages(storageUrls: string[]): Observable<object> {
    const headers = this._buildHeaders();

    return this._http.post(
      `${environment.documentServiceBaseUrl}files/delete-images`,
      { storageUrls },
      { headers }
    ).pipe(
      retry(2),
      catchError(error => {
        console.error('Error deleting images')
        throw new Error(error)
      })
    );
  }

  savePropertyRelatedDocument$(objectId: string, doc: any): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation SavePropertyRelatedDocument($objectId: String!, $doc: DocumentInfoInput!) {
          savePropertyRelatedDocument(objectId: $objectId, doc: $doc)
        }
      `,
      variables: {
        objectId,
        doc,
      },
    }).pipe(map((result: any) => result.data.savePropertyRelatedDocument));
  }

  openDocumentLink(url: string): void {
    window.open(url, '_blank');
  }

  private _buildFormData(doc: DocumentUploadInfo | ImageUploadInfo, fileFieldName: string, metadataFieldName: string): FormData {
    const metadata: FileMetadata[] = [{
      id: doc.id,
      parentId: doc.parentId ?? '',
      type: doc.type,
    }];

    const formData = new FormData();
    formData.append(fileFieldName, doc.data);
    formData.append(metadataFieldName, JSON.stringify(metadata));

    return formData;
  }

  private _buildHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer ${this._authService.accessToken}`);

    return headers;
  }

  updateCompletedUploads(
    docs: UploadedImage[],
    formArray: FormArray,
    removeControlNames: string[],
  ): void {
    docs.forEach((doc) => {
      const formGroup = formArray.controls.find((fg) => fg.value.id === doc.id);

      if (formGroup) {
        removeControlNames.forEach(name => {
          (formGroup as FormGroup).removeControl(name);
        });
        formGroup.patchValue({
          storageUrl: doc.storageUrl,
          uploadStatus: 'DONE',
        });
      }
    });
  }
}
