import {Injectable} from "@angular/core";
import {Apollo, gql} from "apollo-angular";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class MicrosoftService {
  constructor(private apollo: Apollo) {
  }

  checkTokenValidity$(): Observable<boolean> {
    return this.apollo.query({
      query: gql`
        query MicrosoftTokenValidity {
          microsoftTokenValidity
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }).pipe(map((response: any) => response.data.microsoftTokenValidity));
  }

}
