import { createSelector, ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as fromReducer from './reducer';

export interface ExclusiveAgreementFeatureState {
  exclusiveAgreement: fromReducer.State;
}

export const reducers: ActionReducerMap<ExclusiveAgreementFeatureState> = {
  exclusiveAgreement: fromReducer.reducer,
};

export const getExclusiveAgreementState = createFeatureSelector<fromReducer.State>('exclusive-agreement');

export const ExclusiveAgreementSelectors = {
  exclusiveAgreements: createSelector(getExclusiveAgreementState, fromReducer.getExclusiveAgreements),
  exclusiveAgreementsLoading: createSelector(getExclusiveAgreementState, fromReducer.getExclusiveAgreementsLoading),
  exclusiveAgreementDetails: createSelector(getExclusiveAgreementState, fromReducer.getExclusiveAgreementDetails),
  exclusiveAgreementDetailsLoading: createSelector(getExclusiveAgreementState, fromReducer.getExclusiveAgreementDetailsLoading),
};
