import { Component, Input } from '@angular/core';

@Component({
  selector: 'status-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss'],
})
export class StatusIndicatorComponent {
  @Input() isValid: boolean | null = false;
  @Input() link: string = '';
  @Input() name: string = '';
}
