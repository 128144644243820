import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable, TemplateRef } from '@angular/core';
import { ReplaySubject, Subject, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RightSidenavService {
  private _sidenavWidthChanged$$ = new Subject<void>();
  private _sidenavTriggerClose$$ = new ReplaySubject<void>();
  private _sidenavNavContent$$ = new ReplaySubject<TemplateRef<any> | null>();

  sidenavWidthChanged$ = this._sidenavWidthChanged$$.asObservable();
  sidenavContent$ = this._sidenavNavContent$$.asObservable();
  sidenavTriggerClose$ = this._sidenavTriggerClose$$.asObservable();

  isMobile = true;

  private readonly _subscription = new Subscription();

  constructor(
    private observer: BreakpointObserver,
  ) {
    this._subscription.add(
      this.observer.observe('(min-width: 1024px)').subscribe((result) => {
        this.isMobile = !result.matches;
      })
    );
  }

  toggleSidenav() {
    if (!this.isMobile) {
      this._sidenavWidthChanged$$.next();
    }
  }

  triggerClose(): void {
    if (this.isMobile) {
      this._sidenavTriggerClose$$.next();
    }
  }

  clearContent(): void {
    this._sidenavNavContent$$.next(null);
  }


  setContent(content: TemplateRef<any>) {
    this._sidenavNavContent$$.next(content);
  }
}
