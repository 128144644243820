import { Injectable } from "@angular/core";
import { CustomerWithContact } from "@domains/customer";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { catchError, first, tap } from "rxjs/operators";
import { AuthService } from "services/auth.service";
import { DocumentService } from "services/document.service";
import { getAgentCompany } from "src/app/common-models/mapping";
import { PlainAgreementApiFacade } from "./plain-agreement-api.facade";
import { PlainAgreement, PlainAgreementSignatureCreation } from "./plain-agreement.model";
import { PlainAgreementSelectors, PlainAgreementFeatureState } from "./store";
import { PlainAgreementActions } from "./store/action-types";
import { EmailPreviewBodyDto } from "@types";

@Injectable({ providedIn: 'root' })
export class PlainAgreementDataFacade {
  plainAgreements$ = this._store$.select(PlainAgreementSelectors.plainAgreements);
  plainAgreementsLoading$ = this._store$.select(PlainAgreementSelectors.plainAgreementsLoading);
  plainAgreementDetails$ = this._store$.select(PlainAgreementSelectors.plainAgreementDetails);
  plainAgreementDetailsLoading$ = this._store$.select(PlainAgreementSelectors.plainAgreementDetailsLoading);

  constructor(
    private readonly _authService: AuthService,
    private readonly _docService: DocumentService,
    private readonly _plainAgreementApiFacade: PlainAgreementApiFacade,
    private readonly _store$: Store<PlainAgreementFeatureState>
  ) { }

  public loadPlainAgreements(objectId: string): void {
    this._store$.dispatch(PlainAgreementActions.LoadPlainAgreements({ objectId }));
  }

  public loadPlainAgreementsByCustomer(customerId: string): void {
    this._store$.dispatch(PlainAgreementActions.LoadPlainAgreementsByCustomer({ customerId }));
  }

  public loadPlainAgreementDetails(objectId: string, plainAgreementId: string): void {
    this._store$.dispatch(PlainAgreementActions.LoadPlainAgreementDetails({ objectId, plainAgreementId }));
  }

  public savePlainAgreement(plainAgreement: any, isFinal = false): void {
    this._store$.dispatch(PlainAgreementActions.SavePlainAgreement({ plainAgreement, isFinal }));
  }

  public createPlainAgreement$(plainAgreement: any): Observable<PlainAgreement> {
    return this._plainAgreementApiFacade.savePlainAgreement$(plainAgreement)
  }

  public deletePlainAgreement(plainAgreementId: string, objectId: string): void {
    this._store$.dispatch(PlainAgreementActions.DeletePlainAgreement({ plainAgreementId, objectId }));
  }

  public getLastPlainAgreementDetails$(objectId: string): Observable<PlainAgreement> {
    return this._plainAgreementApiFacade.getLastPlainAgreementDetails$(objectId);
  }

  public getPlainAgreementDetails$(objectId: string, plainAgreementId: string): Observable<PlainAgreement> {
    return this._plainAgreementApiFacade.loadPlainAgreementDetails$(objectId, plainAgreementId);
  }

  public compressFiles$(objectId: string, plainAgreementId: string, filePaths: string[], fileNames: string[]): Observable<string> {
    return this._plainAgreementApiFacade.compressFiles$(objectId, plainAgreementId, filePaths, fileNames);
  }

  public updatePlainAgreementCustomers(
    contractEntities: CustomerWithContact[],
    plainAgreementId: string,
    objectId: string
  ): void {
    this._store$.dispatch(PlainAgreementActions.UpdatePlainAgreementCustomers({
      contractEntities,
      plainAgreementId,
      objectId,
    }));
  }

  public createPlainAgreementDocument$(objectId: string, plainAgreementId: string): Observable<string> {
    const agentCompany = getAgentCompany(this._authService);
    return this._plainAgreementApiFacade.createPlainAgreementDocument$(objectId, plainAgreementId, agentCompany);
  }

  public failedCreateAndUploadPlainAgreementDocument(): void {
    this._store$.dispatch(PlainAgreementActions.CreateAndUploadPlainAgreementDocumentFailed());
  }

  public showPlainAgreementDocument(objectId: string, plainAgreementId: string): void {
    this.createPlainAgreementDocument$(objectId, plainAgreementId).pipe(
      first(),
      tap((url) => {
        this._docService.openDocumentLink(url);
      }),
      catchError((err) => {
        this.failedCreateAndUploadPlainAgreementDocument();
        throw err;
      })
    ).subscribe()
  }

  public sendPlainAgreementEmail(objectId: string, plainAgreementId: string, emailData: any): void {
    const agentCompany = getAgentCompany(this._authService);
    this._store$.dispatch(PlainAgreementActions.SendPlainAgreementEmail({
      objectId,
      plainAgreementId,
      emailData,
      agentCompany,
    }));
  }

  public getPlainAgreementEmailVariables$(objectId: string, plainAgreementId: string, documentUrl: string): Observable<any> {
    return this._plainAgreementApiFacade.getPlainAgreementEmailVariables$(objectId, plainAgreementId, documentUrl);
  }

  public signAndCompletePlainAgreement(plainAgreementSignature: PlainAgreementSignatureCreation): void {
    this._store$.dispatch(PlainAgreementActions.SignAndCompletePlainAgreement({ plainAgreementSignature }));
  }

}
