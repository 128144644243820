import {
  createSelector,
  ActionReducerMap,
  createFeatureSelector,
} from '@ngrx/store';
import * as fromReducer from './reducer';

export interface PreListingFeatureState {
  preListing: fromReducer.State;
}

export const reducers: ActionReducerMap<PreListingFeatureState> = {
  preListing: fromReducer.reducer,
};

export const getPreListingState =
  createFeatureSelector<fromReducer.State>('pre-listing');

export const preListingSelectors = {
  preListingLoading: createSelector(
    getPreListingState,
    fromReducer.getPreListingLoading
  ),
};
