import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphQLModule } from 'services/graphql.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as fromReducer from './store/reducer';
import { CustomerEffects } from './store/effects';

@NgModule({
  imports: [
    CommonModule,
    GraphQLModule,
    EffectsModule.forFeature([CustomerEffects]),
    StoreModule.forFeature('customer', fromReducer.reducer),
  ],
})
export class CustomerDomainModule { }
