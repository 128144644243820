import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { Observable } from "rxjs";
import { RentalContract } from "./rental-contract.model";
import { first, map, switchMap } from "rxjs/operators";
import { AgentProfile } from "../../common-models/agent-profile";
import { AgentCompany } from "../../common-models/agent-company";

@Injectable({ providedIn: 'root' })
export class RentalContractApiFacade {

  constructor(private readonly apollo: Apollo) { }

  saveRentalContract$(data: any): Observable<RentalContract> {
    return this.apollo
      .mutate({
        mutation: gql`
        mutation SaveRentalContract($rentalContract: RentalContractInput) {
          rentalContract(rentalContract: $rentalContract) {
            id
            objectId
            state
            lastVisited
            condominiumOwnership {
              condominiumOwnership
            }
            rentalContractDetails {
              moreThanTwoProperties
              buildingPermit1945
              buildingPermit1953
              fundedConstruction
              historicPreservation
              propertyCategory
              rentalWithin6Months
              goodLocation
              goodLocationDescription
            }
            rentalDuration {
              rentalDuration
              rentalStart
              rentalDurationInYears
            }
            terminationRight {
              prematureTermination
              contractPartiesTermination
              terminationStart
              terminationDuration
            }
            rentPrice {
              landlordVAT
              rentPrice
              parkingSlotPrice
              indexProtection
              indexPercentage
            }
            operatingExpenses {
              expenseType
              operatingExpenses
            }
            rentMisc {
              obligationsTenant {
                snowRemoval
                lawnMowing
                gardenCare
                repairDevices
                serviceGasBoiler
                cleaningStairway
              }
              subleaseBan
              rentItem
              usageRules
              gotHouseRules
              additionalNotes
            }
            rentBilling {
              obligationContractCosts
            }
            rentContractDeposit {
              depositMethod
              amount
            }
            rentPropertyData {
              description
              properties {
                cadastralCommunity {
                  KGNr
                  KGName
                  PGNr
                  PolName
                }
                entryNumber
                parcelNumbers
                top
                stake
                bLnr
                parkingSlot {
                  top
                  stake
                  bLnr
                }
              }
            }
            contractTenants {
              customerId
            }
            contractLandlords {
              customerId
              shares {
                stake
                bLnr
              }
            }
            statusCreation {
              postId
              contractId
              success
              contract
            }
            rentalContractDocUrls {
              status
              infoDocLandlord
              infoDocTenant
              rankOrder
              contract
            }
          }
        }
      `,
        variables: {
          rentalContract: data,
        },
      })
      .pipe(
        first(),
        map((result: any) => ({
          ...result.data.rentalContract,
          lastVisited: new Date(result.data.rentalContract?.lastVisited),
        }))
      );
  }

  loadRentalContracts$(objectId: string): Observable<RentalContract[]> {
    return this.apollo.query({
      query: gql`
        query GetRentalContracts($objectId: String!) {
          rentalContracts(objectId: $objectId) {
            id
            objectId
            state
            lastVisited
            contractTenants {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
            }
            contractLandlords {
              customerId
              title
              firstname
              lastname
              contact {
                email
              }
              shares {
                stake
                bLnr
              }
            }
            creator {
              isCreatedByMe
              name
              email
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
      },
    }).pipe(first(), map((result: any) => result.data.rentalContracts));
  }

  loadRentalContractsByCustomer$(customerId: string): Observable<RentalContract[]> {
    return this.apollo.query({
      query: gql`
        query GetRentalByCustomer($customerId: String!) {
          rentalContractsByCustomer(customerId: $customerId) {
            id
            objectId
            state
            lastVisited
            contractTenants {
                customerId
                birthdate
                isConsumer
                title
                firstname
                lastname
                nationality
                contact {
                    address {
                        country
                        city
                        zipCode
                        streetName
                        buildingNumber
                        doorNumber
                        stairway
                        longitude
                        latitude
                    }
                    phone
                    email
                }
                pep {
                    isPep
                    wasPepLastYear
                    isFamilyMemberPep
                    isKnownToBeCloseToPep
                    firstName
                    lastName
                }
                moneyLaundering {
                  askForMoneyLaundering
                  residentPerson
                  investmentIncome
                  estateSales
                  endowment
                  inheritance
                  savings
                  employment
                  investments
                  pension
                  loan
                  other
                  otherDetails
                }
                IDImages {
                    id
                    storageUrl
                    type
                    description
                }
            }
            contractLandlords {
              customerId
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              shares {
                stake
                bLnr
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
              moneyLaundering {
                askForMoneyLaundering
                residentPerson
                investmentIncome
                estateSales
                endowment
                inheritance
                savings
                employment
                investments
                pension
                loan
                other
                otherDetails
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        customerId: customerId,
      },
    }).pipe(first(), map((result: any) => result.data.rentalContractsByCustomer));
  }

  loadRentalContractDetails$(objectId: string, rentalContractId: string): Observable<RentalContract> {
    return this.apollo.query({
      query: gql`
        query GetRentalContractById($objectId: String!, $rentalContractId: String!) {
          rentalContract(objectId: $objectId, rentalContractId: $rentalContractId) {
            id
            objectId
            state
            lastVisited
            condominiumOwnership {
              condominiumOwnership
            }
            rentalContractDetails {
              moreThanTwoProperties
              buildingPermit1945
              buildingPermit1953
              fundedConstruction
              historicPreservation
              propertyCategory
              rentalWithin6Months
              goodLocation
              goodLocationDescription
            }
            rentalDuration {
              rentalDuration
              rentalStart
              rentalDurationInYears
            }
            terminationRight {
              prematureTermination
              contractPartiesTermination
              terminationStart
              terminationDuration
            }
            rentPrice {
              landlordVAT
              rentPrice
              parkingSlotPrice
              indexProtection
              indexPercentage
            }
            operatingExpenses {
              expenseType
              operatingExpenses
            }
            rentMisc {
              obligationsTenant {
                snowRemoval
                lawnMowing
                gardenCare
                repairDevices
                serviceGasBoiler
                cleaningStairway
              }
              subleaseBan
              rentItem
              usageRules
              gotHouseRules
              additionalNotes
            }
            rentBilling {
              obligationContractCosts
            }
            rentContractDeposit {
              depositMethod
              amount
            }
            rentPropertyData {
              description
              properties {
                cadastralCommunity {
                  KGNr
                  KGName
                  PGNr
                  PolName
                }
                entryNumber
                parcelNumbers
                top
                stake
                bLnr
                parkingSlot {
                  top
                  stake
                  bLnr
                }
              }
            }
            contractTenants {
              customerId
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              isConsumer
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
              moneyLaundering {
                askForMoneyLaundering
                residentPerson
                investmentIncome
                estateSales
                endowment
                inheritance
                savings
                employment
                investments
                pension
                loan
                other
                otherDetails
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
            }
            contractLandlords {
              customerId
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              shares {
                stake
                bLnr
              }
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
              moneyLaundering {
                askForMoneyLaundering
                residentPerson
                investmentIncome
                estateSales
                endowment
                inheritance
                savings
                employment
                investments
                pension
                loan
                other
                otherDetails
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
            }
            statusCreation {
              postId
              contractId
              success
              contract
            }
            rentalContractDocUrls {
              status
              infoDocLandlord
              infoDocTenant
              rankOrder
              contract
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        rentalContractId: rentalContractId,
      },
    }).pipe(first(), map((result: any) => result.data.rentalContract));
  }

  deleteRentalContract$(rentalContractId: string): Observable<Boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation DeleteRentalContract($rentalContractId: String!) {
          deleteRentalContract(rentalContractId: $rentalContractId)
        }
      `,
      variables: {
        rentalContractId,
      },
    }).pipe(
      map((result: any) => result.data.deleteRentalContract)
    );
  }

  orderRentalContract$(
    objectId: string,
    rentalContractId: string,
    agentProfile: AgentProfile,
    agentCompany: AgentCompany,
  ): Observable<string> {
    const getRentalOrderDocumentUrls = (contractTenants: any[], contractLandlords: any[]) => this.apollo
      .query({
        query: gql`
            query OrderRentalContract(
              $objectId: String!
              $rentalContractId: String!
              $contractTenants: [ContractTenantsWithCustomerDataInput!]
              $contractLandlords: [ContractLandlordsWithCustomerDataInput!]
              $agentProfile: AgentProfile!
              $agentCompany: AgentCompany!
            ) {
              orderRentalContract(
                objectId: $objectId
                rentalContractId: $rentalContractId
                contractTenants: $contractTenants
                contractLandlords: $contractLandlords
                agentProfile: $agentProfile
                agentCompany: $agentCompany
              ) {
                postId
                contractID
                success
              }
            }
        `,
        fetchPolicy: 'no-cache',
        variables: {
          objectId: objectId,
          rentalContractId: rentalContractId,
          contractTenants: contractTenants,
          contractLandlords: contractLandlords,
          agentProfile: agentProfile,
          agentCompany: agentCompany,
        },
      }).pipe(first(), map((result: any) => result.data.rentalContractDocument));

    return this.getTenantsAndLandlordsFormRentalContractById$(objectId, rentalContractId).pipe(
      switchMap((rentalContract) => getRentalOrderDocumentUrls(rentalContract.contractTenants, rentalContract.contractLandlords))
    );
  }

  private getTenantsAndLandlordsFormRentalContractById$(objectId: string, rentalContractId: string): Observable<RentalContract> {
    return this.apollo.query({
      query: gql`
        query GetRentalContractById($objectId: String!, $rentalContractId: String!) {
          rentalContract(objectId: $objectId, rentalContractId: $rentalContractId) {
            id
            objectId
            contractTenants {
              customerId
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
              moneyLaundering {
                askForMoneyLaundering
                residentPerson
                investmentIncome
                estateSales
                endowment
                inheritance
                savings
                employment
                investments
                pension
                loan
                other
                otherDetails
              }
            }
            contractLandlords {
              customerId
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              shares {
                stake
                bLnr
              }
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
              moneyLaundering {
                askForMoneyLaundering
                residentPerson
                investmentIncome
                estateSales
                endowment
                inheritance
                savings
                employment
                investments
                pension
                loan
                other
                otherDetails
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
        rentalContractId: rentalContractId,
      },
    }).pipe(first(), map((result: any) => result.data.rentalContract));
  }

}
