import { CustomerWithContact } from '@domains/customer';
import { createAction, props } from '@ngrx/store';
import {PlainAgreement, PlainAgreementSignatureCreation} from '../plain-agreement.model';
import {AgentCompany} from "../../../common-models/agent-company";

export const LoadPlainAgreements = createAction('[PlainAgreement] Load Plain Agreements', props<{ objectId: string }>());
export const LoadPlainAgreementsByCustomer = createAction('[PlainAgreement] Load Plain Agreements By Customer', props<{ customerId: string}>());
export const PlainAgreementsLoaded = createAction('[PlainAgreement] Plain Agreements Loaded', props<{ payload: PlainAgreement[] }>());
export const PlainAgreementsLoadingFailed = createAction('[PlainAgreement] Plain Agreements Loading Failed');

export const LoadPlainAgreementDetails = createAction('[PlainAgreement] Load Plain Agreement Details', props<{
  objectId: string;
  plainAgreementId: string;
}>());
export const PlainAgreementDetailsLoaded = createAction('[PlainAgreement] Plain Agreement Details Loaded', props<{ payload: PlainAgreement }>());
export const PlainAgreementDetailsLoadingFailed = createAction('[PlainAgreement] Plain Agreement Details Loading Failed');

export const SavePlainAgreement = createAction('[PlainAgreement] Save Plain Agreement', props<{ plainAgreement: PlainAgreement; isFinal?: boolean }>());

export const CreatePlainAgreementSucceded = createAction('[PlainAgreement] Succeeded to create and save Plain Agreement');

export const DeletePlainAgreement = createAction('[PlainAgreement] Delete Plain Agreement', props<{ plainAgreementId: string, objectId: string }>());
export const DeletePlainAgreementSuccess = createAction('[PlainAgreement] Delete Plain Agreement Succeeded', props<{ plainAgreementId: string }>());
export const DeletePlainAgreementFailed = createAction('[PlainAgreement] Delete Plain Agreement Failed', props<{ plainAgreementId: string }>());

export const UpdatePlainAgreementCustomers = createAction(
  '[PlainAgreement] Update Plain Agreement Customers',
  props<{
    contractEntities: CustomerWithContact[];
    plainAgreementId: string;
    objectId: string;
  }>()
);

export const CreateAndUploadPlainAgreementDocumentFailed = createAction('[PlainAgreement] Failed to create and upload Plain Agreement document');

export const SendPlainAgreementEmail = createAction(
  '[PlainAgreement] Send Plain Agreement Email',
  props<{
    objectId: string;
    plainAgreementId: string;
    emailData: any;
    agentCompany: AgentCompany;
  }>()
);

export const SignAndCompletePlainAgreement = createAction(
  '[PlainAgreement] Sign and Complete Plain Agreement',
  props<{ plainAgreementSignature: PlainAgreementSignatureCreation}>()
);
