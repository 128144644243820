import {Injectable} from '@angular/core';
import {Apollo, gql} from "apollo-angular";
import {Observable, throwError} from "rxjs";
import {Profile} from "../../store/profile/profile.reducers";
import {catchError, first, map} from "rxjs/operators";
import {failedToLoadProfile} from "../../store/profile/profile.actions";
import {cleanTypenames} from "services/cleanup";

@Injectable({ providedIn: 'root' })
export class SettingsApiFacade {

  constructor(private readonly apollo: Apollo) {
  }

  loadUserProfile$(): Observable<Profile> {
    return this.apollo
    .query({
      query: gql`
        query LoadUserProfile {
          profile {
            id
            givenName
            familyName
            email
            isRemax
            themeConfig
            legalEntity
            officeName
            phone
            officeAddress
            companyLogo
            googleReviewLink
            createdTimeStamp
            companyInformation {
              legalEntity
              officeName
              officeAddress
              taxNumber
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
    })
    .pipe(first(), map((result: any) => result.data.profile));
  }

}
