import { Injectable } from '@angular/core';
import { CustomerDataFacade } from '@domains/customer';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import {
  globalStateHasBeenLoaded,
  initializeCountries,
  initializeGlobalState,
} from './global.actions';

@Injectable()
export class GlobalEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _customerDataFacade: CustomerDataFacade,
  ) { }

  initializeGlobalState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(initializeGlobalState),
      mergeMap((_) => forkJoin([this._customerDataFacade.getCountries$()])),
      switchMap(([countries]) => [
        initializeCountries({ countries }),

        globalStateHasBeenLoaded(),
      ])
    )
  );
}
