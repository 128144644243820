import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';

import { AgentCompany } from 'src/app/common-models/agent-company';
import { AgentProfile } from 'src/app/common-models/agent-profile';
import { preListingSelectors, PreListingFeatureState } from './store';
import { PreListingActions } from './store/action-types';

@Injectable({ providedIn: 'root' })
export class PreListingDataFacade {
  preListingLoading$ = this._store$.select(preListingSelectors.preListingLoading);

  constructor(private readonly _store$: Store<PreListingFeatureState>) { }

  public createPreListing(
    objectId: string,
    agentProfile: AgentProfile,
    agentCompany: AgentCompany
  ): void {
    this._store$.dispatch(PreListingActions.CreatePreListing({
      objectId,
      agentProfile,
      agentCompany,
    }));
  }
}
