import {MomentDateAdapter} from "@angular/material-moment-adapter";
import moment from "moment";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class PropupDateAdapter extends MomentDateAdapter {
  override getFirstDayOfWeek(): number {
    return 1;
  }

override format(date: moment.Moment | null, displayFormat: string): string {
    if (!date) return '';
    return date.format('DD.MM.YYYY');
  }

}
