import { Action, createReducer, on } from '@ngrx/store';
import { BuyingOffer } from '../buying-offer.model';
import * as DataActions from './actions';

export interface State {
  buyingOffers: {
    data: BuyingOffer[];
    loading: boolean;
  };
  buyingOfferDetails: {
    data: BuyingOffer | null;
    loading: boolean;
  };
}

export const initialState: State = {
  buyingOffers: {
    data: [],
    loading: false,
  },
  buyingOfferDetails: {
    data: null,
    loading: false,
  },
};

const buyingOfferReducer = createReducer(
  initialState,

  on(DataActions.LoadBuyingOffers, DataActions.LoadBuyingOffersByCustomer, (state) => ({
    ...state,
    buyingOffers: {
      loading: true,
      data: [],
    }
  })),
  on(DataActions.BuyingOffersLoaded, (state, { payload }) => ({
    ...state,
    buyingOffers: {
      loading: false,
      data: payload,
    }
  })),
  on(DataActions.BuyingOffersLoadingFailed, (state) => ({
    ...state,
    buyingOffers: {
      ...state.buyingOffers,
      loading: false,
    },
  })),
  on(DataActions.LoadBuyingOfferDetails, (state) => ({
    ...state,
    buyingOfferDetails: {
      loading: true,
      data: null,
    },
  })),
  on(DataActions.BuyingOfferDetailsLoaded, (state, { payload }) => ({
    ...state,
    buyingOfferDetails: {
      loading: false,
      data: payload,
    },
  })),
  on(DataActions.BuyingOfferDetailsLoadingFailed, (state) => ({
    ...state,
    buyingOfferDetails: {
      ...state.buyingOfferDetails,
      loading: false,
    },
  })),
  on(DataActions.DeleteBuyingOfferSuccess, (state, { buyingOfferId }) => ({
    ...state,
    buyingOffers: {
      ...state.buyingOffers,
      data: state.buyingOffers.data.filter(buyingOffer => buyingOffer.id !== buyingOfferId),
    },
  })),
);

export const reducer = (state: State | undefined, action: Action): State => buyingOfferReducer(state, action);

export const getBuyingOffers = (state: State): BuyingOffer[] => state.buyingOffers.data;
export const getBuyingOffersLoading = (state: State): boolean => state.buyingOffers.loading;
export const getBuyingOfferDetails = (state: State): BuyingOffer | null => state.buyingOfferDetails.data;
export const getBuyingOfferDetailsLoading = (state: State): boolean => state.buyingOfferDetails.loading;
