import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable, Subscription, timer } from 'rxjs';
import { map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { SnackbarService } from './snackbar.service';

export enum JobState {
  Completed = 'completed',
  Failed = 'failed',
}

const CUSTOMERS_SYNC_JOB_KEY = 'CUSTOMERS_SYNC_JOB_KEY';
const JOB_FINISH_STATES = [JobState.Completed, JobState.Failed];

@Injectable({
  providedIn: 'root',
})
export class EdirealService {
  isCustomerSyncInProgress = false;

  constructor(private apollo: Apollo, private _snackbar: SnackbarService) { }

  checkTokenValidity(): Observable<boolean> {
    return this.apollo.query({
      query: gql`
        query EdirealTokenValidity {
          edirealTokenValidity
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }).pipe(map((response: any) => response.data.edirealTokenValidity));
  }

  getEdirealAgentData(): Observable<string> {
    return this.apollo.query({
      query: gql`
        query EdirealAgentData {
          edirealAgentData
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }).pipe(map((response: any) => response.data.edirealAgentData));
  }

  triggerCustomersSync(): Observable<any> {
    return this.apollo
      .mutate({
        mutation: gql`
          mutation TriggerEdirealCustomersSync {
            triggerEdirealCustomersSync {
              entityType
              data {
                success
                error
                jobId
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      })
      .pipe(
        map((response: any) => response.data.triggerEdirealCustomersSync),
        tap((response: any) => {
          if (response?.data?.jobId) {
            this.isCustomerSyncInProgress = true;
            this._saveSyncCustomersJobId(response.data.jobId);
            this.checkCustomersSync();
          }

          if (response.data.error) {
            this._showSyncCustomersSnackbar(false);
          }
        })
      );
  }

  checkCustomersSync(): Subscription | void {
    const jobId = this._getSyncCustomersJobId();

    if (jobId) {
      const minute = 1000 * 60;

      return timer(0, minute).pipe(
        switchMap(() => this._getCustomersSyncState(jobId)),
        tap((state: JobState) => {
          if (JOB_FINISH_STATES.includes(state) || !state) {
            this._saveSyncCustomersJobId('', true);
            this.isCustomerSyncInProgress = false;

            if (state === JobState.Completed) {
              this._showSyncCustomersSnackbar(true);
            } else if (state === JobState.Failed) {
              this._showSyncCustomersSnackbar(false);
            }
          } else {
            this.isCustomerSyncInProgress = true;
          }
        }),
        takeWhile((value) => !JOB_FINISH_STATES.includes(value)),
      ).subscribe();
    }
  }

  private _saveSyncCustomersJobId(jobId: string, remove: boolean = false): void {
    if (remove) {
      localStorage.removeItem(CUSTOMERS_SYNC_JOB_KEY);
      return;
    }

    localStorage.setItem(CUSTOMERS_SYNC_JOB_KEY, jobId);
  }

  private _getSyncCustomersJobId(): string {
    return localStorage.getItem(CUSTOMERS_SYNC_JOB_KEY) as string;
  }

  private _showSyncCustomersSnackbar(success: boolean): void {
    if (success) {
      const successMessage = 'Kundenliste erfolgreich aktualisiert';
      this._snackbar.showSnackbar(successMessage, 'mat-primary', true);
    } else {
      const errorMessage = 'Kundenliste konnte nicht aktualisiert werden';
      this._snackbar.showSnackbar(errorMessage, 'mat-warn', true);
    }
  }

  private _getCustomersSyncState(jobId: string): Observable<JobState> {
    return this.apollo.query({
      query: gql`
        query CustomersSyncJobState($jobId: String!) {
          customersSyncJobState(jobId: $jobId)
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        jobId,
      }
    }).pipe(map((response: any) => response.data.customersSyncJobState));
  }
}
