import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { GraphQLModule } from "services/graphql.module";
import { PlainAgreementEffects } from "./store/effects";
import * as fromReducer from './store/reducer';

@NgModule({
  imports: [
    CommonModule,
    GraphQLModule,
    EffectsModule.forFeature([PlainAgreementEffects]),
    StoreModule.forFeature('plain-agreement', fromReducer.reducer)
  ],
})
export class PlainAgreementDomainModule { }
