import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap, tap,
} from 'rxjs/operators';

import { SnackbarService } from 'services/snackbar.service';
import { ExclusiveAgreementApiFacade } from '../exclusive-agreement-api.facade';
import * as DataActions from './actions';
import { ContractEntity } from '../exclusive-agreement.model';
import { getAgentCompany } from "../../../common-models/mapping";
import { AuthService } from "services/auth.service";
import { EMPTY } from 'rxjs';
import { DocumentsDataFacade } from "../../documents";

@Injectable()
export class ExclusiveAgreementEffects {
  public loadExclusiveAgreements$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadExclusiveAgreements),
      switchMap(({ objectId }) =>
        this._exclusiveAgreementApiFacade
          .loadExclusiveAgreement$(objectId)
          .pipe(
            switchMap((value) => [
              DataActions.ExclusiveAgreementsLoaded({ payload: value }),
            ]),
            catchError(() => [
              DataActions.ExclusiveAgreementDetailsLoadingFailed(),
            ])
          )
      )
    )
  );

  public loadExclusiveAgreementsByCustomer$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadExclusiveAgreementsByCustomer),
      switchMap(({ customerId }) =>
        this._exclusiveAgreementApiFacade.loadExclusiveAgreementsByCustomer$(customerId).pipe(
          switchMap((value) => [DataActions.ExclusiveAgreementsLoaded({ payload: value })]),
          catchError(() => [DataActions.ExclusiveAgreementsLoadingFailed()])
        )
      )
    )
  );

  public loadExclusiveAgreementDetails$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.LoadExclusiveAgreementDetails),
      switchMap(({ objectId, exclusiveAgreementId }) =>
        this._exclusiveAgreementApiFacade
          .loadExclusiveAgreementDetails$(objectId, exclusiveAgreementId)
          .pipe(
            switchMap((value) => [
              DataActions.ExclusiveAgreementDetailsLoaded({ payload: value }),
            ]),
            catchError(() => [
              DataActions.ExclusiveAgreementDetailsLoadingFailed(),
            ])
          )
      )
    )
  );

  public saveExclusiveAgreement$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SaveExclusiveAgreement),
      switchMap(({ exclusiveAgreement, isFinal }) => {
        const contractEntitiesWithoutCustomers =
          exclusiveAgreement.contractEntities.map(
            (contractEntity: ContractEntity) => {
              const {
                id,
                title,
                firstname,
                lastname,
                birthdate,
                contact,
                nationality,
                pep,
                ...contractEntitiesWithoutCustomers
              } = contractEntity;
              return {
                ...contractEntitiesWithoutCustomers,
                customerId: (contractEntity as any).customerId,
              };
            }
          );

        const {
          signatureData,
          ...exclusiveAgreementWithoutSignature
        } = exclusiveAgreement;

        const exclusiveAgreementWithoutCustomers = {
          ...exclusiveAgreementWithoutSignature,
          contractEntities: contractEntitiesWithoutCustomers,
        };

        return this._exclusiveAgreementApiFacade
          .saveExclusiveAgreement$(exclusiveAgreementWithoutCustomers)
          .pipe(
            switchMap((ea) => {
              if (isFinal) {
                this._router.navigateByUrl(`/objects/${ea.objectId}/object-recordings`);

                return [DataActions.CreateExclusiveAgreementSucceded()];
              }
              return [];
            })
          );
      })
    )
  );

  public exclusiveAgreementCreationSucceded$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(DataActions.CreateExclusiveAgreementSucceded),
        map(() =>
          this._snackbarService.showSnackbar(
            'Vermittlungsauftrag erfolgreich erstellt',
            'mat-primary',
            true
          )
        )
      ),
    { dispatch: false }
  );

  public updateExclusiveAgreementCustomers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.UpdateExclusiveAgreementCustomers),
      switchMap(({ objectId, exclusiveAgreementId, contractEntities }) =>
        this._exclusiveAgreementApiFacade
          .loadExclusiveAgreementDetails$(objectId, exclusiveAgreementId)
          .pipe(
            switchMap((exclusiveAgreement) => {
              exclusiveAgreement.contractEntities.map((c, index) => ({
                ...c,
                customerId: contractEntities[index]?.id,
              }));
              exclusiveAgreement.contractEntities =
                exclusiveAgreement.contractEntities.map((c, index) => ({
                  ...c,
                  customerId: contractEntities[index]?.id,
                }));

              return [
                DataActions.SaveExclusiveAgreement({
                  exclusiveAgreement,
                  isFinal: true,
                }),
              ];
            })
          )
      )
    )
  );

  deleteExclusiveAgreement$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.DeleteExclusiveAgreement),
      mergeMap(({ exclusiveAgreementId, objectId }) =>
        this._exclusiveAgreementApiFacade
          .deleteExclusiveAgreement$(exclusiveAgreementId)
          .pipe(
            switchMap(() => {
              this._snackbarService.showSnackbar(
                'Vermittlungsauftrag erfolgreich gelöscht',
                'mat-primary',
                true
              );
              this._documentDataFacade.deleteExclusiveAgreement(exclusiveAgreementId)
              return [
                DataActions.DeleteExclusiveAgreementSuccess({
                  exclusiveAgreementId,
                }),
              ];
            }),
            catchError(() => {
              this._snackbarService.showSnackbar(
                'Vermittlungsauftrag konnte nicht gelöscht werden',
                'mat-warn',
                true
              );
              return [
                DataActions.DeleteExclusiveAgreementFailed({
                  exclusiveAgreementId,
                }),
              ];
            })
          )
      )
    )
  );

  public createAndUploadExclusiveAgreementDocumentFailed$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.CreateAndUploadExclusiveAgreementDocumentFailed),
      map(() =>
        this._snackbarService.showSnackbar(
          'Vermittlungsauftrag PDF konnte nicht erzeugt werden',
          'mat-warn',
          true
        )
      )
    ),
    { dispatch: false }
  );

  public trySignAndCompleteExclusiveAgreement$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SignAndCompleteExclusiveAgreement),
      mergeMap(({ exclusiveAgreementSignature }) =>
        this._exclusiveAgreementApiFacade.signAndCompleteExclusiveAgreement$(
          exclusiveAgreementSignature.objectId,
          exclusiveAgreementSignature.exclusiveAgreementId,
          exclusiveAgreementSignature.contractEntitiesSignatureData,
          getAgentCompany(this._authService)
        ).pipe(
          tap((exclusiveAgreement) => {
            this._router.navigateByUrl(
              `/objects/${exclusiveAgreement.objectId}/object-recordings/exclusive-agreement/${exclusiveAgreement.id}/email`
            );
          }),
          catchError(() => {
            this._router.navigateByUrl(`/objects/${exclusiveAgreementSignature.objectId}/object-recordings/exclusive-agreement`);

            return EMPTY;
          })
        )),
    ),
    { dispatch: false }
  );

  public sendExclusiveAgreementEmail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendExclusiveAgreementEmail),
      switchMap(({ exclusiveAgreementId, emailData, agentCompany }) =>
        this._exclusiveAgreementApiFacade.sendExclusiveAgreementEmail$(
          exclusiveAgreementId,
          emailData,
          agentCompany
        ).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              'Vermittlungsauftrag erfolgreich gesendet',
              'mat-primary',
              true
            );
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Vermittlungsauftrag E-Mail konnte nicht gesendet werden',
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  public sendExclusiveAgreementOwnerPortalEmail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(DataActions.SendExclusiveAgreementOwnerPortalEmail),
      switchMap(({ exclusiveAgreementId, emailData, agentCompany }) =>
        this._exclusiveAgreementApiFacade.sendExclusiveAgreementOwnerPortalEmail$(
          exclusiveAgreementId,
          emailData,
          agentCompany
        ).pipe(
          tap(() => {
            this._snackbarService.showSnackbar(
              'Abgeberportal erfolgreich gesendet',
              'mat-primary',
              true
            );
          }),
          catchError(() => {
            this._snackbarService.showSnackbar(
              'Abgeberportal E-Mail konnte nicht gesendet werden',
              'mat-warn',
              true
            );

            return [];
          })
        )
      )
    ),
    { dispatch: false }
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _router: Router,
    private readonly _authService: AuthService,
    private readonly _snackbarService: SnackbarService,
    private readonly _exclusiveAgreementApiFacade: ExclusiveAgreementApiFacade,
    private readonly _documentDataFacade: DocumentsDataFacade,
  ) { }
}
