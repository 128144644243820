import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {GraphQLModule} from "services/graphql.module";
import {StoreModule} from "@ngrx/store";
import * as fromReducer from './store/reducer';
import {EffectsModule} from "@ngrx/effects";
import {SettingsEffects} from "./store/effects";

@NgModule({
  imports: [
    CommonModule,
    GraphQLModule,
    EffectsModule.forFeature([SettingsEffects]),
    StoreModule.forFeature('settings-domain', fromReducer.reducer)
  ],
})
export class SettingsDomainModule {}
