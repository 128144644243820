import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { CustomerPortal, CreateCustomerPortal, CustomerPortalAgent, CustomerPortalFeedback } from './customer-portal.model';

@Injectable({ providedIn: 'root' })
export class CustomerPortalApiFacade {
  constructor(private readonly apollo: Apollo) { }

  saveCustomerPortal$(data: CreateCustomerPortal): Observable<CustomerPortal> {
    return this.apollo.mutate({
      mutation: gql`
        mutation SaveCustomerPortal($data: CreateCustomerPortal!) {
          customerPortal(data: $data) {
            id
            objectId
            creationDate
            lastVisited
          }
        }
      `,
      variables: {
        data,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.customerPortal),
      map((cp: any) => ({
        ...cp,
        lastVisited: new Date(cp.lastVisited),
        creationDate: new Date(cp.creationDate),
      })),
    );
  }

  getTeamCustomerPortalAgents$(): Observable<CustomerPortalAgent[]> {
    return this.apollo.query({
      query: gql`
        query GetTeamCustomerPortalAgents {
          teamCustomerPortalAgents {
            id
            email
            isRemax
            themeConfig
            firstName
            lastName
            fullname
            picture
            officeName
            officeAddress
            legalEntity
            phone
            companyInformation {
              legalEntity
              officeName
              officeAddress
              taxNumber
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
    }).pipe(first(), map((result: any) => result.data.teamCustomerPortalAgents));
  }

  loadCustomerPortalDetails$(objectId: string, id: string): Observable<CustomerPortal> {
    return this.apollo.query({
      query: gql`
        query getCustomerPortalDetails($objectId: String!, $id: String!) {
          customerPortal(objectId: $objectId, id: $id) {
            id
            objectId
            agent {
              id
              email
              isRemax
              themeConfig
              firstName
              lastName
              picture
              officeName
              officeAddress
              legalEntity
              phone
              companyInformation {
                legalEntity
                officeName
                officeAddress
                taxNumber
              }
            }
            customerType
            customers {
              id
              birthdate
              isConsumer
              title
              firstname
              lastname
              nationality
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                  longitude
                  latitude
                }
                phone
                email
              }
              pep {
                isPep
                wasPepLastYear
                isFamilyMemberPep
                isKnownToBeCloseToPep
                firstName
                lastName
              }
              moneyLaundering {
                askForMoneyLaundering
                residentPerson
                investmentIncome
                estateSales
                endowment
                inheritance
                savings
                employment
                investments
                pension
                loan
                other
                otherDetails
              }
              IDImages {
                id
                storageUrl
                type
                description
              }
            }
            businessCustomer {
              id
              companyNumber
              companyRegister
              contact {
                address {
                  country
                  city
                  zipCode
                  streetName
                  buildingNumber
                  doorNumber
                  stairway
                }
                phone
                email
              }
              customers {
                id
                birthdate
                title
                firstname
                lastname
                nationality
                contact {
                  address {
                    country
                    city
                    zipCode
                    streetName
                    buildingNumber
                    doorNumber
                    stairway
                    longitude
                    latitude
                  }
                  phone
                  email
                }
                pep {
                  isPep
                  wasPepLastYear
                  isFamilyMemberPep
                  isKnownToBeCloseToPep
                  firstName
                  lastName
                }
                moneyLaundering {
                  askForMoneyLaundering
                  residentPerson
                  investmentIncome
                  estateSales
                  endowment
                  inheritance
                  savings
                  employment
                  investments
                  pension
                  loan
                  other
                  otherDetails
                }
                IDImages {
                  id
                  storageUrl
                  type
                  description
                }
              }
            }
            lastVisited
            creationDate
            rentalOfferSettings {
              canSubmitRentalOffer
              mustHaveUploadsIndividual {
                title
                description
                mandatory
              }
              mustFillCustomerQuestionnaireIndividual
            }
            buyingOfferSettings {
              canSubmitBuyingOffer
              mustHaveUploadsIndividual {
                title
                description
                mandatory
              }
            }
            viewingPassSettings {
              canSubmitViewingPass
            }
            individualSettings {
              hasFinanceCalculatorAccess
              hasDocumentAccess
              documents {
                id
                storageUrl
                type
                description
                title
                documentFrom
                state
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: { objectId, id },
    }).pipe(
      first(),
      map((result: any) => result.data.customerPortal),
      map((cp: any) => ({
        ...cp,
        lastVisited: new Date(cp.lastVisited),
        creationDate: new Date(cp.creationDate),
      })),
    );
  }

  loadCustomerPortals$(objectId: string): Observable<CustomerPortal[]> {
    return this.apollo.query({
      query: gql`
        query GetCustomerPortals($objectId: String!) {
          customerPortals(objectId: $objectId) {
            id
            objectId
            lastVisited
            creationDate
            customerType
            customers {
              id
              title
              firstname
              lastname
              contact {
                email
              }
            }
            businessCustomer {
              id
              companyNumber
              companyRegister
              customers {
                id
                title
                firstname
                lastname
                contact {
                  email
                }
              }
            }
            object {
              objectStatus
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
            }
            rentalOfferSettings {
              canSubmitRentalOffer
              mustHaveUploadsIndividual {
                title
                description
                mandatory
              }
              mustFillCustomerQuestionnaireIndividual
            }
            buyingOfferSettings {
              canSubmitBuyingOffer
              mustHaveUploadsIndividual {
                title
                description
                mandatory
              }
            }
            viewingPassSettings {
              canSubmitViewingPass
            }
            individualSettings {
              hasFinanceCalculatorAccess
              hasDocumentAccess
              documents {
                id
                storageUrl
                type
                description
                title
                documentFrom
                state
              }
            }
            creator {
              isCreatedByMe
              name
              email
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
      },
    }).pipe(first(), map((result: any) => result.data.customerPortals));
  }

  loadCustomerPortalFeedback$(objectId: string): Observable<CustomerPortalFeedback[]> {
    return this.apollo.query({
      query: gql`
        query GetCustomerPortalFeedback($objectId: String!) {
          customerPortalFeedback(objectId: $objectId) {
            id
            accessId
            objectId
            createdAt
            customer {
              firstname
              lastname
              email
            }
            rating
            isInterested
            sellingLead
            notInterestedReason
            needFinanceSupport
            generalFeedback
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        objectId: objectId,
      },
    }).pipe(first(), map((result: any) => result.data.customerPortalFeedback));
  }

  loadCustomerPortalsByCustomer$(customerId: string): Observable<CustomerPortal[]> {
    return this.apollo.query({
      query: gql`
        query GetCustomerPortals($customerId: String!) {
          customerPortalsByCustomer(customerId: $customerId) {
            id
            objectId
            lastVisited
            creationDate
            customerType
            customers {
              id
              title
              firstname
              lastname
              contact {
                email
              }
            }
            businessCustomer {
              id
              companyNumber
              companyRegister
              customers {
                id
                title
                firstname
                lastname
                contact {
                  email
                }
              }
            }
            object {
              objectStatus
              address {
                country
                city
                zipCode
                streetName
                buildingNumber
                doorNumber
                stairway
              }
            }
            rentalOfferSettings {
              canSubmitRentalOffer
              mustHaveUploadsIndividual {
                title
                description
                mandatory
              }
              mustFillCustomerQuestionnaireIndividual
            }
            buyingOfferSettings {
              canSubmitBuyingOffer
              mustHaveUploadsIndividual {
                title
                description
                mandatory
              }
            }
            viewingPassSettings {
              canSubmitViewingPass
            }
            individualSettings {
              hasFinanceCalculatorAccess
              hasDocumentAccess
              documents {
                id
                storageUrl
                type
                description
                title
                documentFrom
                state
              }
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        customerId,
      },
    }).pipe(first(), map((result: any) => result.data.customerPortalsByCustomer));
  }

  getCustomerPortalEmailVariables$(
    objectId: string,
    id: string,
  ): Observable<any> {
    return this.apollo.query({
      query: gql`
        query GetCustomerPortalEmailVariables(
          $objectId: String!
          $id: String!
        ) {
          customerPortalEmailVariables(
            objectId: $objectId
            id: $id
          ) {
            customerPortalLink
            propertyAddress
            documentUrl
            signature
          }
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        objectId,
        id,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => result.data?.customerPortalEmailVariables),
    );
  }

  sendCustomerPortalEmail$(
    objectId: string,
    id: string,
    emailData: any,
  ): Observable<boolean> {
    return this.apollo.query({
      query: gql`
        query TriggerSendCustomerPortalEmailQuery(
          $objectId: String!
          $id: String!
          $emailData: EmailData!
        ) {
          customerPortalSendMail(
            objectId: $objectId
            id: $id
            emailData: $emailData
          )
        }
      `,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        objectId,
        id,
        emailData,
      },
    }).pipe(
      first(),
      map((result: ApolloQueryResult<any>) => {
        const success = result.data?.customerPortalSendMail;

        if (!success) {
          throw new Error();
        }

        return true;
      }),
    );
  }

  deleteCustomerPortal$(id: string): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`
        mutation DeleteCustomerPortal($id: String!) {
          deleteCustomerPortal(id: $id)
        }
      `,
      variables: {
        id,
      },
    }).pipe(
      first(),
      map((result: any) => result.data.deleteCustomerPortal),
    );
  }
}
